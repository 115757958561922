import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CreateOrEditStudentContactDto, ListItemLookupTableDto, ListItemsServiceProxy, ParentStudentContactsServiceProxy, StudentContactsServiceProxy } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'parentCreateEditStudentContactModal',
    templateUrl: './parent-create-or-edit-student-contact-modal.component.html',
    styleUrls: ['./parent-create-or-edit-student-contact-modal.component.less'],
})
export class ParentCreateEditStudentContactModalComponent extends AppComponentBase implements OnInit {

    // NOTE: Check if changes made here affect src/app/main/launch/fundraisers/student-contacts/create-or-edit-student-contact-modal/create-or-edit-student-contact-modal.component.html
    // If so, make sure to make the changes in both places
    // Code duplication is sometimes a necessary evil for security :( 

    @ViewChild('parentCreateEditStudentContactModal', { static: true }) modal: ModalDirective;
    @ViewChild('studentContactForm') studentContactForm: NgForm;
    @Input() guid: string;
    @Input() studentId: number;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    
    studentContact = new CreateOrEditStudentContactDto();

    relationships: ListItemLookupTableDto[] = [];
    otherRelationshipId?: number = null;
    
    saving = false;

    phoneErrorMessage: string = '';
    
    validatePhoneNumber() {
        this.phoneErrorMessage = this.getPhoneErrorMessage(this.studentContact.phoneNumber);
    }

    constructor(
        injector: Injector,
        private _parentStudentContactsService: ParentStudentContactsServiceProxy,
        private _listItemsService: ListItemsServiceProxy
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.getRelationships();
    }

    show(id?: number) {
        if(id != null) {
            this._parentStudentContactsService.getStudentContactForEdit(id, this.guid).subscribe(result => {
                this.studentContact = result;
                this.modal.show();
            });
        } else {
            this.studentContact.studentId = this.studentId;
            this.modal.show();
        }
    }

    getRelationships() {
        this._listItemsService.getListItemsForLookupTable('relationships').subscribe(result => {
            this.relationships = result.items;
            let other = this.relationships.find(x => x.programValue == 'other');
            this.otherRelationshipId = other.id;
            // move other to the end of the list
            this.relationships.push(this.relationships.splice(this.relationships.indexOf(other), 1)[0]);
        });
    }

    save() {
        this.saving = true;

        if (!this.studentContactForm.form.valid || this.phoneErrorMessage != '')
            return;
        
        if ((this.studentContact.emailAddress != null && this.studentContact.emailAddress.trim() == '')) 
            this.studentContact.emailAddress = null;

        if(+this.studentContact.relationshipId !== this.otherRelationshipId)
            this.studentContact.relationshipOther = ''; 

        this._parentStudentContactsService.createOrEdit(this.guid, this.studentContact).subscribe(result => {
            this.notify.info(this.l('SavedSuccessfully'));
            this.close();
            this.saving = false;
            this.modalSave.emit(null);
        },
        () => this.saving = false);
    }

    deleteContact() {
        this.message.confirm(
            this.l('DeleteContactConfirmation', `${this.studentContact.firstName} ${this.studentContact.lastName}`),
            this.l('AreYouSure'),
            (isConfirmed) => {
                if (isConfirmed) {
                    this._parentStudentContactsService.delete(this.studentContact.id, this.guid)
                        .subscribe(() => {
                            this.notify.success(this.l('SuccessfullyDeleted'));
                            this.close();
                            this.modalSave.emit(null);
                        });
                }
            }
        );
    }

    close() {
        this.modal.hide();
        this.resetForm();
    }

    resetForm() {
        this.studentContact = new CreateOrEditStudentContactDto();
        this.studentContactForm.resetForm();
        this.phoneErrorMessage = '';
    }
}