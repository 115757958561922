<div class="row" style="text-align: center;">
    <h2 class="header-text header-padding">{{isMetricDollars ? l('TotalRaisedLabel') : l('TotalSoldLabel')}}</h2>
    <div class='raised-total-value mb-4'>{{isMetricDollars ? (totalRaised | currency : 'USD') : totalRaised}}</div>

    <div class="container">

        <div class="row">

            <div class="col-12 col-xl-6 mb-4" (click)="toggleTopSellersMetric()">
                <h2 class="header-text">{{l('TopSellers')}}</h2>

                <div class="row">

                    <div class="col-12 col-md-6 top-seller-row-cols">
                        <div *ngFor="let topSeller of sellersCol1Above">
                            <div class="row align-items-center flow-no-wrap">
                                <div class="col-auto align-items-center justify-content-center pb-3 px-0">
                                    <div class="ranking-box">
                                        {{topSeller.rank}}
                                    </div>
                                </div>
                                <div class="col-8 pb-3 px-3 top-seller-name-amount">
                                    <div class="top-seller-name">{{topSeller.name}}</div>
                                    <div class="top-seller-sold-amount">
                                        {{isSellersSortedByDollars ? (topSeller.soldAmount | currency) : topSeller.soldAmount}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div [@shiftDown]="isShiftDownSellersCol1 ? true : false" *ngFor="let topSeller of sellersCol1ToMoveDown">
                            <div class="row align-items-center">
                                <div class="col-auto align-items-center justify-content-center pb-3 px-0">
                                    <div class="ranking-box">
                                        {{topSeller.rank}}
                                    </div>
                                </div>
                                <div class="col-8 pb-3 px-3 top-seller-name-amount">
                                    <div class="top-seller-name">{{topSeller.name}}</div>
                                    <div class="top-seller-sold-amount">
                                        {{isSellersSortedByDollars ? (topSeller.soldAmount | currency) : topSeller.soldAmount}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div [@shiftUpOne]="isShiftOne ? true : false" [@shiftUpTwo]="isShiftTwo ? true : false"
                             [@shiftUpThree]="isShiftThree ? true : false" [@shiftUpFour]="isShiftFour ? true : false"
                             [@shiftDown]="isShiftDownSellersCol1 ? true : false" 
                             *ngIf="sellersCol1ToMoveUp">
                            <div class="row align-items-center flow-no-wrap">
                                <div class="col-auto align-items-center justify-content-center pb-3 px-0">
                                    <div class="ranking-box">
                                        {{sellersCol1ToMoveUp.rank}}
                                    </div>
                                </div>
                                <div class="col-auto pb-3 px-3 top-seller-name-amount">
                                    <div class="top-seller-name">{{sellersCol1ToMoveUp.name}}</div>
                                    <div class="top-seller-sold-amount">
                                        {{isSellersSortedByDollars ? (sellersCol1ToMoveUp.soldAmount | currency) : sellersCol1ToMoveUp.soldAmount}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngFor="let topSeller of sellersCol1Below">
                            <div class="row align-items-center flow-no-wrap">
                                <div class="col-auto align-items-center justify-content-center pb-3 px-0">
                                    <div class="ranking-box">
                                        {{topSeller.rank}}
                                    </div>
                                </div>
                                <div class="col-8 pb-3 px-3 top-seller-name-amount">
                                    <div class="top-seller-name">{{topSeller.name}}</div>
                                    <div class="top-seller-sold-amount">
                                        {{isSellersSortedByDollars ? (topSeller.soldAmount | currency) : topSeller.soldAmount}}
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div [@shiftFiveToSixTrigger]="shiftFiveToSixTriggerBoolean ? true : false"
                             *ngIf="sellerShiftFiveToSix">
                            <div class="row align-items-center flow-no-wrap">
                                <div class="col-auto align-items-center justify-content-center pb-3 px-0">
                                    <div class="ranking-box">
                                        {{sellerShiftFiveToSix.rank}}
                                    </div>
                                </div>
                                <div class="col-8 pb-3 px-3 top-seller-name-amount">
                                    <div class="top-seller-name">{{sellerShiftFiveToSix.name}}</div>
                                    <div class="top-seller-sold-amount">
                                        {{isSellersSortedByDollars ? (sellerShiftFiveToSix.soldAmount | currency) : sellerShiftFiveToSix.soldAmount}}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="col-12 col-md-6 top-seller-row-cols">
                        <div *ngFor="let topSeller of sellersCol2Above">
                            <div class="row align-items-center flow-no-wrap">
                                <div class="col-auto align-items-center justify-content-center pb-3 px-0">
                                    <div class="ranking-box">
                                        {{topSeller.rank}}
                                    </div>
                                </div>
                                <div class="col-8 pb-3 px-3 top-seller-name-amount">
                                    <div class="top-seller-name">{{topSeller.name}}</div>
                                    <div class="top-seller-sold-amount">
                                        {{isSellersSortedByDollars ? (topSeller.soldAmount | currency) : topSeller.soldAmount}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div [@shiftDown]="isShiftDownSellersCol2 ? true : false" *ngFor="let topSeller of sellersCol2ToMoveDown">
                            <div class="row align-items-center flow-no-wrap">
                                <div class="col-auto align-items-center justify-content-center pb-3 px-0">
                                    <div class="ranking-box">
                                        {{topSeller.rank}}
                                    </div>
                                </div>
                                <div class="col-8 pb-3 px-3 top-seller-name-amount">
                                    <div class="top-seller-name">{{topSeller.name}}</div>
                                    <div class="top-seller-sold-amount">
                                        {{isSellersSortedByDollars ? (topSeller.soldAmount | currency) : topSeller.soldAmount}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div [@shiftUpOne]="isShiftOne ? true : false" [@shiftUpTwo]="isShiftTwo ? true : false"
                             [@shiftUpThree]="isShiftThree ? true : false" [@shiftUpFour]="isShiftFour ? true : false"
                             [@shiftDown]="isShiftDownSellersCol2 ? true : false" 
                             *ngIf="sellersCol2ToMoveUp">
                            <div class="row align-items-center flow-no-wrap">
                                <div class="col-auto align-items-center justify-content-center pb-3 px-0">
                                    <div class="ranking-box">
                                        {{sellersCol2ToMoveUp.rank}}
                                    </div>
                                </div>
                                <div class="col-8 pb-3 px-3 top-seller-name-amount">
                                    <div class="top-seller-name">{{sellersCol2ToMoveUp.name}}</div>
                                    <div class="top-seller-sold-amount">
                                        {{isSellersSortedByDollars ? (sellersCol2ToMoveUp.soldAmount | currency) : sellersCol2ToMoveUp.soldAmount}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div [@shiftLeftUpFive]="isShiftLeftUpFive ? true : false" 
                             [@shiftLeftUpFour]="isShiftLeftUpFour ? true : false" [@shiftLeftUpThree]="isShiftLeftUpThree ? true : false"
                             [@shiftLeftUpTwo]="isShiftLeftUpTwo ? true : false" [@shiftLeftUpOne]="isShiftLeftUpOne ? true : false"
                             [@shiftLeftStraight]="isShiftLeftStraight ? true : false" [@shiftLeftDownOne]="isShiftLeftDownOne ? true : false"
                             [@shiftLeftDownTwo]="isShiftLeftDownTwo ? true : false" [@shiftLeftDownThree]="isShiftLeftDownThree ? true : false"
                             [@shiftLeftDownFour]="isShiftLeftDownFour ? true : false"
                             *ngIf="sellerCol2ToMoveDiagonally">
                            <div class="row align-items-center flow-no-wrap">
                                <div class="col-auto align-items-center justify-content-center pb-3 px-0">
                                    <div class="ranking-box" [ngClass] = "{'opacity-zero' : hideSellerCol2ToMoveDiagonally}">
                                        {{sellerCol2ToMoveDiagonally.rank}}
                                    </div>
                                </div>
                                <div class="col-8 pb-3 px-3 top-seller-name-amount">
                                    <div class="top-seller-name" [ngClass] = "{'opacity-zero' : hideSellerCol2ToMoveDiagonally}">{{sellerCol2ToMoveDiagonally.name}}</div>
                                    <div class="top-seller-sold-amount" [ngClass]="{'opacity-zero' : hideSellerCol2ToMoveDiagonally}">
                                        {{isSellersSortedByDollars ? (sellerCol2ToMoveDiagonally.soldAmount | currency) : sellerCol2ToMoveDiagonally.soldAmount}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngFor="let topSeller of sellersCol2Below">
                            <div class="row align-items-center flow-no-wrap">
                                <div class="col-auto align-items-center justify-content-center pb-3 px-0">
                                    <div class="ranking-box">
                                        {{topSeller.rank}}
                                    </div>
                                </div>
                                <div class="col-8 pb-3 px-3 top-seller-name-amount">
                                    <div class="top-seller-name">{{topSeller.name}}</div>
                                    <div class="top-seller-sold-amount">
                                        {{isSellersSortedByDollars ? (topSeller.soldAmount | currency) : topSeller.soldAmount}}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-12 col-xl-6">

                <h2 class="header-text">{{l('TopTeams')}}</h2>

                <div *ngFor="let topTeam of teamsAbove" class="row mb-3 mx-0 top-team-row" style="background-color: {{topTeam.colorCode}};">
                    <div class="col-8 d-flex flex-column align-items-center justify-content-center px-3 top-team-name-container">
                        <div class="top-team-text top-team-name" style="align-self: start; text-align: left; text-overflow: ellipsis;">
                            {{topTeam.name}}
                        </div>
                    </div>
                    <div class="col-4 d-flex align-items-center justify-content-center px-0 top-team-text">
                        {{topTeam.soldAmount | currency}}
                    </div>
                </div>

                <div [@shiftDown]="isShiftDown ? true : false" *ngFor="let topTeam of teamsToMoveDown"
                     class="row mb-3 mx-0 top-team-row" style="opacity: 0.4; background-color: {{topTeam.colorCode}};">
                    <div class="col-8 d-flex flex-column align-items-center justify-content-center px-3 top-team-name-container">
                        <div class="top-team-text" style="align-self: start; text-align: left; text-overflow: ellipsis;">{{topTeam.name}}</div>
                    </div>
                    <div class="col-4 d-flex align-items-center justify-content-center px-0 top-team-text">
                        {{topTeam.soldAmount | currency}}
                    </div>
                </div>
                <div [@shiftUpOne]="isShiftOne ? true : false" [@shiftUpTwo]="isShiftTwo ? true : false"
                     [@shiftUpThree]="isShiftThree ? true : false" [@shiftUpFour]="isShiftFour ? true : false"
                     *ngIf="teamToMoveUp" class="row mb-3 mx-0 top-team-row"
                     style="opacity: 0.4; background-color: {{teamToMoveUp.colorCode}};">
                    <div class="col-8 d-flex flex-column align-items-center justify-content-center px-3 top-team-name-container">
                        <div class="top-team-text" style="align-self: start; text-align: left; text-overflow: ellipsis;">
                            {{teamToMoveUp.name}}
                        </div>
                    </div>
                    <div class="col-4 d-flex align-items-center justify-content-center px-0 top-team-text">
                        {{teamToMoveUp.soldAmount | currency}}
                    </div>
                </div>

                <div *ngFor="let topTeam of teamsBelow" class="row mb-3 mx-0 top-team-row"
                     style="background-color: {{topTeam.colorCode}};">
                    <div class="col-8 d-flex flex-column align-items-center justify-content-center px-3 top-team-name-container">
                        <div class="top-team-text top-team-name" style="align-self: start; text-align: left; text-overflow: ellipsis;">
                            {{topTeam.name}}
                        </div>
                    </div>
                    <div class="col-4 d-flex align-items-center justify-content-center px-0 top-team-text">
                        {{topTeam.soldAmount | currency}}
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
