import { Injectable } from "@angular/core";
import { AppConsts } from "../../AppConsts";
type Tracker = {
	send: (
		hitType: string,
		category: string,
		action: string,
		label?: string
	) => void;
};
declare const ga: {
	(...args: any[]): () => void;
	getAll: () => Tracker[];
};
const has = Object.prototype.hasOwnProperty;
@Injectable({
	providedIn: 'root'
})
export class GaHelperService {
	gaTrackingId: string;
	constructor() {
		this.gaTrackingId = AppConsts.gaTrackingId;
		if (this.gaTrackingId) {
			const gTagManagerScript = document.createElement('script');
			gTagManagerScript.async = true;
			gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${this.gaTrackingId}`;
			document.head.appendChild(gTagManagerScript);
			// register google analytics
			const gaScript = document.createElement('script');
			gaScript.innerHTML = `
				window.dataLayer = window.dataLayer || [];
				function gtag() { dataLayer.push(arguments); }
				gtag('js', new Date());
				gtag('config', '${this.gaTrackingId}');
			`;
			document.head.appendChild(gaScript);
		}
		// else we're not logging anything to google analytics...
	}
	logCustomEvent(
		eventCategory: string,
		eventAction: string,
		eventLabel?: string
	) {
		ga(() => {
			if (has.call(window, "ga")) {
				const tracker = ga.getAll();
				if (tracker?.length > 0) {
					tracker[0]?.send("event", eventCategory, eventAction, eventLabel);
				}
			}
		});
	}
	logPageView(url: string) {
		ga("set", "page", url);
		ga("send", "pageview");
	}
}