import { FormsModule } from "@angular/forms";
import * as ngCommon from '@angular/common';
import { HttpClientModule } from "@angular/common/http";
import { SignComponent } from "./sign.component";
import { NgModule } from '@angular/core';
import { SignaturePadModule } from 'angular2-signaturepad';
import { UtilsModule } from '@shared/utils/utils.module';

@NgModule({
    declarations: [
        SignComponent
    ],
    imports: [
        ngCommon.CommonModule,
        FormsModule,
        HttpClientModule,
        SignaturePadModule,
        UtilsModule,
    ],
    providers: [
    ]
})
export class SignModule { }