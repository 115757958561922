<div [@routerTransition] class="background" *ngIf="!isCheckout && !isOrderComplete">
    <div class="w-100" [hidden]="showDonationPage || !fundraiserUserInfo">
        <div class="h-100 w-100">
            <fundraiser-header class="d-flex align-items-center justify-content-center w-100 header-container"
                               [organizationName]="fundraiserUserInfo?.organizationName"
                               [tenantLogoUrl]="fundraiserUserInfo?.tenantLogoUrl"
                               [fundraiserName]="fundraiserUserInfo?.fundraiserName"
                               [teamRaised]="teamRaised"
                               [effectiveTeamGoal]="effectiveTeamGoal"
                               [teamGoal]="teamGoal"
                               [teamGoalPercentActual]="teamGoalPercentActual"
                               [teamGoalPercentEffective]="teamGoalPercentEffective"
                               [fundraiserUserId]="fundraiserUserId"
                               [studentRaised]="studentRaised"
                               [isMetricDollars]="fundraiserUserInfo?.isMetricDollars"
                               [isLaunchathon]="false"
                               [isFundraiserOver]="isFundraiserOver && !viewSample"
                               [studentGoal]="studentGoal"
                               [effectiveStudentGoal]="effectiveStudentGoal"
                               [studentGoalPercentActual]="studentGoalPercentActual"
                               [studentGoalPercentEffective]="studentGoalPercentEffective"
                               [studentGoalRemaining]="studentGoalRemaining"
                               [studentName]="fundraiserUserInfo?.studentName"
                               [logoUrl]="fundraiserUserInfo?.logoUrl"
                               [goalValues]="goalValues">
            </fundraiser-header>

            <div class="mb-40 overlay d-flex justify-content-center">
                <div class="row middle-row">
                    <div *ngIf="!isFundraiserOver || viewSample" class="mb-5">
                        <order-options #orderOptionsView (cancel)="closeOptions()" (save)="updateOrderOption($event)"
                                       (orderOptionsCheckout)="proceedToCheckout()">
                        </order-options>
                    </div>
                    <ng-container *ngIf="!showOptionsPage">
                        <ng-container *ngIf="!isFundraiserOver || viewSample">
                            <div *ngFor="let productOrPackage of productsAndPackages" class="col-md-6 col-xl-3 product-card-container">
                                <div class="product-card">

                                    <div class="product-name-row d-flex flex-column h-100">

                                        <div class="w-100">
                                            <div class="col-12">
                                                <div class="product-image-container" [ngStyle]="{'cursor': productOrPackage.productDetails.productPictureUrl ? 'pointer' : 'unset' }"
                                                     (click)="showItemViewer(productOrPackage.productDetails.productPictureUrl)">
                                                    <div *ngIf="productOrPackage.productDetails.productPictureUrl" class="show-item-image-viewer-btn text-center">
                                                        <i class="fa fa-expand item-expander-icon"></i>
                                                    </div>
                                                    <img alt="{{productOrPackage.productDetails.name}}"
                                                         class="product-image m--img-centered"
                                                         [src]="productOrPackage.productDetails.productPictureUrl ?? '/assets/common/images/gradient-product.jpg'" />
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div class="product-name">
                                                <b>{{productOrPackage.productDetails.name}}</b>
                                            </div>
                                        </div>

                                        <div class="product-detail" [innerHtml]='productOrPackage.productDetails.details'>
                                        </div>

                                        <ul *ngIf="productOrPackage.productDetails.containedProducts?.length > 0" class="mt-5 pl-10">
                                            <li *ngFor="let containedProduct of productOrPackage.productDetails.containedProducts">
                                                {{containedProduct.quantity}} {{containedProduct.name}}
                                            </li>
                                        </ul>

                                        <div class="mt-auto">

                                            <div class="price">
                                                {{productOrPackage.productDetails.price | currency: 'USD' : 'symbol'}}
                                            </div>

                                            <div *ngIf="productOrPackage.productDetails.containedProductsTotal && productOrPackage.productDetails.containedProductsTotal > productOrPackage.productDetails.price"
                                                 class="discount-container">
                                                <div class="d-flex justify-content-center w-100">
                                                    <img src="/assets/common/images/help_tape.png" width="150" />
                                                </div>
                                                <div class="discount">
                                                    {{productOrPackage.productDetails.containedProductsTotal - productOrPackage.productDetails.price | currency: 'USD' : 'symbol'}} Discount
                                                </div>
                                            </div>

                                            <div *ngIf="productOrPackage.orderProducts[0].quantity == 0 && !(productOrPackage.productOptions && productOrPackage.productOptions.length > 0)"
                                                 class="mb-5 product-quantity-group" (click)="incrementQuantity(productOrPackage.orderProducts[0])">
                                                <div (click)="incrementQuantity(productOrPackage.orderProducts[0])"
                                                     class="btn card-button enabled-background">
                                                    {{l("AddToCart")}}
                                                </div>
                                            </div>

                                            <div *ngIf="productOrPackage.orderProducts[0].quantity > 0 && !(productOrPackage.productOptions && productOrPackage.productOptions.length > 0)"
                                                 class="mb-5 product-quantity-group">

                                                <div class="btn card-button">
                                                    <i class="product-quantity-decrement fa fa-minus"
                                                       (click)="decrementQuantity(productOrPackage.orderProducts[0])"></i>
                                                    <div class="product-quantity-label">
                                                        {{l("InCart", productOrPackage.orderProducts[0].quantity)}}
                                                    </div>
                                                    <i class="product-quantity-increment fa fa-plus"
                                                       (click)="incrementQuantity(productOrPackage.orderProducts[0])"></i>
                                                </div>
                                            </div>

                                            <div *ngIf="productOrPackage.productOptions && productOrPackage.productOptions.length > 0"
                                                 class="mb-5 product-quantity-group justify-content-center d-flex my-3">
                                                <div class="btn card-button"
                                                     (click)="showOptions(productOrPackage)">
                                                    {{l("ViewOptions")}}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <div class="col-12 justify-content-center">
                            <div class="row">
                                <help-reach-goal class="col-md-6 countdown-container goal-reorder"
                                                 [isFundraiserOver]="isFundraiserOver && !viewSample"
                                                 [fundraiserUserId]="fundraiserUserId"
                                                 [dayVsDays]="dayVsDays"
                                                 [studentName]="fundraiserUserInfo?.studentName"
                                                 [studentRaised]="studentRaised"
                                                 [teamRaised]="teamRaised"
                                                 [isMetricDollars]="fundraiserUserInfo?.isMetricDollars"
                                                 [organizationName]="fundraiserUserInfo?.organizationName"
                                                 [appListing]="appListing"
                                                 [appListingSms]="appListingSms"
                                                 [shareTitle]="shareTitle"
                                                 [shareDescription]="shareDescription"
                                                 [shareUrl]="shareUrl"
                                                 [shareUrlSms]="shareUrlSms"
                                                 [helpReachGoalInfo]="helpReachGoalInfo">
                                </help-reach-goal>

                                <div class="col-md-6 our-supporters-container">
                                    <img src="/assets/common/images/help_tape.png" width="300" />
                                    <div class="brush-stroke help-me-text">
                                        {{isFundraiserOver && !viewSample ? l('ThanksToOurSupporters') : l('OurSupporters')}}
                                    </div>
                                    <div class="our-supporters" [ngStyle]="{'max-height': isFundraiserOver && !viewSample ? '250px' : '500px'}">
                                        <supporters [orders]="fundraiserUserInfo?.orders">
                                        </supporters>
                                    </div>
                                </div>
                                <div *ngIf="!isFundraiserOver || viewSample" class="col-12 countdown-container justify-content-center">
                                    <about-the-team [aboutTheTeam]="fundraiserUserInfo?.aboutTheTeam"
                                                    [aboutTheTeamVideo]="fundraiserUserInfo?.aboutTheTeamVideo">
                                    </about-the-team>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="d-flex flex-row justify-content-center w-100">
                <div class="footer-organization-logo-container my-auto">
                    <img src="{{fundraiserUserInfo?.tenantLogoUrl}}" alt="logo" class="hour-a-thon-logo">
                </div>
            </div>
            <div class="footer-links d-flex flex-wrap justify-content-center">
                <a target="_blank" href="https://www.launchfundraising.com/get-started">Contact Us</a>
                <a target="_blank" href="https://www.launchfundraising.com/get-started">Run your own Fundraiser</a>
                <a target="_blank" href="https://www.launchfundraising.com/get-started">Join our team</a>
            </div>
            <div class="footer-links d-flex flex-wrap justify-content-center">
                <a href="{{appUrlService.appRootUrl}}TermsOfUse.html" target="_blank">Terms of Use</a>
                <a href="https://www.launchfundraising.com/privacy-policy" target="_blank">Privacy Policy</a>
            </div>
            <div class="powered-by-row d-flex flex-row justify-content-center w-100">
                <div class="yellow-text d-flex align-items-end">
                    <div class="d-flex flex-column">
                        <div class="fundraiser-name-and-powered-by-container">
                            <div class="powered-by-container">
                                <div class="powered-by-text">{{l('PoweredBy')}}</div>
                                <img alt="Launch Logo" src="/assets/common/images/logo-launch.png" class="powered-by-logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer class="live-link-footer fixed justify-content-center align-items-center d-flex flex-row">
                <div *ngIf="!isFundraiserOver || viewSample" class="justify-content-center align-items-center d-flex flex-row bottom-buttons-container">
                    <div class="w-100 px-2 justify-content-center" (click)="navigateToCheckoutOrDonate()">
                        <button class="w-100 customer-button customer-submit-button donation-option mx-2 enabled-background" [disabled]="viewSample">
                            {{l('CheckOut')}} <span *ngIf="itemsCountText"> &nbsp; {{itemsCountText}}</span>
                        </button>
                    </div>
                    <div *ngIf="(viewHeight > maximumMobileHeight || userHasScrolled) && allowOrderDonationButton && !itemsCountText" class="justify-content-center w-100 px-2 " (click)="updateTotals(true)">
                        <button class="w-100 customer-button customer-submit-button donation-option enabled-background mx-2" [disabled]="viewSample">
                            {{l('MakeADonation')}}
                        </button>
                    </div>
                </div>
            </footer>
            <div *ngIf="!fundraiserUserInfo?.fundraiserName">
                {{l('FundraiserNotFound')}}
            </div>
        </div>
    </div>
    <donations-page [hidden]="!showDonationPage" #donationView (applyDonation)="updateDonation($event)"
                    [logoUrl]="fundraiserUserInfo?.logoUrl"
                    [organizationName]="fundraiserUserInfo?.organizationName"
                    [fundraiserName]="fundraiserUserInfo?.fundraiserName"
                    [teamMemberNameInput]="fundraiserUserInfo?.studentName"
                    [tenantLogoUrl]="fundraiserUserInfo?.tenantLogoUrl"></donations-page>
</div>
<ng-container *ngIf="isCheckout">

    <checkout-v2 *ngIf="fundraiserUserInfo.isBetaCheckout"
            [donationAmountInput]="donationAmount"
            [fundraiserUserIdInput]="fundraiserUserId"
            [fundraiserIdInput]="fundraiserUserInfo?.fundraiserId"
            [fundraiserName]="fundraiserUserInfo?.fundraiserName"
            [logoUrl]="fundraiserUserInfo?.logoUrl"
            [organizationName]="fundraiserUserInfo?.organizationName"
            [availableSellers]="fundraiserUserInfo?.availableSellers"
            [teamMemberIdInput]="teamMemberId"
            [teamMemberNameInput]="fundraiserUserInfo?.studentName"
            [productAndPackageViewModels]="productsAndPackages"
            [productOptions]="productOptions"
            [isGuest]="fundraiserUserInfo?.isGuest"
            [shareLink]="getShareLink()"
            [donateByRoundingOrderTo]="donateByRoundingOrderTo"
            [tenantLogoUrl]="fundraiserUserInfo?.tenantLogoUrl"
            [paymentMethods]="paymentMethods"
            [checksPayableName]="checksPayableName"
            [creditCardText]="creditCardText"
            [convenienceFee]="convenienceFee"
            [directShipAvailable]="directShipAvailable"
            [forceDirectShip]="forceDirectShip"
            [acceptDonations]="acceptDonations"
            [guestPayCCOption]="guestPayCCOption"
            [studentCreditCardOnly]="studentCreditCardOnly"
            [repInfo]="repInfo"
            [isDonationOnly]="isDonationOnly"
            [guestPayMode]="guestPayMode"
            [defaultOrderDetailToDirectShip]="defaultOrderDetailToDirectShip"
            [orderDetailId]="orderDetailId"
            [orderDetail]="orderDetail"
            [isInitialLoadForEdit]="isInitialLoadForEdit"
            [fundraiserStudentContactSlug]="fundraiserStudentContactSlug"
            [automatedMessageType]="automatedMessageType"
            [tipInfoTitle]="fundraiserUserInfo?.tipInfoTitle"
            [tipInfo]="fundraiserUserInfo?.tipInfo"
            [tenantId]="tenantId"
            [isApplePayEnabledForFundraiser]="isApplePayEnabledForFundraiser"
            [isISponsorEnabled]="isISponsorEnabledForFundraiser"
            (orderComplete)="orderComplete($event)"
            (cancelOrder)="cancelOrder()"
            (goBack)="reload($event)"></checkout-v2>
            
    <checkout *ngIf="!fundraiserUserInfo.isBetaCheckout"
              [donationAmountInput]="donationAmount"
              [fundraiserUserIdInput]="fundraiserUserId"
              [fundraiserIdInput]="fundraiserUserInfo?.fundraiserId"
              [fundraiserName]="fundraiserUserInfo?.fundraiserName"
              [logoUrl]="fundraiserUserInfo?.logoUrl"
              [organizationName]="fundraiserUserInfo?.organizationName"
              [availableSellers]="fundraiserUserInfo?.availableSellers"
              [teamMemberIdInput]="teamMemberId"
              [teamMemberNameInput]="fundraiserUserInfo?.studentName"
              [productAndPackageViewModels]="productsAndPackages"
              [productOptions]="productOptions"
              [isGuest]="fundraiserUserInfo?.isGuest"
              [shareLink]="getShareLink()"
              [donateByRoundingOrderTo]="donateByRoundingOrderTo"
              [tenantLogoUrl]="fundraiserUserInfo?.tenantLogoUrl"
              [paymentMethods]="paymentMethods"
              [checksPayableName]="checksPayableName"
              [creditCardText]="creditCardText"
              [convenienceFee]="convenienceFee"
              [directShipAvailable]="directShipAvailable"
              [forceDirectShip]="forceDirectShip"
              [acceptDonations]="acceptDonations"
              [guestPayCCOption]="guestPayCCOption"
              [studentCreditCardOnly]="studentCreditCardOnly"
              [repInfo]="repInfo"
              [isDonationOnly]="isDonationOnly"
              [guestPayMode]="guestPayMode"
              [defaultOrderDetailToDirectShip]="defaultOrderDetailToDirectShip"
              [orderDetailId]="orderDetailId"
              [orderDetail]="orderDetail"
              [isInitialLoadForEdit]="isInitialLoadForEdit"
              [fundraiserStudentContactSlug]="fundraiserStudentContactSlug"
              [tipInfoTitle]="fundraiserUserInfo?.tipInfoTitle"
              [tipInfo]="fundraiserUserInfo?.tipInfo"
              [isISponsorEnabled]="isISponsorEnabledForFundraiser"
              (orderComplete)="orderComplete($event)"
              (cancelOrder)="cancelOrder()"
              (goBack)="reload($event)"></checkout>
</ng-container>

<order-confirmation #orderConfirmation [hidden]="!isOrderComplete"
                    [customerEmailAddressInput]="customerEmailAddress"
                    [appListing]="appListing"
                    [appListingSms]="appListingSms"
                    [shareTitle]="shareTitle"
                    [shareDescription]="shareDescription"
                    [shareUrl]="shareUrl"
                    [shareUrlSms]="shareUrlSms"
                    [orderTransactionId]="orderTransactionId"
                    [fundraiserId]="fundraiserUserInfo?.fundraiserId"
                    [isLaunchathon]="false"
                    [isISponsorEnabled]="isISponsorEnabledForFundraiser"
                    (returnToFundraiserHomeEmitter)="returnFromConfirmation()"></order-confirmation>

<item-image-viewer #itemImageViewerModal></item-image-viewer>