import { Component, Injector, OnDestroy } from '@angular/core';
import { NgxSpinnerTextService } from '@app/shared/ngx-spinner-text.service';
import { AppConsts } from '@shared/AppConsts';
import { AppUrlService } from '@shared/common/nav/app-url.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';
import { UiCustomizationSettingsDto } from '@shared/service-proxies/service-proxies';
import {
    AbpMultiTenancyService, FeatureCheckerService,
    LocalizationService,
    MessageService, NotifyService, PermissionCheckerService, SettingService
} from 'abp-ng2-module';
import { DateTime } from 'luxon';
import * as moment from 'moment';
import { Moment } from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { PrimengTableHelper } from 'shared/helpers/PrimengTableHelper';

interface AbpEventSubscription {
    eventName: string;
    callback: (...args: any[]) => void;
}

@Component({
    template: '',
})
export abstract class AppComponentBase implements OnDestroy {
    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;
    emailAddressPattern = '^[\\w+\\-\\.]+@([\\w\\-]+\\.)+[\\w\\-]{2,}\\s*$';

    localization: LocalizationService;
    permission: PermissionCheckerService;
    feature: FeatureCheckerService;
    notify: NotifyService;
    setting: SettingService;
    message: MessageService;
    multiTenancy: AbpMultiTenancyService;
    appSession: AppSessionService;
    primengTableHelper: PrimengTableHelper;
    ui: AppUiCustomizationService;
    appUrlService: AppUrlService;
    spinnerService: NgxSpinnerService;
    private ngxSpinnerTextService: NgxSpinnerTextService;

    eventSubscriptions: AbpEventSubscription[] = [];

    constructor(injector: Injector) {
        this.localization = injector.get(LocalizationService);
        this.permission = injector.get(PermissionCheckerService);
        this.feature = injector.get(FeatureCheckerService);
        this.notify = injector.get(NotifyService);
        this.setting = injector.get(SettingService);
        this.message = injector.get(MessageService);
        this.multiTenancy = injector.get(AbpMultiTenancyService);
        this.appSession = injector.get(AppSessionService);
        this.ui = injector.get(AppUiCustomizationService);
        this.appUrlService = injector.get(AppUrlService);
        this.primengTableHelper = new PrimengTableHelper();
        this.spinnerService = injector.get(NgxSpinnerService);
        this.ngxSpinnerTextService = injector.get(NgxSpinnerTextService);
    }

    flattenDeep(array) {
        return array.reduce(
            (acc, val) => (Array.isArray(val) ? acc.concat(this.flattenDeep(val)) : acc.concat(val)),
            []
        );
    }

    l(key: string, ...args: any[]): string {
        args.unshift(key);
        args.unshift(this.localizationSourceName);
        return this.ls.apply(this, args);
    }

    ls(sourcename: string, key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, sourcename);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, this.flattenDeep(args));
    }

    isGranted(permissionName: string): boolean {
        return this.permission.isGranted(permissionName);
    }

    isGrantedAny(...permissions: string[]): boolean {
        if (!permissions) {
            return false;
        }

        for (const permission of permissions) {
            if (this.isGranted(permission)) {
                return true;
            }
        }

        return false;
    }

    s(key: string): string {
        return abp.setting.get(key);
    }

    appRootUrl(): string {
        return this.appUrlService.appRootUrl;
    }

    get currentTheme(): UiCustomizationSettingsDto {
        return this.appSession.theme;
    }

    get containerClass(): string {
        if (this.appSession.theme.baseSettings.layout.layoutType === 'fluid') {
            return 'container-fluid';
        } else if (this.appSession.theme.baseSettings.layout.layoutType === 'fluid-xxl') {
            return 'container-xxl';
        }

        return 'container';
    }

    showMainSpinner(text?: string): void {
        this.ngxSpinnerTextService.setText(text);
        this.spinnerService.show();
    }

    hideMainSpinner(text?: string): void {
        this.spinnerService.hide();
    }

    protected subscribeToEvent(eventName: string, callback: (...args: any[]) => void): void {
        abp.event.on(eventName, callback);
        this.eventSubscriptions.push({
            eventName,
            callback,
        });
    }

    private unSubscribeAllEvents() {
        this.eventSubscriptions.forEach((s) => abp.event.off(s.eventName, s.callback));
        this.eventSubscriptions = [];
    }

    ngOnDestroy(): void {
        this.unSubscribeAllEvents();
    }

    newGuid() {
        //https://gist.github.com/benjamincharity/82ce8651dd53dbee38251e150d62051c
        return 'xxxxxyxx-xxxx-4xxx-yxxx-xxxxxyxxyxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    isValidPhoneNumber(phoneNumber: string): boolean {
        if (!phoneNumber) {
            return false;
        }

        // The masking includes the following format: (___) ___-____
        // Strip out all of the masked characters so we can evaluate the actual number
        phoneNumber = phoneNumber.split('_').join('').replace('(', '').replace(')', '').replace('-', '').replace(' ', '');

        if (phoneNumber.length < 10) {
            return false;
        }
        return true;
    }

    getPhoneErrorMessage(phone: string): string {
        if (!phone || phone == '') {
            return 'This field is required';
        }
        let unmaskedPhone = phone.replace(/[\W_A-Za-z]+/g, '');
        if (unmaskedPhone.length !== 10) {
            return 'Must be 10 digits';
        }
        else if (unmaskedPhone[0] == '1') {
            return "Cannot start with '1'";
        }
        return '';
    }

    isValidEmailAddress(emailAddress: string): boolean {
        if (emailAddress && emailAddress != null && emailAddress.trim().length > 0) {
            let regex = new RegExp(this.emailAddressPattern);
            let result = regex.test(emailAddress);
            if (!result || emailAddress.includes(',')) {
                this.message.error(this.l("InvalidEmailAddress"));
                return false;
            }
        }
        return true;
    }

    isValidUrl(url: string): boolean {
        if(url && url != null && url.trim().length > 0) {
            const regex = new RegExp('(https?:\\/\\/)(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)');
            let result = regex.test(url) 
            if(!result) {
                this.message.error(this.l("InvalidUrl"));
                return false;
            }
        }
        return true;
    }


    isValidYoutubeUrl(url: string): boolean {
        if (url && url != null && url.trim().length > 0) {
            let regex = new RegExp('(?:https?:\\/\\/)?(?:www\\.)?(?:(?:(?:youtube.com\\/watch\\?[^?]*v=|youtube.com\\/shorts\\/|youtu.be\\/)([\\w\\-]+))(?:[^\\s?]+)?)(\\?t=)?([[0-9]*]?)');
            let result = regex.test(url) 
            if(!result) {
                this.message.error(this.l("InvalidYoutubeUrl"));
                return false;
            }
        }
        return true;
    }

    getLocalMoment(utcMoment: Moment): Moment {
        if (!utcMoment) {
            return null;
        }
        let formattedLocal = utcMoment.local().format('YYYY-MM-DD HH:mm:ss'); //e.g. "2021-11-23 07:00:00", for midnight MST.
        // parse the string as a utc time.
        let stillUtc = moment.utc(formattedLocal).toDate();
        // get a localized moment:
        return moment(stillUtc).local();
    }

    getLocalDateString(utcTime: Moment): string {
        if (!utcTime) {
            return '';
        }
        return this.getLocalMoment(utcTime).format('L');
    }

    getLocalDate(utcTime: Moment): Date {
        if (!utcTime) {
            return undefined;
        }
        return this.getLocalMoment(utcTime).toDate();
    }

    getLocalDateTime(utcDateTime: DateTime): DateTime {
        if (!utcDateTime) {
            return null;
        }
        return utcDateTime.toLocal();
    }

    getLocalDateTimeString(utcDateTime: DateTime): string {
        if (!utcDateTime) {
            return '';
        }
        return this.getLocalDateTime(utcDateTime).toFormat('D');
    }

    getLocalDateTimeDate(utcDateTime: DateTime): Date {
        if (!utcDateTime) {
            return undefined;
        }
        return this.getLocalDateTime(utcDateTime).toJSDate();
    }

    getSmsVersionOfShareUrl(shareUrl: string) {
        return shareUrl
            .replace(AppConsts.launchDomain, AppConsts.smsDomain);
    }

    hasStudentContactPermission(): boolean {
        return this.isGranted(AppConsts.studentContactPermissionName);
    }

    copyText(content: string) {
        const textArea = document.createElement('textarea');
        textArea.value = content;
        textArea.style.position = 'fixed';
        textArea.style.left = '0';
        textArea.style.top = '0';
        textArea.style.opacity = '0';
        document.body.appendChild(textArea);
        var browser = this.getOS();
        // highlight textarea to copy the text
        if (browser === 'ios') {
            const range = document.createRange();
            range.selectNodeContents(textArea);
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.readOnly = true;
            textArea.setSelectionRange(0, 999999);
        } else {
            textArea.select();
        }
        document.execCommand('copy');
        document.body.removeChild(textArea);
    }

    getOS(): string {
        const userAgent = navigator.userAgent || navigator.vendor || (<any>window).opera;
        console.log('userAgent: ' + userAgent);
        if (/android/i.test(userAgent)) {
            return 'android';
        }

        if (/iPad|iPhone|iPod/.test(userAgent) && !(<any>window).MSStream) {
            return 'ios';
        }
        return 'desktop';
    }

    enumToListItems(theEnum: any): EnumListItem[] {
        let items: EnumListItem[] = [];

        const stringKeys = Object
            .keys(theEnum)
            .filter((v) => isNaN(Number(v)));

        stringKeys.forEach((key, index) => {            
            items.push({ key: key, value: index });
        });                      

        return items;
    }

    openInNewTab(route: string, id?: string | undefined): void {
        if (!route) {
            throw Error("Route is undefined. Cannot open link in new tab!");
        }

        window.open(`${route}\\${id ?? ''}`, '_blank', 'noopener');
    }
}

export class EnumListItem {
    key: string;
    value: number;
}