import { Component, Injector, Input } from "@angular/core";
import { AppComponentBase } from "../../../../shared/common/app-component-base";

@Component({
    selector: 'fundraiser-footer',
    templateUrl: './fundraiser-footer.component.html',
    styleUrls: ['./fundraiser-footer.component.less'],
})
export class FundraiserFooterComponent extends AppComponentBase {
    @Input() tenantLogoUrl: string;
    @Input() isDarkBackground: boolean = false;

    getStartedLink: string = 'https://www.launchfundraising.com/get-started';
    
    constructor(
        injector: Injector,
    ) {
        super(injector);
    }
}