<div [@routerTransition] class="directory-page">
  <div *ngIf="loading" class="directory-page-info"><em class="fa fa-spinner fa-spin fa-3x fa-fw"></em> {{l('LoadWithThreeDot')}}</div>
  <div *ngIf="!loading" class="directory-page-column pb-4">
    <div *ngIf="!found" class="directory-page-info">
      {{l('GroupSearch_NotFound')}}
    </div>
    <div *ngIf="found">
      <div class="directory-page-group-name pt-4">{{l('GroupSearch_Heading')}}</div>
      <div *ngIf="content.fundraisers.length > 0; else noActiveFundraisers">
        <div class="search-instructions pt-4">
          {{l('GroupSearch_SearchInstructions')}}
        </div>
        <div class="search-input pt-2">
          <input id="searchFundraisers" name="searchFundraisers" [(ngModel)]="filter" (ngModelChange)="searchFundraisers()">
        </div>
        <div class="pt-2">
          <p>or</p>
          <button type="button" (click)="showAll()" class="btn btn-dark">{{l('GroupSearch_ShowAll')}}</button>
        </div>
        <div class="directory-search-results pt-3">
          <div *ngIf="searchedFundraisers && searchedFundraisers.length === 0">
            {{l('GroupSearch_NothingFound')}}
          </div>
          <ul *ngIf="searchedFundraisers && searchedFundraisers.length > 0">
            <li *ngFor="let fund of searchedFundraisers" class="fundraiser-entry">
              <a [routerLink]="['/order', fund.slug]" class="fundraiser-link">
                <em class="fa fa-arrow-circle-right fundraiser-icon"></em>
                <div>
                  <span class="fundraiser-name">{{fund.name}}</span><br />
                  <span class="school-name">{{fund.school}}</span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <ng-template #noActiveFundraisers>
        {{l('GroupSearch_NoActiveFundraisers')}}
      </ng-template>
    </div>
  </div>
</div>