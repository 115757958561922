<div class="error-page-overlay">
  <div class="text-center overlay-page-details">
    <div class="div-img">
      <a href="/">
          <img alt="logo" style="width: 350px; margin-bottom: 20px;" src="logo.png">
      </a>
    </div>
    <h4>
      Sorry we couldn't find that page.
    </h4>
    <div>
      <p>If you were trying to sign in go <a href="/account/login"> here</a></p>
      <p>Contact <a href="mailto:info@launchfundraising.com">info@launchfundraising.com</a> for help.</p>
    </div>
  </div>
</div>