<div class="d-flex align-items-center">
    <div *ngIf="iconOnly" href="#" [class]="togglerCssClass" id="kt_quick_user_toggle" data-kt-menu-trigger="click"
        data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
        <span class="symbol symbol-30px">
            <span class="svg-icon svg-icon-xl">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                    height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                        <path
                            d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                            fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                        <path
                            d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                            fill="#000000" fill-rule="nonzero"></path>
                    </g>
                </svg>
            </span>
        </span>
    </div>

    <div *ngIf="!iconOnly" [class]="togglerCssClass" id="kt_quick_user_toggle" data-kt-menu-trigger="click"
        data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
        <i *ngIf="isImpersonatedLogin" class="fa fa-reply text-danger pe-2"></i>
        <span [class]="symbolCssClass">
            <span [class]="symbolTextCssClass">
                {{ usernameFirstLetter }}
            </span>
        </span>
    </div>

    <div class="
            menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary
            fw-bold
            py-4
            fs-6
            w-400px
        " data-kt-menu="true">
        <div class="menu-item px-3">
            <div class="menu-content d-flex align-items-center px-3">
                <div class="symbol symbol-50px me-5">
                    <img alt="Logo" [src]="profilePicture" />
                </div>
                <div class="d-flex flex-column">
                    <div class="fw-bolder d-flex align-items-center fs-5">
                        {{ firstName }} {{ lastName }}
                    </div>
                    <span *ngIf="isMultiTenancyEnabled" class="tenancy-name">{{ tenancyName }}</span>
                </div>
            </div>
        </div>
        <div class="separator my-2"></div>
        <!-- Back to My Account -->
        <div *ngIf="isImpersonatedLogin" class="menu-item px-5" id="UserProfileBackToMyAccountButton">
            <a href="javascript:;" (click)="backToMyAccount()" class="menu-link px-5">
                <div class="symbol symbol-30px bg-light me-3">
                    <div class="symbol-label">
                        <span class="svg-icon svg-icon-primary svg-icon-2x svg-icon-danger">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24" />
                                    <path
                                        d="M21.4451171,17.7910156 C21.4451171,16.9707031 21.6208984,13.7333984 19.0671874,11.1650391 C17.3484374,9.43652344 14.7761718,9.13671875 11.6999999,9 L11.6999999,4.69307548 C11.6999999,4.27886191 11.3642135,3.94307548 10.9499999,3.94307548 C10.7636897,3.94307548 10.584049,4.01242035 10.4460626,4.13760526 L3.30599678,10.6152626 C2.99921905,10.8935795 2.976147,11.3678924 3.2544639,11.6746702 C3.26907199,11.6907721 3.28437331,11.7062312 3.30032452,11.7210037 L10.4403903,18.333467 C10.7442966,18.6149166 11.2188212,18.596712 11.5002708,18.2928057 C11.628669,18.1541628 11.6999999,17.9721616 11.6999999,17.7831961 L11.6999999,13.5 C13.6531249,13.5537109 15.0443703,13.6779456 16.3083984,14.0800781 C18.1284272,14.6590944 19.5349747,16.3018455 20.5280411,19.0083314 L20.5280247,19.0083374 C20.6363903,19.3036749 20.9175496,19.5 21.2321404,19.5 L21.4499999,19.5 C21.4499999,19.0068359 21.4451171,18.2255859 21.4451171,17.7910156 Z"
                                        fill="#000000" fill-rule="nonzero" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </div>
                {{ 'BackToMyAccount' | localize }}
            </a>
        </div>
        <!-- Linked Accounts -->
        <div *ngIf="showManageLinkedAccounts" class="menu-item px-5" data-kt-menu-trigger="hover" data-kt-menu-placement="left-start">
            <a href="javascript:;" class="menu-link px-5 linked-accounts">
                <div class="symbol symbol-30px bg-light me-3">
                    <div class="symbol-label">
                        <span class="svg-icon svg-icon-primary svg-icon-2x">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 24 22" style="enable-background:new 0 0 24 22;" xml:space="preserve">
                       <style type="text/css">
                           .st0{fill:none;stroke:#1F6A77;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
                           .st1{fill:none;stroke:#4A9D69;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
                       </style>
                       <path class="st0" d="M15,21v-1.6c0-3-3.1-5.4-7-5.4s-7,2.4-7,5.4V21"/>
                       <circle class="st0" cx="8" cy="5.5" r="4.5"/>
                       <g>
                           <path class="st1" d="M20,8v6"/>
                           <path class="st1" d="M17,11h6"/>
                       </g>
                       </svg>
                        </span>
                    </div>
                </div>
                    {{ 'ManageLinkedAccounts' | localize }}
                <span class="menu-arrow"></span>
            </a>
            <div class="menu-sub menu-sub-dropdown w-255px py-4">
                <div class="menu-item px-5" id="ManageLinkedAccountsLink">
                    <a href="javascript:;" (click)="showLinkedAccounts()" class="menu-link px-5">
                        {{ 'ManageLinkedAccounts' | localize }}
                    </a>
                </div>
                <div id="RecentlyUsedLinkedUsers" class="linked-accounts" role="menu">
                    <div class="separator my-2"></div>
                    <div class="menu-item px-5" *ngFor="let linkedUser of recentlyLinkedUsers">
                        <a
                            (click)="switchToLinkedUser(linkedUser)"
                            class="menu-link px-5 recently-linked-user"
                        >
                            {{ getShownUserName(linkedUser) }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- User Delegation -->
        <!-- <div *ngIf="appSession.application.userDelegationIsEnabled && !isImpersonatedLogin" class="menu-item px-5">
            <a href="javascript:;" (click)="showUserDelegations()" class="menu-link px-5" id="ManageUserDelegations">
                <div class="symbol symbol-30px bg-light me-3">
                    <div class="symbol-label">
                        <span class="svg-icon svg-icon-primary svg-icon-2x svg-icon-warning">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                            >
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24" />
                                    <path
                                        d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z"
                                        fill="#000000"
                                        opacity="0.3"
                                    />
                                    <path
                                        d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z"
                                        fill="#000000"
                                        opacity="0.3"
                                    />
                                    <path
                                        d="M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 C14.5228466,17 11.463736,17 7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z"
                                        fill="#000000"
                                        opacity="0.3"
                                    />
                                </g>
                            </svg>
                        </span>
                    </div>
                </div>
                {{ 'ManageUserDelegations' | localize }}
            </a>
        </div> -->
        <!-- Change Password -->
        <div class="menu-item px-5">
            <a href="javascript:;" (click)="changePassword()" class="menu-link px-5" id="UserProfileChangePasswordLink">
                <div class="symbol symbol-30px bg-light me-3">
                    <div class="symbol-label">
                        <span class="svg-icon svg-icon-primary svg-icon-2x svg-icon-success">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24"
                                style="enable-background:new 0 0 24 24;" xml:space="preserve">
                                <style type="text/css">
                                    .st0 {
                                        fill: none;
                                        stroke: #4A9D69;
                                        stroke-width: 2;
                                        stroke-linecap: round;
                                        stroke-linejoin: round;
                                        stroke-miterlimit: 10;
                                    }

                                    .st1 {
                                        fill: none;
                                        stroke: #1F6A77;
                                        stroke-width: 2;
                                        stroke-linecap: round;
                                        stroke-linejoin: round;
                                        stroke-miterlimit: 10;
                                    }
                                </style>
                                <g>
                                    <path class="st0" d="M10,12V8c0-1.1,0.9-2,2-2s2,0.9,2,2v4" />
                                    <path class="st1" d="M23,3v5h-5" />
                                    <path class="st1"
                                        d="M22.8,14c-0.9,5.1-5.4,9-10.8,9C5.9,23,1,18.1,1,12S5.9,1,12,1c4.4,0,8.3,2.6,10,6.4" />
                                    <path class="st0"
                                        d="M15,17H9c-0.6,0-1-0.4-1-1v-3c0-0.6,0.4-1,1-1h6c0.6,0,1,0.4,1,1v3C16,16.6,15.6,17,15,17z" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </div>
                {{ 'ChangePassword' | localize }}
            </a>
        </div>
        <!-- Login Attempts -->
        <div class="menu-item px-5">
            <a href="javascript:;" (click)="showLoginAttempts()" class="menu-link px-5" id="ShowLoginAttemptsLink">
                <div class="symbol symbol-30px bg-light me-3">
                    <div class="symbol-label">
                        <span class="svg-icon svg-icon-primary svg-icon-2x svg-icon-danger">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 22.8 23.2"
                                style="enable-background:new 0 0 22.8 23.2;" xml:space="preserve">
                                <g>
                                    <path
                                        style="fill:none;stroke:#1F6A77;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"
                                        d="
		M19.8,22.2h-8c-1.1,0-2-0.9-2-2v-5c0-1.1,0.9-2,2-2h8c1.1,0,2,0.9,2,2v5C21.8,21.3,20.9,22.2,19.8,22.2z" />
                                    <path
                                        style="fill:none;stroke:#1F6A77;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"
                                        d="
		M12.8,13.2v-3c0-1.7,1.3-3,3-3s3,1.3,3,3v3" />
                                </g>
                                <g>
                                    <g>
                                        <path
                                            style="fill:none;stroke:#4A9D69;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"
                                            d="M1,1
			h11" />
                                        <path
                                            style="fill:none;stroke:#4A9D69;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"
                                            d="M1,6
			h11" />
                                        <path
                                            style="fill:none;stroke:#4A9D69;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"
                                            d="
			M1,11h8" />
                                    </g>
                                </g>
                            </svg>
                        </span>
                    </div>
                </div>
                {{ 'LoginAttempts' | localize }}
            </a>
        </div>
        <!-- Profile Picture -->
        <div class="menu-item px-5 d-flex">
            <a href="javascript:;" (click)="changeProfilePicture()" class="menu-link px-5"
                id="UserProfileChangePictureLink">
                <div class="symbol symbol-30px bg-light me-3">
                    <div class="symbol-label">
                        <span class="svg-icon svg-icon-primary svg-icon-2x svg-icon-info">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24"
                                style="enable-background:new 0 0 24 24;" xml:space="preserve">
                                <g>
                                    <g>
                                        <path
                                            style="fill:none;stroke:#4A9D69;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"
                                            d="
                                   M1,17h22" />

                                        <circle
                                            style="fill:none;stroke:#4A9D69;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"
                                            cx="12" cy="7.5" r="2.5" />
                                        <path
                                            style="fill:none;stroke:#4A9D69;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"
                                            d="
                                   M18,17v-0.5c0-1.9-2.7-3.5-6-3.5s-6,1.6-6,3.5V17" />
                                    </g>
                                    <path
                                        style="fill:none;stroke:#1F6A77;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"
                                        d="
                               M21,23H3c-1.1,0-2-0.9-2-2V3c0-1.1,0.9-2,2-2h18c1.1,0,2,0.9,2,2v18C23,22.1,22.1,23,21,23z" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </div>
                {{ 'ChangeProfilePicture' | localize }}
            </a>
        </div>
        <!-- My Settings -->
        <div class="menu-item px-5 d-flex">
            <a href="javascript:;" (click)="changeMySettings()" class="menu-link px-5" id="UserProfileMySettingsLink">
                <div class="symbol symbol-30px bg-light me-3">
                    <div class="symbol-label">
                        <span class="svg-icon svg-icon-primary svg-icon-2x svg-icon-dark">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24"
                                style="enable-background:new 0 0 24 24;" xml:space="preserve">
                                <g>
                                    <path
                                        style="fill:none;stroke:#4A9D69;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"
                                        d="M11,2
		h8c2.2,0,4,1.8,4,4s-1.8,4-4,4H5c-2.2,0-4-1.8-4-4s1.8-4,4-4H11z" />
                                    <path
                                        style="fill:none;stroke:#1F6A77;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"
                                        d="M5,10
		c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S7.2,10,5,10z" />
                                    <path
                                        style="fill:none;stroke:#4A9D69;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"
                                        d="
		M13,22H5c-2.2,0-4-1.8-4-4s1.8-4,4-4h14c2.2,0,4,1.8,4,4s-1.8,4-4,4H13z" />
                                    <path
                                        style="fill:none;stroke:#1F6A77;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"
                                        d="
		M19,14c2.2,0,4,1.8,4,4s-1.8,4-4,4s-4-1.8-4-4S16.8,14,19,14z" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </div>
                {{ 'MySettings' | localize }}
            </a>
        </div>
        <!-- GDPR -->
        <!-- <div class="menu-item px-5 d-flex">
            <a
                href="javascript:;"
                (click)="downloadCollectedData()"
                class="menu-link px-5"
                id="UserDownloadCollectedDataLink"
            >
                <div class="symbol symbol-30px bg-light me-3">
                    <div class="symbol-label">
                        <span class="svg-icon svg-icon-primary svg-icon-2x">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                            >
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24" />
                                    <path
                                        d="M3.5,21 L20.5,21 C21.3284271,21 22,20.3284271 22,19.5 L22,8.5 C22,7.67157288 21.3284271,7 20.5,7 L10,7 L7.43933983,4.43933983 C7.15803526,4.15803526 6.77650439,4 6.37867966,4 L3.5,4 C2.67157288,4 2,4.67157288 2,5.5 L2,19.5 C2,20.3284271 2.67157288,21 3.5,21 Z"
                                        fill="#000000"
                                        opacity="0.3"
                                    />
                                    <path
                                        d="M14.8875071,12.8306874 L12.9310336,12.8306874 L12.9310336,10.8230161 C12.9310336,10.5468737 12.707176,10.3230161 12.4310336,10.3230161 L11.4077349,10.3230161 C11.1315925,10.3230161 10.9077349,10.5468737 10.9077349,10.8230161 L10.9077349,12.8306874 L8.9512614,12.8306874 C8.67511903,12.8306874 8.4512614,13.054545 8.4512614,13.3306874 C8.4512614,13.448999 8.49321518,13.5634776 8.56966458,13.6537723 L11.5377874,17.1594334 C11.7162223,17.3701835 12.0317191,17.3963802 12.2424692,17.2179453 C12.2635563,17.2000915 12.2831273,17.1805206 12.3009811,17.1594334 L15.2691039,13.6537723 C15.4475388,13.4430222 15.4213421,13.1275254 15.210592,12.9490905 C15.1202973,12.8726411 15.0058187,12.8306874 14.8875071,12.8306874 Z"
                                        fill="#000000"
                                    />
                                </g>
                            </svg>
                        </span>
                    </div>
                </div>
                {{ 'DownloadCollectedData' | localize }}
            </a>
        </div> -->
        <div class="separator my-2"></div>
        <div class="menu-item px-5 d-flex">
            <a href="javascript:;" (click)="logout()" class="menu-link px-5">
                <div class="symbol symbol-30px bg-light me-3">
                    <div class="symbol-label">
                        <span class="svg-icon svg-icon-muted svg-icon-2hx">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24"
                                style="enable-background:new 0 0 24 24;" xml:space="preserve">
                                <g>
                                    <path
                                        style="fill:none;stroke:#4A9D69;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"
                                        d="
		M11.8,19.4h6c1,0,1.7-0.8,1.7-1.7V5.8c0-1-0.8-1.7-1.7-1.7h-6" />
                                    <g>
                                        <path
                                            style="fill:none;stroke:#1F6A77;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"
                                            d="
			M7.6,8.3l-3.4,3.4l3.4,3.4" />
                                        <path
                                            style="fill:none;stroke:#1F6A77;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"
                                            d="
			M4.1,11.8h10.3" />
                                    </g>
                                </g>
                            </svg>
                        </span>
                    </div>
                </div>
                {{ 'Logout' | localize }}
            </a>
        </div>
    </div>
</div>