import { Component, EventEmitter, Injector, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { CountdownComponent, CountdownConfig, CountdownEvent } from 'ngx-countdown';
import { DateTime } from 'luxon';
import { FundraisersServiceProxy, GetFundraiserForLiveLink } from '../../../../../../shared/service-proxies/service-proxies';

@Component({
    selector: 'live-link-countdown',
    templateUrl: './live-link-countdown.component.html',
    styleUrls: ['./live-link-countdown.component.less'],
    animations: [appModuleAnimation()]
})
export class LiveLinkCountdownComponent extends AppComponentBase {
    _fundraiserForLiveLink: GetFundraiserForLiveLink;
    @Input() set fundraiserForLiveLink(value: GetFundraiserForLiveLink) {
        if (!value || (
            // this comes from a signal-r message meant for updating something other than the countdown.
            !value.fundraiserEnd && !value.hourathonEnd && !value.fundraiserEnd
        )) {
            return;
        }

        this._fundraiserForLiveLink = value;
        this.fundraiserId = this._fundraiserForLiveLink.fundraiserId;

        // test fundraiser expiration countdown roll-over:
        // const nowUtcSeconds = DateTime.now().toSeconds();
        // this._fundraiserForLiveLink.hourathonEnd = nowUtcSeconds + 25;
        // this._fundraiserForLiveLink.isFundraiserEndUtc = true;
        // this._fundraiserForLiveLink.fundraiserEnd = nowUtcSeconds + 35;
        // this.secondsInDay = 0;

        this.setupTimerAndButtons();
    }
    @ViewChild('cd', { static: true }) private ct: CountdownComponent;

    showTimer: boolean = false;
    showCountdownButton: boolean = false;
    showResetButton: boolean = false;
    
    isFundraiserOver: boolean = false;
    fundraiserId: number = 0;

    config: CountdownConfig = {};
    daysLeft: string;
    get dayVsDays(): string {
        let result = 'days';
        if (this.daysLeft == '1') {
            result = 'day'
        }
        return result;
    }
    secondsInDay: number = 86400;

    countdownStatus: 'running' | 'ended' | 'paused' | null;
    receivedInitialDone: boolean = false;

    hourathonCountdownAudio: HTMLAudioElement;
    hourathonCountdownAudioTimer: NodeJS.Timeout = undefined;

    get buttonText(): string {
        switch (this.countdownStatus) {
            case 'running':
                return 'Pause';
            case 'paused':
                return 'Continue';
            default:
                return 'Start';
        }
    }

    constructor(
        injector: Injector,
        private _fundraisersServiceProxy: FundraisersServiceProxy
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.hourathonCountdownAudio = new Audio();
        this.hourathonCountdownAudio.src = '/assets/common/sounds/ten-second-countdown.mp3';
        this.hourathonCountdownAudio.load();
    }

    nowUtcSeconds: number;
    offset: number;
    getSecondsLeftInFundraiser(now: DateTime = null) {
        if (this._fundraiserForLiveLink.fundraiserEnd == null) {
            return null;
        }
        if (now == null) {
            now = DateTime.now();
        }
        this.nowUtcSeconds = now.toSeconds();

        // if !isFundraiserEndUtc, assume client is in same timezone as fundraiser.
        this.offset = this._fundraiserForLiveLink.isFundraiserEndUtc ? 0 : now.offset * 60;
        // end date is stored in the DB with midnight (the start) of the day for time,
        // so add a day's worth of seconds (86400) to the result.
        const result = this._fundraiserForLiveLink.fundraiserEnd - this.nowUtcSeconds - this.offset + this.secondsInDay;
        this.nowUtcSeconds = null;
        this.offset = null;
        return result;
    }

    clearHourathonCountdownAudioTimer() {
        clearTimeout(this.hourathonCountdownAudioTimer);
        this.hourathonCountdownAudioTimer = undefined;
        return;
    }

    startHourathonCountdownAudioTimer() {
        if (this.hourathonCountdownAudioTimer === undefined) {
            // Play really short audio to determine if user needs to interact with page according
            // to MEI score https://developer.chrome.com/blog/autoplay/
            this.hourathonCountdownAudio.currentTime = this.hourathonCountdownAudio.duration - 0.01;
            this.executeHourathonCountdownAudio();
            // Calculate time to countdown. If time is shorter than file length delay 0
            const secondsToDelay = this.getSecondsLeftInHourathon() - this.hourathonCountdownAudio.duration;
            this.hourathonCountdownAudioTimer = setTimeout(this.playHourathonCountdownAudio, (secondsToDelay > 0 ? secondsToDelay : 0) * 1000);
        }
    }

    // 4 possible hourathon states: not started, running, paused, ended.
    // 2 possible fundraiser states: not-ended, ended.
    setupTimerAndButtons() {
        this.showTimer = false;
        this.showCountdownButton = false;
        this.showResetButton = false;

        const now = DateTime.now();

        const timeSecondsInFundraiser = this.getSecondsLeftInFundraiser(now);

        // hourathonEnd is seconds since UNIX epoch, UTC.
        const hourathonEndTimeFromNow = this.getSecondsLeftInHourathon();

        if (timeSecondsInFundraiser < 0) {
            // fundraiser is over.
            this.isFundraiserOver = true;
            this.clearHourathonCountdownAudioTimer();
        }
        else if (hourathonEndTimeFromNow != null) {
            // hourathon is running or has ended
            if (hourathonEndTimeFromNow > 0) {
                // hourathon is running
                this.countdownStatus = 'running';
                this.setupTimer(hourathonEndTimeFromNow, true);
                this.showTimer = true;
                this.showCountdownButton = this.permission.isGranted('Pages.Fundraisers.Team.Manage');
                this.showCountdownButton = true;

                this.startHourathonCountdownAudioTimer();
            }
            else {
                // hourathon has ended
                this.configureForHourathonDone(now, timeSecondsInFundraiser);
            }
        }
        else if (this._fundraiserForLiveLink.hourathonSecondsRemaining != null) {
            // hourathon is paused.
            this.countdownStatus = 'paused';
            this.setupTimer(this._fundraiserForLiveLink.hourathonSecondsRemaining, false);
            this.showTimer = true;
            this.showResetButton = this.permission.isGranted('Pages.Fundraisers.Team.Manage');
            this.showCountdownButton = this.permission.isGranted('Pages.Fundraisers.Team.Manage');
            this.clearHourathonCountdownAudioTimer();         
        }
        else {
            // hourathon has not been started.
            this.showCountdownButton = this.permission.isGranted('Pages.Fundraisers.Team.Manage');   
        }
    }

    setupTimer(leftTime: number, startTimer: boolean) {
        //this.ct.begin() isn't working, so we'll set it running or not with 'demand' in the config.
        this.config = {
            format: `HH:mm:ss`,
            leftTime: leftTime,
            demand: !startTimer
        };
    }

    toggleTimer() {
        // clear _fundraiserForLiveLink so that everything reloads when parent resets it.
        this._fundraiserForLiveLink = null;
        this._fundraisersServiceProxy.toggleHourathon(this.fundraiserId).subscribe(result => {
            // no need to tell the parent to refresh,
            // because all clients should get a signal r message.
        });
    }

    handleEvent(event: CountdownEvent) {
        if (event.action == 'done') {
            if (!this.receivedInitialDone) {
                // we get a 'done' on page load, for some reason...
                this.receivedInitialDone = true;
                return;
            }
            this.configureForHourathonDone();
        }
    }

    configureForHourathonDone(now: DateTime = null, secondsLeftInFundraiser: number = null) {
        this.showCountdownButton = false;
        if (secondsLeftInFundraiser == null) {
            now = DateTime.now();
            secondsLeftInFundraiser = this.getSecondsLeftInFundraiser(now);
        }   
        if (secondsLeftInFundraiser > 0) {
            this.daysLeft = (secondsLeftInFundraiser / this.secondsInDay).toString().split('.')[0];
            this.setupTimer(secondsLeftInFundraiser, true);
            this.setDaysLeftTimeout(now, false);
            this.showResetButton = this.permission.isGranted('Pages.Fundraisers.Team.Manage');
            this.showTimer = true;
            this.countdownStatus = 'ended';
        }
        else {
            this.showFundraiserEnded();
        }        
    }

    setDaysLeftTimeout(now: DateTime, isSelfTriggered: boolean) {
        // if the hourathon has just ended or we're re-loading the page,
        // start a Timeout to run in parallel with the countdown,
        // for decrementing of daysLeft, since countdown component doesn't trigger an event when a day rolls over.

        if (isSelfTriggered) {
            // countdown timer must have rolled-over, so decrement day.
            this.daysLeft = (+ this.daysLeft - 1).toString();
        }
        if (+this.daysLeft >= 0) {
            let millisecondsLeftInFundraiserDay = (this.getSecondsLeftInFundraiser() % this.secondsInDay) * 1000;
            setTimeout(() => this.setDaysLeftTimeout(DateTime.now(), true), millisecondsLeftInFundraiserDay);
        }
        else {
            this.showFundraiserEnded();
        }
    }

    showFundraiserEnded() {
        this.isFundraiserOver = true;
        this.showResetButton = false;
    }

    resetHourathon() {
        this.message.confirm(
            this.l('ThisWillResetLaunchAThon'),
            this.l('AreYouSure'),
            (isConfirmed) => {
                if (isConfirmed) {
                    this.countdownStatus = null;
                    this._fundraiserForLiveLink = null;
                    this._fundraisersServiceProxy.resetHourathon(this.fundraiserId).subscribe(result => {
                        // no need to tell the parent to refresh,
                        // because all clients should get a signal r message.
                    });
                }
            }
        );
    }

    executeHourathonCountdownAudio = (isRetry: boolean = false) => {
        if (this.countdownStatus && this.countdownStatus === 'ended') {
            return;
        }
        var promise = this.hourathonCountdownAudio.play();
        if (promise !== undefined) {
            promise.then(_ => {}).catch(_ => {
                // playing sound was blocked by the browser, which requires a user 'interaction'.
                // let's prompt for an interaction, while also giving the user a chance to say they don't want sounds.
                this.message.confirm(this.l('HowToMuteCountdown'), this.l('EnableSound'), (isConfirmed) => {
                    if (isConfirmed && !isRetry) {
                        // the user clicking a button is enough of an interaction to trigger the browser to allow playing sounds now.
                        // play the sound to 'confirm':
                        this.executeHourathonCountdownAudio(true);
                    }
                });
            });
        }
    }

    playHourathonCountdownAudio = () => {        
        if (!this.countdownStatus || this.countdownStatus !== 'running') {
            return;
        }

        this.hourathonCountdownAudio.currentTime = 0;

        // If time remaining is shorter than the length of the audio
        // file change start time to time remaining
        if (this.getSecondsLeftInHourathon() < this.hourathonCountdownAudio.duration) {
            this.hourathonCountdownAudio.currentTime = Math.abs(this.getSecondsLeftInHourathon() - this.hourathonCountdownAudio.duration);
        }

        this.executeHourathonCountdownAudio();
    }

    getSecondsLeftInHourathon = () => {
        return this._fundraiserForLiveLink?.hourathonEnd == null ? null : this._fundraiserForLiveLink.hourathonEnd - DateTime.now().toSeconds()
    }
}
