<div class="d-flex flex-row justify-content-center w-100 pt-8">
    <div class="footer-organization-logo-container my-auto" [ngClass]="isDarkBackground ? 'bg-unset': ''">
        <img src="{{tenantLogoUrl}}" alt="logo" class="hour-a-thon-logo">
    </div>
</div>
<div [ngClass]="isDarkBackground ? 'footer-links-dark-bg': ''">
    <div class="footer-links d-flex flex-wrap justify-content-center">
        <a rel="noopener" target="_blank" [href]="getStartedLink">{{l('ContactUs')}}</a>
        <a rel="noopener" target="_blank" [href]="getStartedLink">{{l('RunOwnFundraiser')}}</a>
        <a rel="noopener" target="_blank" [href]="getStartedLink">{{l('JoinOurTeam')}}</a>
    </div>
    <div class="footer-links d-flex flex-wrap justify-content-center">
        <a rel="noopener" href="{{appUrlService.appRootUrl}}TermsOfUse.html" target="_blank">{{l('TermsOfUse')}}</a>
        <a rel="noopener" href="https://www.launchfundraising.com/privacy-policy" target="_blank">{{l('PrivacyPolicy')}}</a>
    </div>
</div>
<div class="d-flex flex-row justify-content-center w-100 powered-by-container">
    <div class="powered-by-text me-1 mt-1" [ngClass]="isDarkBackground ? 'text-white': ''">{{l('PoweredBy')}}</div>
    <img alt="Launch Logo" src="/assets/common/images/logo-launch.png" class="powered-by-logo" />
</div>