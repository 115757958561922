import { Injectable } from '@angular/core';  
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';  
import { Observable, of } from 'rxjs';  
import { tap } from 'rxjs/operators';  
import { HttpCacheService } from './http-cache.service';  
  
  
@Injectable()  
export class CacheInterceptor implements HttpInterceptor {  
  
  constructor(private cacheService: HttpCacheService) { }  
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {  
  
    // pass along non-cacheable requests and invalidate cache  
    if(req.method !== 'GET') {  
      // Don't invalidateCache yet - this happens when a PUT/POST/DELETE happens. 
      // For us, we are limiting the endpoints that are cached, so no need to 
      // call invalidateCache now

      // console.log(`Invalidating cache: ${req.method} ${req.url}`);  
      // this.cacheService.invalidateCache();  
      return next.handle(req);  
    }

    // Only cache certain URLs (e.g. url contains GetListItemsForLookupTable)
    if (req.url.indexOf('GetListItemsForLookupTable') < 0) {
      return next.handle(req);  
    }
  
    // attempt to retrieve a cached response  
    const cachedResponse: HttpResponse<any> = this.cacheService.get(req.url);  
  
    // return cached response  
    if (cachedResponse) {  
      // console.log(`Returning a cached response: ${cachedResponse.url}`);  
      return of(cachedResponse);  
    }      
  
    // send request to server and add response to cache  
    return next.handle(req)  
      .pipe(  
        tap(event => {  
          if (event instanceof HttpResponse) {  
            // console.log(`Adding item to cache: ${req.url}`);  
            this.cacheService.put(req.url, event);  
          }  
        })  
      );  
  
  }  
} 