import { Component, OnDestroy } from '@angular/core';
import { NgxSpinnerTextService } from '@app/shared/ngx-spinner-text.service';
import { GaHelperService } from '@shared/common/google-analytics/ga-helper.service';
import { Subject, timer } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { has } from 'lodash-es';

@Component({
    selector: 'app-root',
    template: `
        <router-outlet></router-outlet>
        <ngx-spinner type="ball-clip-rotate" size="medium" color="#5ba7ea">
            <p *ngIf="ngxSpinnerText">{{ getSpinnerText() }}</p>
        </ngx-spinner>
    `,
})
export class RootComponent implements OnDestroy {
    private destroy$ = new Subject<void>();
    ngxSpinnerText: NgxSpinnerTextService;
    gaHelper: GaHelperService;
    router: Router;

    constructor(
        _ngxSpinnerText: NgxSpinnerTextService,
        _gaHelper: GaHelperService,
        _router: Router) {
        this.ngxSpinnerText = _ngxSpinnerText;
        this.gaHelper = _gaHelper;
        this.router = _router;
    }

    getSpinnerText(): string {
        return this.ngxSpinnerText.getText();
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
    logRouteToGa() {
        timer(500)
            .pipe(
                filter(() => has.call(window, 'ga')),
                take(1),
                switchMap(() => {
                    return this.router.events.pipe(
                        filter((e) => e instanceof NavigationEnd),
                        tap((e: NavigationEnd) => {
                            this.gaHelper.logPageView(e.url);
                        })
                    );
                }),
                takeUntil(this.destroy$)
            )
    }
}
