﻿<div *ngIf="gotAgreement && (!agreement || !agreement.name)" class="alert alert-error">{{l("NoAgreementForLink")}}</div>
<div *ngIf="agreement && agreement.isRequestForSignatureExpired" class="alert alert-error">{{l("RequestForSignatureExpired") + agreement.repContactInfo}}</div>
<ng-container *ngIf="agreement?.name && !agreement.isRequestForSignatureExpired">
    <img *ngIf="!hasLogo" [src]="defaultLogo" alt="logo" width="139" height="35" />
    <img *ngIf="hasLogo" [src]="imageSource" alt="logo" width="139" height="35" />
    <h4 *ngIf="agreement">
        <span *ngIf="!viewTerms">{{agreement.name}}</span>
        <span *ngIf="viewTerms">{{l("SignatureTerms")}}</span>
    </h4>
    <form *ngIf="agreement && !viewTerms" novalidate (ngSubmit)="sign()" autocomplete="off">
        <div class="mb-5">
            <label class="form-label" for="ContactName">{{ 'Contact' | localize }} *</label>
            <input id="ContactName" class="form-control" type="text" name="ContactName" [(ngModel)]="agreement.contactName" disabled />
        </div>
        <div class="mb-5">
            <label for="contactEmail">{{l("EmailAddress")}}</label>
            <input id="contactEmail" type="text" class="form-control" [(ngModel)]="agreement.contactEmail" name="contactEmail" disabled />
        </div>
        <div class="mb-5">
            <label for="organizationName">{{l("Organization")}}</label>
            <input id="organizationName" type="text" class="form-control" [(ngModel)]="agreement.organizationName" name="organizationName" disabled />
        </div>
        <div class="mb-5">
            <label>{{l("Address")}}</label>
            <textarea class="form-control" name="address" rows="3" cols="100" disabled>{{agreement.organizationAddress}}</textarea>
        </div>
        <div class="mb-5">
            <label for="Organization_Mascot">{{l("Mascot")}}</label>
            <input type="text" class="form-control" [(ngModel)]="agreement.organizationMascot" name="Mascot" disabled />
        </div>
        <div class="mb-5">
            <label for="Organization_SchoolColors">{{l("SchoolColors")}}</label>
            <input type="text" class="form-control" [(ngModel)]="agreement.organizationSchoolColors" name="SchoolColors" disabled />
        </div>
        <div class="mb-5 m-form__group">
            <label>{{l("ProductGroup")}}</label>
            <div class="input-group">
                <input class="form-control" name="productGroupName" [(ngModel)]="agreement.productGroupName" type="text" disabled>
            </div>
        </div>
        <!-- <input class="form-control" name="agreement.productGroupId" [(ngModel)]="agreement.productGroupId" type="text" hidden> -->
        <div class="row">
            <div class="mb-5 m-form__group col-6">
                <label>{{l("Season")}}</label>
                <input class="form-control" name="season" [(ngModel)]="agreement.quarter" type="text" disabled>
            </div>
            <div class="mb-5 col-6">
                <label>{{l("Year")}} </label>
                <input type="number" class="form-control" [(ngModel)]="agreement.year" name="Year" disabled />
            </div>
        </div>
        <div class="mb-5">
            <label>{{l("NumberOfStudents")}} </label>
            <input type="number" class="form-control" [(ngModel)]="agreement.numberOfStudents" name="NumberOfStudents" disabled />
        </div>
        <div class="mb-5 m-form__group row">
            <label for="StartDate" class="col-md-12">
                {{l("StartDate")}}
            </label>
            <div class="col-lg-10 col-md-9 col-sm-8">
                <input class="form-control m-input" [value]="startDate" name="StartDate" disabled>
            </div>
        </div>
        <div class="mb-5 m-form__group row">
            <label for="EndDate" class="col-md-12">{{l("EndDate")}}</label>
            <div class="col-lg-10 col-md-9 col-sm-8">
                <input class="form-control m-input" [value]="endDate" name="EndDate" disabled>
            </div>
        </div>
        <div class="mb-5 m-form__group row">
            <label for="DeliveryDate" class="col-md-12">{{l("DeliveryDate")}}</label>
            <div class="col-lg-10 col-md-9 col-sm-8">
                <input class="form-control m-input" [value]="deliveryDateString" name="DeliveryDate" disabled>
            </div>
        </div>
        <div class="mb-5">
            <label>{{l("ChecksPayableName")}}</label>
            <input type="text" class="form-control" [(ngModel)]="agreement.checksPayableName" name="ChecksPayableName" disabled />
        </div>
        <form>
            <p>{{l("ProviderRep")}}</p>
            <div class="mb-5">
                <img #providerSignatureImage [src]="agreement.signatures[1].signatureBase64">
            </div>
            <div class="mb-5">
                <label>{{l("Name")}}</label>
                <input type="text" class="form-control" [(ngModel)]="agreement.signatures[1].name" name="ProviderName" disabled />
            </div>
            <div class="mb-5">
                <label>{{l("SignedDate")}}</label>
                <input class="form-control m-input" type="text" [value]="repSignatureDateString" name="OrganizationSignDate" disabled />
            </div>
        </form>
        <hr>
        <br>
        <ng-container *ngIf="!agreement.signatures[0].signatureBase64; else organizationSignature">
            <div class="mb-5 m-form__group row" style="margin-left: 0; margin-right: 0;">
                <label>{{l("OrganizationRep")}}</label>
                <div class="input-group">
                    <signature-pad class="signature-pad" #organizationSignaturePad [options]="signaturePadOptions"></signature-pad>
                    <div class="input-group-append">
                        <button class="btn btn-danger" (click)="clearSignature()" [disabled]="saving" type="button">
                            <i class="fas fa-times"></i>
                        </button>
                        <button type="button" (click)="sign()" class="btn btn-primary blue">
                            {{l("Sign")}}
                        </button>
                    </div>
                </div>
            </div>
            <div class="mb-5">
                <label>{{l("Name")}}</label>
                <input type="text" class="form-control" [(ngModel)]="agreement.signatures[0].name" name="OrganizationName" disabled />
            </div>
            <div class="mb-5 m-form__group row">
                <label for="SignedDate" class="col-md-12">
                    {{l("SignedDate")}}
                </label>
                <div class="col-lg-10 col-md-9 col-sm-8">
                    <input class="form-control m-input" type="text" [value]="now" name="OrganizationSignDate" disabled />
                </div>
            </div>
        </ng-container>
        <ng-template #organizationSignature>
            <form>
                <div class="mb-5">
                    <p>{{l("OrganizationRep")}}</p>
                    <img #organizationSignatureImage [src]="agreement.signatures[0].signatureBase64">
                </div>
                <div class="mb-5">
                    <label>{{l("Name")}}</label>
                    <input type="text" class="form-control" [(ngModel)]="agreement.signatures[0].name" name="OrganizationName" disabled />
                </div>
                <div class="mb-5">
                    <label>{{l("SignedDate")}}</label>
                    <input class="form-control m-input" type="text" [value]="customerSignatureDateString" name="OrganizationSignDate" disabled />
                </div>
            </form>
        </ng-template>
        <button class="btn btn-default" (click)="viewTerms=true;">{{l("SignatureViewTerms")}}</button>
    </form>
    <div *ngIf="agreement && viewTerms">
        <div class="mb-5" *ngIf="agreement.splitSummary">
            <div class="margin-bottom-10">{{l("SplitForProductGroup")}} {{agreement.productGroupName}}:</div>
            <div *ngIf="agreement.splitSummary.isRetroactive" class="container-fluid pl-4 pr-4">
                <div class="row border-bottom">
                    <div class="col-3">Quantity</div>
                    <div class="col-3">{{l("Profit")}}</div>
                </div>
                <div class="row mt-2">
                    <div class="col-3">
                        1 - {{agreement.splitSummary.tiers[0].maximum}}
                    </div>
                    <div class="col-3">
                        {{getFormattedProfit(agreement.splitSummary.isPercent, agreement.splitSummary.tiers[0].profit)}}
                    </div>
                </div>
                <div class="row mt-2" *ngFor="let splitTier of agreement.splitSummary.tiers.slice(1,agreement.splitSummary.tiers.length); index as i">
                    <div class="col-3">
                        Over {{splitTier.minimum - 1}}
                    </div>
                    <div class="col-3">
                        {{getFormattedProfit(agreement.splitSummary.isPercent, splitTier.profit)}}
                    </div>
                </div>
            </div>
            <div *ngIf="!agreement.splitSummary.isRetroactive" class="container-fluid pl-4 pr-4">
                <div class="row border-bottom">
                    <div class="col-3">Quantity</div>
                    <div class="col-3">{{l("Profit")}}</div>
                </div>
                <div class="row mt-2" *ngFor="let splitTier of agreement.splitSummary.tiers.slice(0,agreement.splitSummary.tiers.length - 1); index as i">
                    <div class="col-3">
                        {{splitTier.minimum}} - {{splitTier.maximum}}
                    </div>
                    <div class="col-3">
                        {{getFormattedProfit(agreement.splitSummary.isPercent, splitTier.profit)}}
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-3">
                        {{agreement.splitSummary.tiers[agreement.splitSummary.tiers.length - 1].minimum}} +
                    </div>
                    <div class="col-3">
                        {{getFormattedProfit(agreement.splitSummary.isPercent, agreement.splitSummary.tiers[agreement.splitSummary.tiers.length - 1].profit)}}
                    </div>
                </div>
            </div>
        </div>
        <div [innerHtml]="agreement.content"></div>
        <button class="btn btn-primary blue" (click)="viewTerms=false;">{{l("SignatureGoBack")}}</button>
    </div>
</ng-container>