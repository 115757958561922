import { Component, Input } from "@angular/core";
import { appModuleAnimation } from "../../../../shared/animations/routerTransition";
import { AppComponentBase } from "../../../../shared/common/app-component-base";
import { GoalNumbers } from "../fundraiser-home/fundraiser-home.component";

@Component({
    selector: 'fundraiser-header',
    templateUrl: './fundraiser-header.component.html',
    styleUrls: ['./fundraiser-header.component.less'],
    animations: [appModuleAnimation()]
})
export class FundraiserHeaderComponent extends AppComponentBase {
    @Input() organizationName: string;
    @Input() fundraiserName: string;
    @Input() teamRaised: number;
    @Input() effectiveTeamGoal: number;
    @Input() teamGoal: number;
    @Input() teamGoalPercentActual: number;
    @Input() teamGoalPercentEffective: number;
    @Input() isMetricDollars: boolean;
    @Input() isLaunchathon: boolean;
    @Input() fundraiserUserId: number;

    @Input() studentRaised: number;
    @Input() isFundraiserOver: boolean;
    @Input() studentGoal: number;
    @Input() effectiveStudentGoal: number;
    @Input() studentGoalPercentActual: number;
    @Input() studentGoalPercentEffective: number;
    @Input() studentGoalRemaining: number;
    @Input() studentName: string;
    @Input() logoUrl: string;
    @Input() tenantLogoUrl: string;

    @Input() goalValues: GoalNumbers[];

    @Input() isCheckout: boolean = false;
    @Input() teamMemberNameInput: string;
}