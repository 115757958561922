import { Component, EventEmitter, Injector, Output } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { CreateISponsorAccountDto, ISponsorIntegrationServiceProxy } from "@shared/service-proxies/service-proxies";

@Component({
    selector: 'app-isponsor-promotion',
    templateUrl: './isponsor-promotion.component.html',
    styleUrls: ['./isponsor-promotion.component.less'],
})
export class ISponsorPromotionComponent extends AppComponentBase {
    @Output() closeEmitter: EventEmitter<void> = new EventEmitter<void>();

    // not making this an Input means I can clear the dto from the parent when its set and not have to worry about it later
    // this is necessary because we have sensitive information in this dto and need to clear it as soon as possible.
    customerInformation: CreateISponsorAccountDto;

    showConfirmationMessage: boolean = false;
    isLoading: boolean = false;

    constructor(
        injector: Injector,
        private _iSponsorAppService: ISponsorIntegrationServiceProxy,
    ) {
        super(injector);
    }

    optInToISponsor() {
        this.isLoading = true;
        this._iSponsorAppService.createISponsorAccount(this.customerInformation).subscribe(() => {
            this.clearOutCustomerInformation();
            this.showConfirmationMessage = true;
        }, 
        () => {}, 
        () => { this.isLoading = false; });
    }

    clearOutCustomerInformation() {
        this.customerInformation = null;
    }

    close() {
       this.clearOutCustomerInformation();
       this.closeEmitter.emit();
    }
}