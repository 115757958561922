import { ErrorHandler, inject, Injector } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import * as elmahIoJavascript from 'elmah.io.javascript';
export class ElmahIoErrorHandler implements ErrorHandler {
    injector = inject(Injector);
    logger: elmahIoJavascript = undefined;
    _sessionService: any;
    constructor() { }
    handleError(error) {
        if (!this._sessionService) {
            // injecting app-session service breaks the app, apparently because it's not ready.
            // Lazy loading here apparently gives it a chance to be initialized.
            import("../../../shared/common/session/app-session.service").then((file) => {
                this._sessionService = this.injector.get(file.AppSessionService);
                this.logError(error);
            });
        }
        else {
            this.logError(error);
        }
    }
    logError(error) {
        let currentUser = this._sessionService.user?.emailAddress;
        let tenantName = typeof this._sessionService.tenant?.name == 'undefined' ? '(no customer)' : this._sessionService.tenant.name;
        if ((AppConsts.elmahLogId != undefined && AppConsts.elmahLogId != '') && this.logger == undefined) {
            this.logger = new elmahIoJavascript({
                apiKey: AppConsts.elmahApiKey,
                logId: AppConsts.elmahLogId,
                application: AppConsts.elmahApplication
            });
        }
        let messageTitle = error && error.message ? error.message : 'Error in application';
        let message: elmahIoJavascript.Message = this.logger.message(error);
        message.user = `${currentUser} ${tenantName}`;
        message.title = messageTitle;
        this.logger.log(message);
        console.error(error);
    }
}