import { Component, OnInit, Input } from '@angular/core';

// TODO: We are not using ngx-sharebuttons for X (formerly Twitter) until we update the package and Angular. The X share button has been made custom in the meantime. Once we update Angular we can update the package and use it as-is for X.

@Component({
  selector: 'app-x-share-button',
  templateUrl: './x-share-button.component.html',
  styleUrls: ['./x-share-button.component.less']
})
export class XShareButtonComponent implements OnInit {
    @Input() url: string = '';
    @Input() description: string = '';
    @Input() title: string = '';
    @Input() applyMarginTop: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  shareOnX(): void {
      let shareUrl = encodeURIComponent(this.url);
      let shareDescription = encodeURIComponent(this.description);

      window.open(`https://x.com/intent/post?url=${shareUrl}&text=${shareDescription}`, '_blank');
  }
}
