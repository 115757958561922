import { enableProdMode, ApplicationRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';
import './polyfills.ts';
import { RootModule } from './root.module';
import { enableDebugTools } from '@angular/platform-browser';

if (environment.production) {
    enableProdMode();
}

const bootstrap = () => {
    var module = platformBrowserDynamic().bootstrapModule(RootModule);

    module.then(moduleRef => {
            const applicationRef = moduleRef.injector.get(ApplicationRef);
            const componentRef = applicationRef.components[0];
            // allows to run `ng.profiler.timeChangeDetection();`
            enableDebugTools(componentRef);
        })
        .catch(err => console.log(err));
    return module;
};

/* "Hot Module Replacement" is enabled as described on
 * https://medium.com/@beeman/tutorial-enable-hrm-in-angular-cli-apps-1b0d13b80130#.sa87zkloh
 */

if (environment.hmr) {
    if (module['hot']) {
        hmrBootstrap(module, bootstrap); //HMR enabled bootstrap
    } else {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }
} else {
    bootstrap(); //Regular bootstrap
}
