import { Component, Injector, NgZone, ViewChild, ElementRef, HostListener, Inject, Renderer2, ChangeDetectorRef } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { ActivatedRoute, Router } from '@angular/router';
import { FundraisersServiceProxy, GetFundraiserUserInfo, CalendarDonationDto, CalendarMonthDto, CalendarDayDto, CalendarFundraiserInputDto, CalendarMonthInputDto } from '../../../../shared/service-proxies/service-proxies';
import { QuickSelection } from './quick-selection/quick-selection';
import { HelpReachGoalInfo } from '../help-reach-goal/help-reach-goal.component';
import { GoalNumbers } from '../fundraiser-home/fundraiser-home.component';
import { CompleteOrderInfo, OrderDetailPlusProductAndPackageViewModels } from '../checkout/checkout.component';
import { NgModel } from '@angular/forms';
import { DateTime } from 'luxon';
import { AppConsts } from '@shared/AppConsts';
import { DOCUMENT } from '@angular/common';
import { OrderConfirmationComponent } from '../order-confirmation/order-confirmation.component';

@Component({
    templateUrl: './calendar-fundraiser-home.component.html',
    styleUrls: ['./calendar-fundraiser-home.component.less'],
    animations: [appModuleAnimation()]
})
export class CalendarFundraiserHomeComponent extends AppComponentBase {
    @ViewChild('teamMemberSelect', { static: false }) teamMemberSelect: NgModel;
    @ViewChild('selectDonationArea', { static: false }) donationOptionsRow: ElementRef;
    @ViewChild('donationAmountInput', { static: false }) donationAmountInput: NgModel;
    @ViewChild('orderConfirmation') orderConfirmationComponent: OrderConfirmationComponent;

    idForFundraiserInfo: string;  // fundraiserUser or fundraiser ID
    fundraiserUserId: number;
    fundraiserStudentContactSlug: string;
    automatedMessageType: string;
    tenantId: number;
    months: CalendarMonthDto[] = [];
    currentMonth: any = {};
    donations: CalendarDonationDto[] = [];
    totalDonations: number = 0;
    minimumDonation: number = 10;
    daysInMonth: number = 31;
    totalMonthAmount: number = 496; // sum of 1 through 31. Sum=n∗(n+1)/2
    naDay: string = "NA";
    isWriteInSeller: boolean = false;
    writeInSellerTeamMemberId: number = -1;
    writeInSellerFirstName: string;
    writeInSellerLastName: string;
    fundraiserStudentName: string;
        
    nextCalendarBtnVisible: boolean = false;
    previousCalendarBtnVisible: boolean = false;

    finishQuickSelection: QuickSelection;
    evenQuickSelection: QuickSelection;
    oddQuickSelection: QuickSelection;
    dayQuickSelections: QuickSelection[] = [];
    allQuickSelections: QuickSelection[] = [];

    daysLeft: string;
    get dayVsDays(): string {
        let result = 'Days';
        if (this.daysLeft == '1') {
            result = 'Day'
        }
        return result;
    }
    donationAmount: number = 0;
    donationOptions = [500, 250, 150, 100, 50];

    // TODO: We are not using ngx-sharebuttons for X (formerly Twitter) until we update the package and Angular. In the meantime the X share button has been made custom (x-share-button). Once we update Angular we can update the package and use it as-is for X.
    appListing: string[] = ['facebook', 'email'];
    appListingSms: string[] = ['sms'];
    shareTitle: string;
    shareDescription: string;
    shareUrl: string;
    shareUrlSms: string;

    teamMemberId: number;

    helpReachGoalInfo: HelpReachGoalInfo;

    isApplePayEnabledForFundraiser: boolean = false;
    isISponsorEnabledForFundraiser: boolean = false;

    changeStudent(teamMemberId) {
        this.teamMemberId = teamMemberId;

        // if teammember is a student then navigate to it if not (Other) then just display the name+last fields and enable the calendar for Write-In sellers.
        if (teamMemberId != this.writeInSellerTeamMemberId) {
            this._router.navigate([`fundraiserHomeInfo/student/${teamMemberId}`]);
        } else {
            // always brand new calendar when "Other" (writeInSellerTeamMemberId) is selected.
            this.fundraiserStudentName = null;
            this.months = [];
            this.initCalendar(0, true);
        } 
    }

    initCalendar(monthNumber: number, isNew: boolean = false) {
        if (isNew) {
            this.createNewCalendarMonth();
            this.createNewDonations();
        }

        this.currentMonth = this.months[monthNumber];
        this.initQuickSelections();
    }

    createNewCalendarMonth() {
        let days = [];
        for (let i = 1; i <= 31; i++) {
            let day = new CalendarDayDto();
            day.day = i;
            day.supporters = [];
            days.push(day);
        }

        let month = new CalendarMonthDto();
        month.month = this.months.length + 1;
        month.totalRaised = 0;
        month.days = days;

        this.months.push(month);
    }

    createNewDonations() {
        const donationAmounts = [100, 250, 500, 1000];

        this.donations = donationAmounts.map(amount => {
            const donation = new CalendarDonationDto();
            donation.dollarAmount = amount;
            donation.supporters = [];
            return donation;
        });
    }

    initQuickSelections() {
        this.finishQuickSelection = this.initQuickSelection(this.l('CalendarFundraiser.FinishMyCalendar'));
        this.evenQuickSelection = this.initQuickSelection(this.l('CalendarFundraiser.EvenDays'));
        this.oddQuickSelection = this.initQuickSelection(this.l('CalendarFundraiser.OddDays'));
        this.dayQuickSelections = [];

        this.dayQuickSelections.push(this.initQuickSelection(this.l('CalendarFundraiser.Sun')));
        this.dayQuickSelections.push(this.initQuickSelection(this.l('CalendarFundraiser.Mon')));
        this.dayQuickSelections.push(this.initQuickSelection(this.l('CalendarFundraiser.Tues')));
        this.dayQuickSelections.push(this.initQuickSelection(this.l('CalendarFundraiser.Wed')));
        this.dayQuickSelections.push(this.initQuickSelection(this.l('CalendarFundraiser.Thur')));
        this.dayQuickSelections.push(this.initQuickSelection(this.l('CalendarFundraiser.Fri')));
        this.dayQuickSelections.push(this.initQuickSelection(this.l('CalendarFundraiser.Sat')));
        this.allQuickSelections = [];

        for (let i = 0; i < this.currentMonth.days?.length; i++) {
            let day = this.currentMonth.days[i];
            this.finishQuickSelection.days.push(day);
            if (day.day % 2 == 0) {
                this.evenQuickSelection.days.push(day);
            }
            else {
                this.oddQuickSelection.days.push(day);
            }

            const dayOfWeekIndex = i % 7;
            this.dayQuickSelections[dayOfWeekIndex].days.push(day);
        };

        this.oddQuickSelection.totalRemaining = this.calculateRemainingQuickSelection(this.oddQuickSelection);
        this.evenQuickSelection.totalRemaining = this.calculateRemainingQuickSelection(this.evenQuickSelection);
        this.finishQuickSelection.totalRemaining = this.calculateRemainingQuickSelection(this.finishQuickSelection);

        this.allQuickSelections.push(this.finishQuickSelection, this.evenQuickSelection, this.oddQuickSelection, ...this.dayQuickSelections);
        this.updateQuickSelections();
    }

    initQuickSelection(title: string) {
        let qs = new QuickSelection();
        qs.title = title;
        qs.days = [];
        qs.selected = false;
        qs.disabled = false;
        return qs;
    }

    updateQuickSelections() {
        this.allQuickSelections.forEach(quickSelection => {
            
            if (quickSelection.days?.every(d => d.supporters?.length > 0)) {
                quickSelection.disabled = true;
            }
            else if (quickSelection.days?.every(d => d['selected'] || d.supporters?.length > 0)) {
                quickSelection.selected = true;
            }
            else {
                quickSelection.selected = false;
            }
        });
    }

    calculateRemainingQuickSelection(quickSelection) {
        let total = 0;

        quickSelection.days.forEach(day => {
            if (!day.supporters || day.supporters.length == 0) {
                total += day.day;
            }
        });

        return total;
    }

    toggleQuickSelection(quickSelection) {
        quickSelection.days.forEach(day => {
            this.toggleDay(day, quickSelection?.selected);     
        });

        this.updateQuickSelections();
    }

    toggleDay(day, quickSelection) {
        if (!this.teamMemberId) {
            this.message.error(this.l('CalendarFundraiser.StudentRequired'));
        }
        else {
            if (!day.supporters?.length) {
                if (quickSelection === true || (day['selected'] && quickSelection === null)) {
                    day['selected'] = false;
                    this.donationAmount -= day.day;
                }
                else {
                    if (!day['selected']) {
                        day['selected'] = true;
                        this.donationAmount += day.day;

                        // if calendar is full but donationAmount is less than the minimum, create new calendar and show message.
                        if (this.donationAmount < this.minimumDonation && this.currentMonth.days.every(d => d.selected || d.supporters?.length > 0)) {
                            this.createNewCalendarMonth();
                            this.initCalendar(this.months.length - 1);
                            this.message.info(this.l('CalendarFundraiser.CalendarFullMinNotMet'));
                        }
                    }
                }
                if (quickSelection === null) {
                    this.updateQuickSelections();
                }
            }
        }
    }    

    toggleDonation(donation) {
        if (!this.teamMemberId) {
            this.message.error(this.l('CalendarFundraiser.StudentRequired'));
        }
        else {
            if (donation['selected']) {
                donation['selected'] = false;
                this.donationAmount -= donation.dollarAmount;
            }
            else {
                donation['selected'] = true;
                this.donationAmount += donation.dollarAmount;
            }
        }
    }

    getTooltip(day) {
        let tooltip = '';
        if (day.supporters && day.supporters.length > 0) {
            for (let i = 0; i < day.supporters.length; i++) {
                if (i > 0) {
                    tooltip += '<br />';
                }
                tooltip += day.supporters[i].fullName;                
            }
        }
        return tooltip;
    }

    getDayText(day) {
        let dayText = '';
        if (day.supporters && day.supporters.length > 0) {
            dayText = day.supporters[0].initials;
            if (day.supporters?.length > 1) {
                dayText += '...';
            };
        }
        else {
            dayText = `$${day.day}`;
        }

        let currentMonth = this.currentMonth["month"];
        let isPrevious = currentMonth < this.months.length;

        if (isPrevious
            && day.supporters?.length == 0
            && !day["selected"]) {
            dayText = this.naDay;
        }

        return dayText;
    }

    getDaySupportersTooltip(day) {
        let dayTooltip = [];

        if (day.supporters.length > 0) {
            dayTooltip = day.supporters.map((s, index) => {
                return index === day.supporters.length - 1 ? s.fullName : s.fullName + ",";
            });
        }

        return dayTooltip;
    }

    isDisabledDay(day) {
        return !this.teamMemberId
            || this.getDayText(day) == this.naDay
            || day.supporters?.length > 0;
    }

    // *** Previous/Next Month navigation
    isMonthComplete() {
        // count the already donated days.
        let donatedAndSelectedDays = this.currentMonth.days != undefined ? this.currentMonth.days.filter(day => day.supporters.length > 0 || day.selected).length : 0;

        return this.currentMonth.days !== undefined && donatedAndSelectedDays >= this.daysInMonth;
    }

    canGoPreviousMonth() {
        return this.currentMonth.month !== undefined && this.currentMonth.month > 1;
    }

    // Can only move to the next month if ANY of the following:
    // 1) Once current month is full (all 1-31 days selected)
    // 2) the dollar amount ($10) is met
    // 3) The total month's amount is already met or passed
    canGoNextMonth() {
        let daysLeftInCurrentMonthSum = 0;
        let currentMonthTotalRaised = 0;

        if (this.currentMonth.days) {
            daysLeftInCurrentMonthSum = this.currentMonth.days.filter(day => !day.selected).reduce((sum, day) => sum + day.day, 0);
            currentMonthTotalRaised = this. currentMonth.totalRaised;
        }

        return this.isMonthComplete() || daysLeftInCurrentMonthSum < this.minimumDonation || currentMonthTotalRaised >= this.totalMonthAmount;
    }

    goToMonth(month: number) {
        if (month <= 0 || month > this.months.length) {
            this.createNewCalendarMonth();
        }

        let selectMonth = month - 1;
        let donatedOrSelectedDays = this.months[selectMonth].days.filter(day => day.supporters.length > 0 || day['selected']).map(d => d.day);

        this.fillCalendarMonthDays(this.months[selectMonth], donatedOrSelectedDays);
        this.currentMonth = this.months[selectMonth];
        this.initCalendar(selectMonth);
    }

    fillCalendarMonthDays(calendarMonthDto: CalendarMonthDto, donatedDays: number[] = []) {
        let days = [];

        for (let i = 1; i <= 31; i++) {
            let day = new CalendarDayDto();
            day.day = i;
            day.supporters = [];

            if (donatedDays.includes(i)) {
                day.supporters = calendarMonthDto.days.find(d => d.day === i)?.supporters || [];
                day['selected'] =  !day.supporters || day.supporters.length === 0;
            }
           
            days.push(day);
        }

        calendarMonthDto.days = days;
    }

    _effectiveStudentGoal: number;
    get effectiveStudentGoal(): number {
        return this._effectiveStudentGoal ?? (this._effectiveStudentGoal =  this.getEffectiveGoal(this.studentRaised, this.studentGoal, AppConsts.studentStretchIncrementDollars));
    }

    _effectiveTeamGoal: number;
    get effectiveTeamGoal(): number {
        return this._effectiveTeamGoal ?? (this._effectiveTeamGoal =  this.getEffectiveGoal(this.teamRaised, this.teamGoal, AppConsts.teamStretchIncrementDollars));
    }

    getEffectiveGoal(raised: number, goal: number, stretchIncrement: number): number {
        if (!this.fundraiserUserInfo)
            return null;

        // `goal` is the `goal`
        //  UNLESS ...
        //      `goal` has been exceeded 
        //          OR
        //      `viewSample` is true
        //  THEN ...
        //      `goal` is the next `stretchIncrement` past the goal
        if (this.viewSample || raised < goal)
            return goal;

        return (Math.trunc((raised - goal) / stretchIncrement) + 1) 
            * stretchIncrement 
            + goal;
    }

    fundraiserUserInfo: GetFundraiserUserInfo;
    get teamGoal() {
        if (!this.fundraiserUserInfo) return 0;
        return +this.fundraiserUserInfo.teamGoal.toFixed();
    }
    get teamGoalPercentEffective() {
        if (!this.fundraiserUserInfo) return '0';
        return this.teamRaised < this.effectiveTeamGoal ? (this.teamRaised / this.effectiveTeamGoal * 100).toString() + '%' : '100%';
    }
    get teamGoalPercentActual() {
        if (!this.fundraiserUserInfo) return '0';
        return this.teamRaised < this.teamGoal ? (this.teamRaised / this.teamGoal * 100).toString() + '%' : '100%';
    }
    get studentGoalPercentEffective() {
        if (!this.fundraiserUserInfo) return '0';
        return this.studentRaised < this.effectiveStudentGoal ? (this.studentRaised / this.effectiveStudentGoal * 100).toString() + '%' : '100%';
    }
    get studentGoalPercentActual() {
        if (!this.fundraiserUserInfo) return '0';
        return this.studentRaised < this.studentGoal ? (this.studentRaised / this.studentGoal * 100).toString() + '%' : '100%';
    }
    get studentGoalRemaining() {
        if (!this.fundraiserUserInfo) return 0;
        return this.effectiveStudentGoal > this.studentRaised ? this.effectiveStudentGoal - this.studentRaised : 0;
    }
    get teamRaised() {
        if (!this.fundraiserUserInfo) return 0;
        return +this.fundraiserUserInfo.teamRaised.toFixed();
    }
    get studentRaised() {
        if (!this.fundraiserUserInfo) return 0;
        return +this.fundraiserUserInfo.studentRaised.toFixed();
    }
    get studentGoal() {
        if (!this.fundraiserUserInfo) return 0;
        return +this.fundraiserUserInfo.studentGoal.toFixed();
    }

    customerEmailAddress: string;

    slugParam: string;
    orderDetailId: number;
    constructor(
        injector: Injector,
        private _activatedRoute: ActivatedRoute,
        private _fundraisersServiceProxy: FundraisersServiceProxy,
        public _zone: NgZone,
        private _router: Router,
        private _renderer2: Renderer2,
        private cdr: ChangeDetectorRef,
        @Inject(DOCUMENT) private _document: Document
    ) {
        super(injector);

        this._activatedRoute.params.subscribe(params => {
            this.orderDetailId = params.orderId;

            this.fundraiserUserId = params.fundraiserUserId;
            this.slugParam = params.slug;

            this._activatedRoute.queryParams.subscribe(qparams => {
                this.fundraiserStudentContactSlug = qparams['fscSlug'];
                this.automatedMessageType = qparams['automatedMessageType'];

                // if this is not for a write-in seller (-1) then set these values
                if (this.fundraiserUserId !== undefined && this.fundraiserUserId == this.writeInSellerTeamMemberId) {
                    this.fundraiserUserId = undefined;
                    this.teamMemberId = this.writeInSellerTeamMemberId;
                    this.isWriteInSeller = true;
                }

                this.getFundraiserUserInfo();
            });
        });
    }

    viewSample: boolean;
    ngOnInit(): void {
        this.viewSample = window.location.pathname.includes('viewSample');
    }

    getFundraiserUserInfo() {
        this._fundraisersServiceProxy.getFundraiserUserInfo(this.fundraiserUserId, this.slugParam, this.orderDetailId).subscribe(result => {
            this.fundraiserUserInfo = result;
            this.fundraiserStudentName = this.fundraiserUserInfo?.studentName;
            this.tenantId = this.fundraiserUserInfo.tenantId;
                        this.isApplePayEnabledForFundraiser = this.fundraiserUserInfo.enableApplePay;
            this.isISponsorEnabledForFundraiser = this.fundraiserUserInfo.enableISponsor;

            if (this.fundraiserUserId && this.appSession.user?.id == this.fundraiserUserInfo.studentId) {
                this.shareDescription = this.getFilledInShareMessage(this.fundraiserUserInfo.smsStudentShareMessage ?
                    this.fundraiserUserInfo.smsStudentShareMessage : this.l('DefaultStudentShareMessage'));
            }
            else {
                this.shareDescription = this.getFilledInShareMessage(this.fundraiserUserInfo.smsShareMessage ?
                    this.fundraiserUserInfo.smsShareMessage : this.l('OrderHelpFundraiser'));
            }

            this.shareTitle = `${this.fundraiserUserInfo.organizationName} - ${this.fundraiserUserInfo.fundraiserName}`;

            this.teamMemberId = this.isWriteInSeller ? this.writeInSellerTeamMemberId : this.fundraiserUserId;

            if (this.teamMemberId && this.teamMemberId > 0) {
                this._fundraisersServiceProxy.getCalendarFundraiserInfo(this.teamMemberId, 0).subscribe(response => {
                    this.months = response.months;
                    this.donations = response.donations;
                    if (!this.donations || this.donations.length == 0) {
                        this.createNewDonations();
                    }
                    if (this.months.length == 0) {
                        this.initCalendar(0, true);
                    }
                    else {
                        const donatedDays = this.months[response.months.length - 1].days.map(d => d.day);
                        this.fillCalendarMonthDays(this.months[response.months.length - 1], donatedDays);
                        this.initCalendar(response.months.length - 1);
                    }
                });
            }
            else {
                this.initCalendar(0, true);
            }

            this.helpReachGoalInfo = new HelpReachGoalInfo(this.fundraiserUserInfo.isFundraiserEndUtc, this.fundraiserUserInfo.fundraiserEnd);

            this.setIsFundraiserOver();
            if (this.isFundraiserOver) {
                this.insertTrackingTagScript();
            }

            if (this.fundraiserUserId) {
                this.setCarouselGoalComponents();
            }
            this.shareUrl = this.getShareLink();
            this.shareUrlSms = this.getSmsVersionOfShareUrl(this.shareUrl);

            if (this.orderDetailId) {
                this.submit();
            }
        });
    }

    insertTrackingTagScript() {
        let script = this._renderer2.createElement('script');
        script.text = `
            {
                gtag('event', 'ended_pageview', {
                    'fundraiser_ID': '` + this.fundraiserUserInfo.fundraiserId + `',
                  });
            }
        `;
        this._renderer2.appendChild(this._document.body, script);
    }

    getSecondsLeftInFundraiser(now: DateTime = null) {
        let nowUtcSeconds: number;
        let offset: number;

        if (this.fundraiserUserInfo?.fundraiserEnd == null) {
            return null;
        }
        if (now == null) {
            now = DateTime.now();
        }
        nowUtcSeconds = now.toSeconds();
        // if !isFundraiserEndUtc, assume client is in same timezone as fundraiser.
        offset = this.fundraiserUserInfo?.isFundraiserEndUtc ? 0 : now.offset * 60;
        // end date is stored in the DB with midnight (the start) of the day for time,
        // so add a day's worth of seconds (86400) to the result.
        let result = this.fundraiserUserInfo.fundraiserEnd - nowUtcSeconds - offset + 86400;
        return result;
    }

    setIsFundraiserOver(): void {
        let now = DateTime.now();
        this.isFundraiserOver = this.getSecondsLeftInFundraiser(now) < 0;
    }

    isFundraiserOver: boolean;

    getFilledInShareMessage(rawShareMessage: string): string {
        if (!this.fundraiserUserInfo) return '';
        let result = rawShareMessage.replace('{STUDENTNAME}', this.fundraiserUserInfo.studentName).replace('{ORGANIZATIONNAME}', this.fundraiserUserInfo.organizationName)
            .replace('{STUDENTGOAL}', this.effectiveStudentGoal.toString());
        return result;
    }

    reload(orderDetailPlusProductViewModels: OrderDetailPlusProductAndPackageViewModels): void {
        this.donationAmount = orderDetailPlusProductViewModels.orderDetail.donationAmount;
        this.isCheckout = false;
    }

    donationClick(donationAmount: number) {
        this.donationAmount = donationAmount;
        this.completeGoalDonationAmount = undefined;
    }

    completeGoalDonationAmount: number;
    completeStudentGoal() {
        this.completeGoalDonationAmount = this.effectiveStudentGoal - this.studentRaised;
        this.donationAmount = undefined;
    }

    isCheckout: boolean;
    submit() {
        if (this.donationAmount < this.minimumDonation) {
            this.message.error(this.l('CalendarFundraiser.MinimumNotMet'));
            return;
        }
        this.isCheckout = true;
    }

    isOrderComplete: boolean = false;
    orderTransactionId: string;
    orderComplete(orderInfo: CompleteOrderInfo) {
        this.isCheckout = false;
        this.isOrderComplete = true;
        this.orderTransactionId = orderInfo.orderTransactionId;
        this.customerEmailAddress = orderInfo.customerEmailAddress;
        this.fundraiserUserInfo.teamRaised += orderInfo.orderAmount;
        if (this.studentRaised && !Number.isNaN(this.studentRaised)) {
            this.fundraiserUserInfo.studentRaised += orderInfo.orderAmount;
        }
        if (this.isISponsorEnabledForFundraiser && this.orderConfirmationComponent) {
            this.orderConfirmationComponent.isISponsorEnabled = this.isISponsorEnabledForFundraiser;
            this.orderConfirmationComponent.iSponsorPromotionComponent.customerInformation = orderInfo.isponsorInformation;
            orderInfo.isponsorInformation = null;
        } else {
            orderInfo.isponsorInformation = null;
        }

        // Call to update the calendar

        let calendarInput = new CalendarFundraiserInputDto();
        calendarInput.fundraiserId = this.fundraiserUserInfo.fundraiserId;

        // must send null for write-in sellers, as -1 will break the backend as that is not a valid/found fundraiserUserId.
        calendarInput.fundraiserUserId = this.teamMemberId == this.writeInSellerTeamMemberId ? null : this.teamMemberId;
        
        calendarInput.months = [];
        calendarInput.donations = [];
        calendarInput.orderTransactionId = orderInfo.orderTransactionId;

        this.months.forEach(m => {
            let monthOrder = new CalendarMonthInputDto();
            monthOrder.month = m.month;
            monthOrder.days = [];

            m.days.forEach(d => {
                if (d['selected']) {
                    if (!monthOrder.days) {
                        monthOrder.days = [];
                    }
                    monthOrder.days.push(d.day);
                }
            });

            if (monthOrder.days.length > 0) {
                calendarInput.months.push(monthOrder);
            }
        });

        this.donations.forEach(d => {
            if (d['selected']) {
                calendarInput.donations.push(d.dollarAmount);
            }
        });

        this._fundraisersServiceProxy.insertCalendarFundraiserDonation(calendarInput)
            .subscribe(result => {
                this.getFundraiserUserInfo();
            });

        this.cdr.detectChanges();
    }

    cancelOrder() {
        this.isCheckout = false;
        this.resetDonationSelections();
    }

    returnFromConfirmation() {
        this.isCheckout = false;
        this.isOrderComplete = false;
        this.resetDonationSelections();
        this.getFundraiserUserInfo();
    }

    resetDonationSelections() {
        this.donationAmount = undefined;
        this.completeGoalDonationAmount = undefined;
    }

    getShareLink(): string {
        let fundraiserUserId = this.fundraiserUserId ? this.fundraiserUserId : '0';
        let slug = this.fundraiserUserInfo?.slug ? this.fundraiserUserInfo.slug : '';

        let link = `${this.appRootUrl()}FundraiserHomeInfo/${fundraiserUserId}/${slug}`;

        if (window.location.href.includes('localhost')) {
            link = link.replace('FundraiserHomeInfo', 'fundraiserHomeInfo');
        }
        return link;
    }

    getStudentName(): string {
        let studentName = this.fundraiserUserInfo?.studentName;
        if (this.teamMemberId == this.writeInSellerTeamMemberId) {
            studentName = '';
        }

        return studentName;
    }

    async navigateToProductFundraiserHomePage() {
        if (this.fundraiserUserId) {
            this._router.navigate(['productFundraiserHome', 'student', this.fundraiserUserId]);
        }
        else {
            this._router.navigate(['productFundraiserHome', 'fundraiser', this.fundraiserUserInfo.slug]);
        }
    }

    pageYoffset = 0;
    @HostListener('window:scroll', ['$event']) onScroll(event) {
        this.pageYoffset = window.pageYOffset;
    }

    scrollToDonationOptions() {
        if (this.donationOptionsRow) {
            const htmlElement = this.donationOptionsRow.nativeElement as HTMLElement;
            htmlElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }
    }

    goalValues: GoalNumbers[] = [];
    setCarouselGoalComponents(): void {
        let goalValuesFull: GoalNumbers[] = [];
        goalValuesFull.push({
            rightTitle: this.l('MyGoalLabel'),
            rightAmount: this.isFundraiserOver ? this.studentGoal : this.effectiveStudentGoal,
            leftTitle: this.l('TotalRaisedLabel'),
            leftAmount: this.studentRaised,
            progressBarValue: this.isFundraiserOver ? this.studentGoalPercentActual : this.studentGoalPercentEffective,
            name: this.fundraiserUserInfo.studentName
        })

        if (!this.isFundraiserOver) {
            goalValuesFull.push({
                rightTitle: this.l('TeamGoalLabel'),
                rightAmount: this.effectiveTeamGoal,
                leftTitle: this.l('TotalRaisedLabel'),
                leftAmount: this.teamRaised,
                progressBarValue: this.teamGoalPercentEffective,
                name: this.l('Team')
            });
        } else {
            goalValuesFull.push({
                rightTitle: this.l('TotalRaisedLabel'),
                rightAmount: this.teamRaised,
                leftTitle: this.l('TeamGoalLabel'),
                leftAmount: this.teamGoal,
                progressBarValue: this.teamGoalPercentActual,
                name: this.l('Team')
            })
        }
        this.goalValues = goalValuesFull;
    }
}