<!-- NOTE: Check if changes made here affect src/app/main/launch/fundraisers/student-contacts/create-or-edit-student-contact-modal/create-or-edit-student-contact-modal.component.html
If so, make sure to make the changes in both places
Code duplication is sometimes a necessary evil for security :( -->

<div bsModal #parentCreateEditStudentContactModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="parentCreateEditStudentContactModal" aria-hidden="true" [config]="{backdrop: 'static'}" 
    (onHide)="resetForm()" (onHidden)="resetForm()">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <form #studentContactForm="ngForm" (ngSubmit)="save()" autocomplete="off">
                <div class="modal-header">
                    <h4 class="modal-title">{{ studentContact?.id ? l("EditContact") : l("CreateNewContact") }}</h4>
                    <button type="button" class="btn-close" (click)="close()" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-5">
                        <div class="col-12 col-lg-6">
                            <label for="firstName">{{l("FirstName")}}</label>
                            <input type="text" id="firstName" name="firstName" class="form-control" 
                                    [(ngModel)]="studentContact.firstName"
                                    #firstNameInput="ngModel" maxlength="75" required />
                            <validation-messages [formCtrl]="firstNameInput"></validation-messages>
                        </div>
                        <div class="col-12 col-lg-6">
                            <label for="lastName">{{l("LastName")}}</label>
                            <input type="text" id="lastName" name="lastName" class="form-control" 
                                    [(ngModel)]="studentContact.lastName" 
                                    #lastNameInput="ngModel" maxlength="75" required />
                            <validation-messages [formCtrl]="lastNameInput"></validation-messages>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-12 col-lg-6">
                            <label for="phoneNumber">{{l("PhoneNumber")}}</label>
                            <p-inputMask class="form-control" id="phoneNumber" name="phoneNumber" ngDefaultControl
                                    (onInput)="validatePhoneNumber()" (onBlur)="validatePhoneNumber()"
                                    [(ngModel)]="studentContact.phoneNumber" mask="(999) 999-9999" placeholder="(___) ___-____"
                                    #phoneNumberInput="ngModel" minlength="10" required [autoClear]="false"></p-inputMask>
                            <span *ngIf="phoneNumberInput.dirty && phoneErrorMessage" class="form-control-feedback">{{phoneErrorMessage}}</span>
                        </div>
                        <div class="col-12 col-lg-6">
                            <label for="emailAddress">{{l("Email")}}</label>
                            <input type="text" id="emailAddress" name="emailAddress" class="form-control" 
                                    [(ngModel)]="studentContact.emailAddress" [pattern]="emailAddressPattern"
                                    #emailInput="ngModel" maxlength="256" />
                            <validation-messages [formCtrl]="emailInput"></validation-messages>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-12 col-lg-6">
                            <label for="relationship">{{l("Relationship")}}</label>
                            <select id="relationship" name="relationship" class="form-control" 
                                    [(ngModel)]="studentContact.relationshipId" 
                                    #relationshipSelect="ngModel" required>
                                <option *ngFor="let rel of relationships" [value]="rel.id">{{rel.displayName}}</option>
                            </select>
                            <validation-messages [formCtrl]="relationshipSelect"></validation-messages>
                        </div>
                        <div class="col-12 col-lg-6" *ngIf="studentContact.relationshipId == otherRelationshipId">
                            <label for="relOther">{{l("RelationshipOther")}}</label>
                            <input type="text" id="relOther" name="relOther" class="form-control" 
                                    [(ngModel)]="studentContact.relationshipOther"
                                    #relOtherInput="ngModel" maxlength="50" />
                            <validation-messages [formCtrl]="relOtherInput"></validation-messages>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col d-flex justify-content-start">
                        <button type="button" *ngIf="studentContact.id" id="deleteContact" class="btn btn-outline-primary" (click)="deleteContact()" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')">
                            <i class="fa fa-trash-alt"></i>
                            <span>{{l("Delete")}}</span>
                        </button>
                    </div>
                    <div class="col-auto d-flex justify-content-end">
                        <button [disabled]="saving" type="button" class="btn btn-default" (click)="close()">
                            {{l("Cancel")}}
                        </button>
                        <button type="submit" class="btn btn-primary" [disabled]="!studentContactForm.form.valid || phoneErrorMessage != '' || saving">
                            <i [ngClass]="saving ? 'spinner-border spinner-border-sm' : 'fa fa-save'" class="me-1"></i>
                            <span>{{saving ? l('SavingWithThreeDot') : l("Save")}}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>