<div class="row p-5" *ngIf="shippingAddress">
    <div class="d-flex flex-row mb-5">
        <div class="col-6">
            <h2 *ngIf="isGuest">{{isLaunchathon || isDonationOnly ? l('BillTo') : l('ShipTo')}}</h2>
            <h2 *ngIf="!isGuest">{{l('CustomerDetails')}}</h2>
        </div>
        <div class="col-6 d-flex flex-column">
            <h5 class="fw-bolder">{{shippingAddress.name}}</h5>
            <p class="m-0 ms-2">
                {{shippingAddress.streetLine1}}
                <span class *ngIf="shippingAddress.streetLine2">&nbsp;{{shippingAddress.streetLine2}}</span>
            </p> 
            <p class="m-0 ms-2">{{shippingAddress.city}}{{state ? ', ' + state : ''}} {{shippingAddress.zipCode}}</p>
        </div>
    </div>
    <div class="d-flex flex-row mb-5">
        <div class="col-6">
            <h2>{{l('Payment')}}</h2>
        </div>
        <div class="col-6 d-flex flex-column">
            <h5 class="fw-bolder">{{paymentMethodName}}</h5>
            <ng-container *ngIf="isGuest; else studentDetails">
                <p class="m-0 ms-2">{{maskCCNumber(orderDetail.creditCardInfo?.creditCardNumber)}}</p>
            </ng-container>
            <ng-template #studentDetails>
                <p *ngIf="isPaymentMethod(check)" class="m-0 ms-2">{{orderDetail.checkNumber}}</p>
                <p class="m-0 ms-2">{{isPaidOrNotPaid}}</p>
                <p *ngIf="!isLaunchathon" class="m-0 ms-2">{{IsDeliveredOrNotDelivered}}</p>
            </ng-template>
        </div>
    </div>
    <div class="d-flex align-items-center justify-content-center mt-5" *ngIf="isUpdatingTotals">
        <p class="text-center mw-600">{{l('UpdateTotals')}}</p>
    </div>
    <div class="d-flex w-100 justify-content-center" [ngClass]="isUpdatingTotals ? '' : 'mt-5'">
        <button *ngIf="!isPaymentMethod(applePay) || !isANewOrder()" type="button" class="customer-submit-button customer-button" [ngClass]="isUpdatingTotals ? '' : 'enabled-background'" [disabled]="isUpdatingTotals ? true : null" (click)="completeCheckoutOrder()">
            {{ isANewOrder() ? isLaunchathon ? l('Donate') : l('PayNow') : l('UpdateOrder')}}
        </button>

        <checkout-payment-methods-applepay *ngIf="!isUpdatingTotals && isPaymentMethod(applePay) && isApplePayEnabledForFundraiser" [totalAmount]="totalAmount" 
            [organizationName]="organizationName" [canUseApplePay]="canUseApplePay"
            (applePayEvent)="submitApplePayEvent($event)"></checkout-payment-methods-applepay>
         
    </div>
    <div class="d-flex align-items-center justify-content-center mt-3" *ngIf="isLaunchathon">
        <p class="text-center mw-600">
            {{l("DonationBillingAuthorizationText").replace('There may be a handling fee. ', '')}}
        </p>
    </div>
</div>