import { Injectable, Injector, NgZone } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { HubConnection } from '@microsoft/signalr';

@Injectable()
export class LiveSignalrService extends AppComponentBase {
    constructor(injector: Injector, public _zone: NgZone) {
        super(injector);
    }

    liveHub: HubConnection;
    isLiveConnected = false;

    configureConnection(connection): void {
        // Set the common hub
        this.liveHub = connection;

        // Reconnect loop
        let reconnectTime = 5000;
        let tries = 1;
        let maxTries = 8;
        function start() {
            return new Promise(function (resolve, reject) {
                if (tries > maxTries) {
                    reject();
                } else {
                    connection
                        .start()
                        .then(resolve)
                        .then(() => {
                            reconnectTime = 5000;
                            tries = 1;
                        })
                        .catch(() => {
                            setTimeout(() => {
                                start().then(resolve);
                            }, reconnectTime);
                            reconnectTime *= 2;
                            tries += 1;
                        });
                }
            });
        }

        // Reconnect if hub disconnects
        connection.onclose((e) => {
            this.isLiveConnected = false;

            if (e) {
                abp.log.debug('Live connection closed with error: ' + e);
            } else {
                abp.log.debug('Live disconnected');
            }

            start().then(() => {
                this.isLiveConnected = true;
            });
        });

        // Register to get notifications
        this.registerLiveEvents(connection);
    }

    registerLiveEvents(connection): void {

        connection.on('getLiveFundraiserInfo', (info) => {
            abp.event.trigger('app.live.fundriaserInfoReceived', info);
        });

    }

    sendCountdownEvent(countdownEvent, callback): void {

        if (!this.isLiveConnected) {
            if (callback) {
                callback();
            }

            abp.notify.warn(this.l('LiveIsNotConnectedWarning'));
            return;
        }

        this.liveHub
            .invoke('sendCountdownEvent', countdownEvent)
            .then((result) => {
                if (result) {
                    abp.notify.warn(result);
                }

                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                abp.log.error(error);

                if (callback) {
                    callback();
                }
            });
    }

    init(): void {

        this._zone.runOutsideAngular(() => {
            abp.signalr.connect();
            abp.signalr
                .startConnection(abp.appPath + 'signalr-live', (connection) => {
                    this.configureConnection(connection);
                })
                .then(() => {
                    abp.event.trigger('app.live.connected');
                    this.isLiveConnected = true;
                });
        });
    }
}