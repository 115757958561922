import { Component, Input } from "@angular/core";
import { AppComponentBase } from "../../../../shared/common/app-component-base";
import { HourathonOrder } from "../../../../shared/service-proxies/service-proxies";

@Component({
    selector: 'supporters',
    templateUrl: './supporters.component.html',
    styleUrls: ['./supporters.component.less']
})

export class SupportersComponent extends AppComponentBase {
    @Input() orders: HourathonOrder[];
}