import { Component, ViewChild } from "@angular/core";
import { appModuleAnimation } from "../../../../shared/animations/routerTransition";
import { AppComponentBase } from "../../../../shared/common/app-component-base";
import { ModalDirective } from 'ngx-bootstrap/modal';


@Component({
    selector: 'item-image-viewer',
    templateUrl: './item-image-viewer.component.html',
    styleUrls: ['./item-image-viewer.component.less'],
    animations: [appModuleAnimation()]
})
export class ItemImageViewerComponent extends AppComponentBase {
    @ViewChild('itemImageViewerModal', { static: true }) modal: ModalDirective;

    imageUrl: string;
    show(imageUrl: string): void {
        this.imageUrl = imageUrl;
        this.modal.show();
    }

    close(): void {
        this.modal.hide();
    }

    imageClick(event: Event): void {
        event.stopPropagation();
    }
}