import { Component, OnInit } from "@angular/core";
import { AppComponentBase } from "../../../../../shared/common/app-component-base";
import { ActivatedRoute } from "@angular/router";

@Component({
    styleUrls: ['./sideline-report.component.less'],
    templateUrl: './sideline-report.component.html'
})
export class SidelineReportComponent extends AppComponentBase implements OnInit {
    ngOnInit(): void { }
    init(): void { }
}