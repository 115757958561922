<div class="activity-container">

    <h2 class="header-text header-padding" style="text-align: center;">ACTIVITY</h2>

    <div [@fadeIn]="this.newestHourathonOrder" *ngIf="this.newestHourathonOrder" class="row mx-0 w-100">
        <div class="col-2 col-md-1 d-flex flex-column align-items-center justify-content-center pb-3">
            <div class="ranking-box" [ngStyle]="{'background-color': newestHourathonOrder.colorCode}">
                {{newestHourathonOrder.customerInitials}}
            </div>
        </div>
        <div class="col-6 col-md-8 d-flex flex-column align-items-start justify-content-start pb-3 my-auto">
            <div>
                <div class="order-customer-name">{{newestHourathonOrder.customerName}}</div>
                <div>({{newestHourathonOrder.studentName}})</div>
            </div>
            <div style="align-self: start">{{newestHourathonOrder.messageText ? '"' + newestHourathonOrder.messageText + '"': ''}}</div>
        </div>
        <div class="col-4 col-md-3 d-flex flex-column align-items-center justify-content-center pb-3 px-3">
            <div style="align-self: start">{{newestHourathonOrder.orderAmount  | currency : 'USD'}}</div>
        </div>
    </div>
    <div [@shiftDown]="isShiftDown ? true : false">
        <div *ngFor="let order of orders" class="row mx-0 w-100">
            <div class="col-2 col-md-1 d-flex flex-column align-items-center justify-content-center pb-3">
                <div class="ranking-box" [ngStyle]="{'background-color': order.colorCode}">
                    {{order.customerInitials}}
                </div>
            </div>
            <div class="col-6 col-md-8 d-flex flex-column align-items-start justify-content-start my-auto">
                <div>
                    <div class="order-customer-name">{{order.customerName}}</div>
                    <div>({{order.studentName}})</div>
                </div>
                <div style="align-self: start">{{order.messageText ? '"' + order.messageText + '"' : ''}}</div>
            </div>
            <div class="col-4 col-md-3 d-flex flex-column align-items-center justify-content-center pb-3 px-3">
                <div style="align-self: start">{{order.orderAmount  | currency : 'USD'}}</div>
            </div>
        </div>
    </div>
</div>