import { Component, Input } from "@angular/core";
import { DateTime } from "luxon";
import { CountdownConfig, CountdownEvent } from "ngx-countdown";
import { appModuleAnimation } from "../../../../shared/animations/routerTransition";
import { AppComponentBase } from "../../../../shared/common/app-component-base";

@Component({
    selector: 'help-reach-goal',
    templateUrl: './help-reach-goal.component.html',
    styleUrls: ['./help-reach-goal.component.less'],
    animations: [appModuleAnimation()]
})
export class HelpReachGoalComponent extends AppComponentBase {
    @Input() isFundraiserOver: boolean;
    @Input() fundraiserUserId: number;
    @Input() dayVsDays: boolean;
    @Input() studentName: string;
    @Input() studentRaised: number;
    @Input() teamRaised: number;
    @Input() organizationName: string;
    @Input() appListing: string[];
    @Input() appListingSms: string[];
    @Input() shareTitle: string;
    @Input() shareDescription: string;
    @Input() shareUrl: string;
    @Input() shareUrlSms: string;
    @Input() isMetricDollars: boolean;

    isFundraiserEndUtc: boolean;
    fundraiserEnd: number;
    nowUtcSeconds: number;
    @Input() set helpReachGoalInfo(value: HelpReachGoalInfo) {
        if (!value) {
            return;
        }
        this.isFundraiserEndUtc = value.isFundraiserEndUtc;
        this.fundraiserEnd = value.fundraiserEnd;
        this.secondsLeftInFundraiser = this.getSecondsLeftInFundraiser(this.now, this.isFundraiserEndUtc, this.fundraiserEnd);
        this.configureForHourathonDone(this.now, this.isFundraiserEndUtc, this.fundraiserEnd, this.nowUtcSeconds, this.secondsLeftInFundraiser);
    }

    configHours: CountdownConfig;
    configMinutes: CountdownConfig;
    daysLeft: string;

    now: DateTime;
    secondsLeftInFundraiser: number;
    ngOnInit(): void {
        this.now = DateTime.now();
        this.nowUtcSeconds = this.now.toSeconds();
    }

    receivedInitialDone: boolean = false;
    handleEvent(event: CountdownEvent) {
        if (event.action == 'done') {
            if (!this.receivedInitialDone) {
                // we get a 'done' on page load, for some reason...
                this.receivedInitialDone = true;
                return;
            }
            this.configureForHourathonDone(this.now, this.isFundraiserEndUtc, this.fundraiserEnd, this.nowUtcSeconds, this.secondsLeftInFundraiser);
        }
    }

    configureForHourathonDone(now: DateTime = null, isFundraiserEndUtc: boolean, fundraiserEnd: number, nowUtcSeconds: number, secondsLeftInFundraiser: number = null) {
        if (secondsLeftInFundraiser == null) {
            return;
        }
        if (secondsLeftInFundraiser > 0) {
            this.daysLeft = (secondsLeftInFundraiser / 86400).toString().split('.')[0];
            this.setupTimer(secondsLeftInFundraiser, true);
            this.setDaysLeftTimeout(now, isFundraiserEndUtc, fundraiserEnd, nowUtcSeconds, false);
        }
        else {
            this.showFundraiserEnded();
        }
    }

    setupTimer(leftTime: number, startTimer: boolean) {
        //this.ct.begin() isn't working, so we'll set it running or not with 'demand' in the config.
        this.configHours = {
            format: `HH`,
            leftTime: leftTime,
            demand: !startTimer
        };
        this.configMinutes = {
            format: `mm`,
            leftTime: leftTime,
            demand: !startTimer
        };
    }

    setDaysLeftTimeout(now: DateTime, isFundraiserEndUtc: boolean, fundraiserEnd: number, nowUtcSeconds: number, isSelfTriggered: boolean) {
        // if the hourathon has just ended or we're re-loading the page,
        // start a Timeout to run in parallel with the countdown,
        // for decrementing of daysLeft, since countdown component doesn't trigger an event when a day rolls over.
        if (isSelfTriggered) {
            // countdown timer must have rolled-over, so decrement day.
            this.daysLeft = (+ this.daysLeft - 1).toString();
        }
        if (+this.daysLeft >= 0) {
            let millisecondsLeftInFundraiserDay = (this.getSecondsLeftInFundraiser(now, isFundraiserEndUtc, fundraiserEnd) % 86400) * 1000;
            setTimeout(() => this.setDaysLeftTimeout(DateTime.now(), isFundraiserEndUtc, fundraiserEnd, nowUtcSeconds, true), millisecondsLeftInFundraiserDay);
        }
        else {
            this.showFundraiserEnded();
        }
    }

    showFundraiserEnded() {
        this.isFundraiserOver = true;
    }

    offset: number;
    getSecondsLeftInFundraiser(now: DateTime = null, isFundraiserEndUtc: boolean, fundraiserEnd: number) {
        if (this.fundraiserEnd == null) {
            return null;
        }
        if (now == null) {
            now = DateTime.now();
        }
        // if !isFundraiserEndUtc, assume client is in same timezone as fundraiser.
        this.offset = isFundraiserEndUtc ? 0 : now.offset * 60;
        // end date is stored in the DB with midnight (the start) of the day for time,
        // so add a day's worth of seconds (86400) to the result.
        let result = fundraiserEnd - this.nowUtcSeconds - this.offset + 86400;
        return result;
    }

    get formattedDaysLeft() {
        return this.daysLeft?.length == 1 ? '0' + this.daysLeft : this.daysLeft;
    }
}

export class HelpReachGoalInfo {
    isFundraiserEndUtc: boolean;
    fundraiserEnd: number;

    constructor(
        isFundraiserEndUtc: boolean,
        fundraiserEnd: number
    ) {
        this.isFundraiserEndUtc = isFundraiserEndUtc;
        this.fundraiserEnd = fundraiserEnd
    }
}