<div [@routerTransition] class="d-flex flex-column flex-root">
    <div class="page d-flex flex-row flex-column-fluid">
        <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
            <div
                id="kt_header"
                class="header"
                data-kt-sticky="true"
                data-kt-sticky-name="header"
                data-kt-sticky-offset="{default: '200px', lg: '300px'}"
            >
                <div [class]="containerClass + ' d-flex flex-grow-1 flex-stack'">
                    <div class="d-flex align-items-center me-5">
                        <div
                            class="d-lg-none btn btn-icon btn-active-color-primary w-30px h-30px ms-n2 me-3"
                            id="kt_header_menu_toggle"
                        >
                            <span class="svg-icon svg-icon-1">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                        fill="black"
                                    ></path>
                                    <path
                                        opacity="0.3"
                                        d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                        fill="black"
                                    ></path>
                                </svg>
                            </span>
                        </div>
                        <theme11-brand></theme11-brand>
                    </div>
                    <div class="d-flex align-items-center">
                        <div class="d-flex align-items-center flex-shrink-0">
                            <active-delegated-users-combo [customStyle]="'me-2'"></active-delegated-users-combo>
                            <subscription-notification-bar
                                [customStyle]="
                                    'btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline btn-outline-secondary btn-active-bg-light w-30px h-30px w-lg-40px h-lg-40px position-relative me-2'
                                "
                            ></subscription-notification-bar>
                            <quick-theme-selection
                                [customStyle]="
                                    'btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline btn-outline-secondary btn-active-bg-light w-30px h-30px w-lg-40px h-lg-40px me-2'
                                "
                            ></quick-theme-selection>
                            <language-switch-dropdown
                                [customStyle]="
                                    'btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline btn-outline-secondary btn-active-bg-light w-30px h-30px w-lg-40px h-lg-40px me-2'
                                "
                            ></language-switch-dropdown>
                            <header-notifications
                                [customStyle]="
                                    'btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline btn-outline-secondary btn-active-bg-light w-30px h-30px w-lg-40px h-lg-40px position-relative me-2'
                                "
                            ></header-notifications>
                            <chat-toggle-button
                                [customStyle]="
                                    'btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline btn-outline-secondary btn-active-bg-light w-30px h-30px w-lg-40px h-lg-40px position-relative me-2'
                                "
                            ></chat-toggle-button>
                            <user-menu
                                [togglerCssClass]="
                                    'btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline btn-outline-secondary btn-active-bg-light w-auto px-5'
                                "
                                [textCssClass]="'font-weight-bolder font-size-base d-none d-md-inline me-4'"
                                [symbolCssClass]="'symbol symbol-30px'"
                                [symbolTextCssClass]="'symbol-label fs-5 bg-primary font-weight-bold text-white'"
                            ></user-menu>
                        </div>
                    </div>
                </div>
                <div class="separator"></div>
                <div class="header-menu-container container-xxl d-flex flex-stack h-lg-75px" id="kt_header_nav">
                    <div
                        class="header-menu flex-column flex-lg-row"
                        data-kt-drawer="true"
                        data-kt-drawer-name="header-menu"
                        data-kt-drawer-activate="{default: true, lg: false}"
                        data-kt-drawer-overlay="true"
                        data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                        data-kt-drawer-direction="start"
                        data-kt-drawer-toggle="#kt_header_menu_toggle"
                        data-kt-swapper="true"
                        data-kt-swapper-mode="prepend"
                        data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
                    >
                        <top-bar-menu></top-bar-menu>
                    </div>
                </div>
            </div>
            <div class="row gy-0 gx-10">
                <router-outlet></router-outlet>
            </div>
            <footer-bar></footer-bar>
        </div>
    </div>
</div>
