import { AfterViewInit, Component, ElementRef, EventEmitter, Injector, Input, Output, ViewChild } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ApplePayServiceServiceProxy } from "@shared/service-proxies/service-proxies";

export interface ApplePayEventInfo {
  session: ApplePaySession;
  event: ApplePayJS.ApplePayPaymentAuthorizedEvent;
}

@Component({
    selector: 'checkout-payment-methods-applepay',
    templateUrl: './checkout-payment-methods-applepay.component.html',
    styleUrls: ['./checkout-payment-methods-applepay.component.less'],
})
export class CheckoutPaymentMethodsApplePayComponent extends AppComponentBase implements AfterViewInit {
  @ViewChild('applePayContainer', { static: false }) applePayContainer: ElementRef;

  @Input() totalAmount: number;
  @Input() organizationName: string;
  @Input() canUseApplePay: boolean;

  @Output() applePayEvent: EventEmitter<ApplePayEventInfo> = new EventEmitter<ApplePayEventInfo>();

  constructor(private _applePayService: ApplePayServiceServiceProxy,
    injector: Injector) {
      super(injector);
  }

  ngAfterViewInit(): void {
    if (this.applePayContainer && this.canUseApplePay) {

      this.applePayContainer.nativeElement.innerHTML = '<apple-pay-button id="btn-appl" buttonstyle="black" type="buy" locale="en">';

      var firstChild = this.applePayContainer.nativeElement.firstChild;
      firstChild.addEventListener('click', () => {
        this.initiateApplePay();
      });
    }
  }


  initiateApplePay() {
    const paymentRequest: ApplePayJS.ApplePayPaymentRequest = {
      countryCode: 'US',
      currencyCode: 'USD',
      total: {
        label: this.organizationName ? this.organizationName : this.l("LaunchFundraising"),
        amount: this.totalAmount.toString()
      },
      supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
      merchantCapabilities: ['supports3DS']
    };

    try {
      const session = new ApplePaySession(3, paymentRequest);

      session.onvalidatemerchant = (event) => {
        this._applePayService.validateMerchant(event.validationURL).subscribe((response: any) => {
          session.completeMerchantValidation(JSON.parse(response));
        }, error => {
          console.error('Validate Merchant Failed: ', error);
        });
      };
  
      session.onpaymentauthorized = (event) => {
        var sessionInfo = {
          session: session,
          event: event
        }
        this.applePayEvent.emit(sessionInfo);
      };
  
      session.begin(); 

      } catch (error) {
        console.error('Apple Pay Session Failed: ', error);
      }
  }


}
