
<div class="row top-row">
    <div class="col-lg-9">
        <div class="logo-fundraiser-name row">
            <div class="hour-a-thon-org-logo-container col-3 my-auto">
                <img src="{{logoUrl}}" alt="logo" class="hour-a-thon-logo">
            </div>
            <img src="/assets/common/images/circle-mask-3.svg" alt="logo" class="hour-a-thon-logo logo-mask" />
            <div class="col-9 hour-a-thon-fundraiser-name yellow-text">
                <div class="d-flex flex-column">
                    <div class="hour-a-thon-organization-name brush-stroke">{{organizationName}}</div>
                    <div class="fundraiser-name-and-powered-by-container">
                        <span class="fundraiser-name">{{fundraiserName}}</span>
                        <div class="powered-by-container">
                            <img alt="Fundraiser Group Logo" src="{{tenantLogoUrl}}" class="powered-by-logo" />
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="teamMemberNameInput">
                    <span class="powered-by-text">{{l('OnBehalfOf')}} </span><span class="brush-stroke yellow-text student-progress-footer-name">{{teamMemberNameInput}}</span>
                </ng-container>
            </div>
        </div>
    </div>
    <ng-container *ngIf="!isCheckout">
        <div *ngIf="isMetricDollars || !isLaunchathon" class="col-lg-3 team-progress mt-3" [ngClass]="fundraiserUserId ? 'student-large' : ''">
            <div *ngIf="!isFundraiserOver" class="d-flex justify-content-between team-progress-raised-goal">
                <div>
                    <div class="brush-stroke mini-header-text yellow-text">{{isMetricDollars ? l('TotalRaisedLabel') : l('TotalSoldLabel')}}</div>
                    <div class="white-text student-progress-amount">{{isMetricDollars ? (teamRaised | currency:'USD':'symbol':'1.0-0') : (teamRaised | number:'1.0':'en-US')}}</div>
                </div>
                <div>
                    <div class="brush-stroke mini-header-text yellow-text">{{l('TeamGoalLabel')}}</div>
                    <div class="white-text student-progress-amount">{{isMetricDollars ? (effectiveTeamGoal | currency:'USD':'symbol':'1.0-0') : (effectiveTeamGoal | number:'1.0':'en-US')}}</div>
                </div>
            </div>
            <div *ngIf="isFundraiserOver" class="d-flex justify-content-between team-progress-raised-goal">
                <div>
                    <div class="brush-stroke mini-header-text yellow-text">{{l('TeamGoalLabel')}}</div>
                    <div class="white-text student-progress-amount">{{isMetricDollars ? (teamGoal | currency:'USD':'symbol':'1.0-0') : (teamGoal | number:'1.0':'en-US')}}</div>
                </div>
                <div>
                    <div class="brush-stroke mini-header-text yellow-text">{{isMetricDollars ? l('TotalRaisedLabel') : l('TotalSoldLabel')}}</div>
                    <div class="yellow-text student-progress-amount">{{isMetricDollars ? (teamRaised | currency:'USD':'symbol':'1.0-0') : (teamRaised | number:'1.0':'en-US')}}</div>
                </div>
            </div>
            <div class="team-progress-bar">
                <div class="team-progress-bar-remaining progress-gray"></div>
                <div class="team-progress-bar-raised progress-green" [ngStyle]="{'width': isFundraiserOver ? teamGoalPercentActual : teamGoalPercentEffective}"></div>
            </div>
        </div>
        <ng-container *ngIf="fundraiserUserId && (isMetricDollars || !isLaunchathon)">
            <p-carousel *ngIf="goalValues" [value]="goalValues" [numVisible]="1" [numScroll]="1" [circular]="true" [showIndicators]="false"
                        class="carousel student-progress-bar-small p-0">
                <ng-template let-goalValue pTemplate="item">
                    <div class="team-progress">
                        <div class="d-flex justify-content-between team-progress-raised-goal">
                            <div>
                                <div class="brush-stroke mini-header-text yellow-text">{{goalValue.leftTitle}}</div>
                                <div class="white-text student-progress-amount">
                                    {{isMetricDollars ? (goalValue.leftAmount | currency:'USD':'symbol':'1.0-0') : (goalValue.leftAmount | number:'1.0':'en-US')}}
                                </div>
                            </div>
                            <div>
                                <div class="brush-stroke mini-header-text yellow-text">{{goalValue.rightTitle}}</div>
                                <div class="white-text student-progress-amount">
                                    {{isMetricDollars ? (goalValue.rightAmount | currency:'USD':'symbol':'1.0-0') : (goalValue.rightAmount | number:'1.0':'en-US')}}
                                </div>
                            </div>
                        </div>
                        <div class="team-progress-bar">
                            <div class="team-progress-bar-remaining progress-gray"></div>
                            <div class="team-progress-bar-raised progress-green" [ngStyle]="{'width': goalValue.progressBarValue }"></div>
                        </div>
                    </div>
                    <div class="student-progress-footer">
                        <span class="brush-stroke yellow-text student-progress-footer-name">{{goalValue.name}}</span>
                        <span class="brush-stroke white-text student-progress-footer-label">{{l('ProgressTracker')}}</span>
                    </div>
                </ng-template>
            </p-carousel>
            <div class="d-flex align-items-center justify-content-center w-100">
                <div class="d-flex student-progress-bar-large">
                    <div>
                        <div class="brush-stroke mini-header-text yellow-text">{{l('Start')}}</div>
                        <div class="white-text student-progress-amount">{{isMetricDollars ? l('ZeroDollars') : l('ZeroUnits')}}</div>
                    </div>
                    <div class="student-progress-bar-row">
                        <div class="student-progress-bar-remaining progress-gray"></div>
                        <div class="student-progress-bar-raised progress-green" [ngStyle]="{'width': isFundraiserOver ? studentGoalPercentActual : studentGoalPercentEffective}"></div>
    
                        <ng-container *ngIf="!isFundraiserOver">
                            <div class="student-progress-text student-progress-text-left white-text">
                                {{isMetricDollars ? (studentRaised | currency:'USD':'symbol':'1.0-0') : (studentRaised | number:'1.0':'en-US')}} {{isMetricDollars ? l('StudentRaisedLabel') : l('StudentSoldLabel')}}
                            </div>
                            <div class="student-progress-text student-progress-text-right white-text">
                                {{isMetricDollars ? (studentGoalRemaining | currency:'USD':'symbol':'1.0-0') : (studentGoalRemaining | number:'1.0':'en-US')}} Left!
                            </div>
                        </ng-container>
    
                        <ng-container *ngIf="isFundraiserOver">
                            <div *ngIf="studentRaised < studentGoal" class="student-progress-text white-text student-progress-text-center">
                                {{isMetricDollars ? (studentRaised | currency:'USD':'symbol':'1.0-0') : (studentRaised | number:'1.0':'en-US')}}  {{isMetricDollars ? l('StudentRaisedLabel') : l('StudentSoldLabel')}}
                            </div>
                            <div *ngIf="studentRaised >= studentGoal" class="student-progress-text white-text student-progress-text-center">
                                {{l('GoalComplete')}}</div>
                        </ng-container>
                    </div>
                    <div>
                        <div class="brush-stroke mini-header-text yellow-text">{{l('MyGoalLabel')}}</div>
                        <div class="white-text student-progress-amount">
                            {{isMetricDollars ? ((isFundraiserOver ? studentGoal : effectiveStudentGoal) | currency:'USD':'symbol':'1.0-0') : ((isFundraiserOver ? studentGoal : effectiveStudentGoal) | number:'1.0':'en-US')}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="student-progress-footer student-large">
                <span class="brush-stroke yellow-text student-progress-footer-name">{{studentName}}</span>
                <span class="brush-stroke white-text student-progress-footer-label">{{l('ProgressTracker')}}</span>
            </div>
        </ng-container>
    </ng-container>
</div>