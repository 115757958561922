<div [ngClass]="{ 'd-none': !subscriptionStatusBarVisible() }" class="d-flex align-items-center">
    <div [class]="customStyle" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
        <i class="flaticon-alert fs-2 glowing"></i>
        <span
            class="
                bullet bullet-dot
                bg-success
                h-6px
                w-6px
                position-absolute
                translate-middle
                top-0
                start-50
                animation-blink
            "
        ></span>
    </div>
    <div class="menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-400px p-5" data-kt-menu="true">
        <span
            *ngIf="appSession.tenant?.isInTrialPeriod && !subscriptionIsExpiringSoon()"
            [innerHTML]="getTrialSubscriptionNotification()"
        ></span>
        <a *ngIf="subscriptionIsExpiringSoon()" routerLink="/app/admin/subscription-management">
            <span *ngIf="subscriptionIsExpiringSoon() && appSession.tenant.isInTrialPeriod">
                <i class="fa fa-exclamation-triangle"></i>
                {{ getExpireNotification('TrialExpireNotification') }}
            </span>
            <span *ngIf="subscriptionIsExpiringSoon() && !appSession.tenant.isInTrialPeriod">
                <i class="fa fa-exclamation-triangle"></i>
                {{ getExpireNotification('SubscriptionExpireNotification') }}
            </span>
        </a>
    </div>
</div>
