import { AfterViewInit, Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from "@angular/core";
import { CreateOrEditOrderDetailDto, CreditCardDto, ListItemLookupTableDto, OrderTotalsDto,  SharedOrdersServiceProxy } from "@shared/service-proxies/service-proxies";
import { AppComponentBase } from "@shared/common/app-component-base";
import { NgModel } from "@angular/forms";
import { AppConsts } from "@shared/AppConsts";

@Component({
    selector: 'checkout-payment-methods',
    templateUrl: './checkout-payment-methods.component.html',
    styleUrls: ['./checkout-payment-methods.component.less'],
})
export class CheckoutPaymentMethodsComponent extends AppComponentBase implements AfterViewInit {

    @Input() isGuest: boolean;
    @Input() isProcessingOrder: boolean = false;
    @Input() orderDetail: CreateOrEditOrderDetailDto;
    @Input() paymentMethods: ListItemLookupTableDto[];
    @Input() checksPayableName: string;
    @Input() creditCardText: string;
    @Input() studentCreditCardOnly: boolean;
    @Input() isAProductSelected: boolean
    @Input() shareLink: string;
    @Input() orderTotalsUpdateResult: OrderTotalsDto;
    @Input() fundraiserUserId: number;
    @Input() fundraiserId: number;
    @Input() tipDollars: number;
    @Input() isLaunchathon: boolean;
    @Input() isApplePayEnabledForFundraiser: boolean;
    @Input() canUseApplePay: boolean;

    @ViewChild('creditCardNumberInput', { static: false }) creditCardNumberInput: NgModel;
    @ViewChild('cvvInput', { static: false }) cvvInput: NgModel;

    @Output() sendSharedOrderOutput: EventEmitter<any> = new EventEmitter<any>();
    @Output() goToReviewOrderOutput: EventEmitter<any> = new EventEmitter<any>();
    @Output() updatePaymentMethod: EventEmitter<number> = new EventEmitter<number>();


    customerCCOrderEmailAddress: string;
    paidIsSet: boolean = false;
    expires: string;
    expiresErrorMessage: string = '';
    checkNumberValidationMessage: string = '';

    check = AppConsts.check;
    creditcard = AppConsts.creditcard;
    applepay = AppConsts.applepay;

    selectedPaymentMethod: string;

    guestPaymentMethods: ListItemLookupTableDto[];


    constructor(injector: Injector) {
        super(injector);
    }

    ngAfterViewInit(): void {
        this.setUpPaymentMethods();
    }

    setUpPaymentMethods(): void {
        if(this.paymentMethods) {
            if (this.isGuest) {
                this.guestPaymentMethods = [this.paymentMethods.find(pm => pm.programValue == AppConsts.creditcard)];
                if (this.canUseApplePay && this.isApplePayEnabledForFundraiser) {
                    var applePay = this.paymentMethods.find(pm => pm.programValue == AppConsts.applepay);
                    if (applePay) {
                        this.guestPaymentMethods.push(applePay);
                    }
                }
            } else {
                // remove Apple Pay for Student's logged in page
                var applePayIndex = this.paymentMethods.findIndex(pm => pm.programValue == AppConsts.applepay) ?? -1;
                if (applePayIndex > -1) {
                    this.paymentMethods.splice(applePayIndex, 1);
                }
            }
        }
    }
    
    changePaymentMethod(paymentMethodId: number): void {
        this.updatePaymentMethod.emit(paymentMethodId);
    }
    
    isPaymentMethod(paymentMethod: string): boolean {
        if(!this.paymentMethods || !this.orderDetail?.paymentMethodId) {
            return false;
        }
        return this.orderDetail.paymentMethodId == this.paymentMethods.find(pm => pm.programValue == paymentMethod)?.id
    };

    validateExpiresDate() {
        this.expiresErrorMessage = '';

        if(!this.expires) {
            this.expiresErrorMessage = this.l('{0}IsInvalid', this.l('Expires'));
            return;
        }

        const validExpRegex = /^(0[1-9]|1[0-2])\/\d{2}$/;
        const [month, year] = this.expires.split('/').map(num => parseInt(num, 10));
        if (!validExpRegex.test(this.expires) || (!month || !year)) {
            this.expiresErrorMessage = this.l('{0}IsInvalid', this.l('Expires'));
            return;
        }

        const now = new Date();
        const currentMonth = now.getMonth() + 1; // Months are zero-indexed
        const currentYear = now.getFullYear() % 100; // Get last two digits of the year

        // Check if the expiration date is in the past
        if (year < currentYear || (year === currentYear && month < currentMonth)) {
            this.expiresErrorMessage = this.l('ExpirationExpired');
            return;
        }
    }

    validateCheckNumber() {
        this.checkNumberValidationMessage = '';
        if (this.isPaymentMethod(AppConsts.check) && !this.orderDetail.checkNumber) {
            this.checkNumberValidationMessage = this.l('CheckNumberErrorMessage');
        }
    }

    sendSharedOrder(): void {
        this.sendSharedOrderOutput.emit();
    }

    isNewOrder(): boolean {
        return !this.orderDetail.id;
    }

    goToReviewOrder(): void {
        if (!((this.orderTotalsUpdateResult?.orderTotal ?? 0) + this.tipDollars)) {
            this.message.error(`${this.l('EmptyOrder1')}${!this.isLaunchathon ? ' ' + this.l('EmptyOrder2') : '.'}`);
            return;
        }

        if (!this.isGuest) {
            // if the user is a student and CC is the selected payment method, make that the sole error message:
            if (this.isPaymentMethod(AppConsts.creditcard)){
                this.message.error(this.l('YouMustBeLoggedOut'));
                return;
            }
            // if payment type is check and no check number is entered, make that the sole error message:
            this.validateCheckNumber();
            if (this.checkNumberValidationMessage) {
                this.message.error(this.checkNumberValidationMessage);
                return;
            }  
        }                      
        
        var invalidFields = [];
        var invalidMessages = [];
        
        if (this.isNewOrder()) {
            if (this.isGuest ) {
                if (this.isPaymentMethod(AppConsts.creditcard)) {
                    const validationInputs = [ this.creditCardNumberInput, this.cvvInput];
                    validationInputs.forEach(field => {
                        if (field) { // if field is hidden by ngIf then it will be undefined
                            field.control.markAsTouched();
                            if (field.control.invalid) {
                                invalidFields.push(field.name);
                            }
                        }
                    });
            
                    this.validateExpiresDate();
                    if (this.expiresErrorMessage) {
                        invalidFields.push(this.l('Expires'));
                    }
                }
            }
            else {
                if (!this.paidIsSet) {
                    invalidMessages.push(this.l('SelectPaidOrNotPaid'));
                }
    
                if (!this.studentCreditCardOnly && this.isAProductSelected 
                        && (this.orderDetail.delivered === undefined || this.orderDetail.delivered === null)) {
                            invalidMessages.push(this.l('SelectDeliveredOrNotDelivered'));
                }
            }
        }
        else if (!this.orderDetail.paymentMethodId) {
            invalidMessages.push(this.l('SelectAPaymentMethod'));
        }

        if (invalidFields.length > 0 || invalidMessages.length > 0) {
            var fieldMessage = invalidFields.length > 0 ? `${this.l('PleaseReviewFields')}</br> ${invalidFields.join('</br>')}</br>` : '';
            this.message.error(fieldMessage.concat(invalidMessages.join('</br>')), '', { isHtml: true });
            return;
        }

        if (this.isGuest && !this.isPaymentMethod(AppConsts.creditcard)) {
            this.orderDetail.creditCardInfo = new CreditCardDto();
        }

        this.goToReviewOrderOutput.emit();
    }
}
