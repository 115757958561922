import { Component, Injector } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppComponentBase } from "../../../../shared/common/app-component-base";
import { CreateOrEditProductOptionDto, FundraisersServiceProxy, OrderProductOrPackageDto, ProductOrPackageDto, SharedOrdersServiceProxy, StudentContactsServiceProxy } from "../../../../shared/service-proxies/service-proxies";
import { AppConsts } from "@shared/AppConsts";
import { error } from "console";

// Define the Fundraiser Type enum
export enum FundraiserTypeComponent {
    launchathon = 'launchathonFundraiserHome',
    calendar = 'calendarFundraiserHome',
    product = 'productFundraiserHome',
}

@Component({
    // do we need a template?
    templateUrl: './fundraiser-home.component.html'
})
export class FundraiserHomeComponent extends AppComponentBase {

    constructor(
        injector: Injector,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _fundraisersServiceProxy: FundraisersServiceProxy,
        private _sharedOrdersServiceProxy: SharedOrdersServiceProxy,
        private _studentContactsServiceProxy: StudentContactsServiceProxy
    ) {
        super(injector);
    }

    fundraiserUserId: number;
    slugOrFundraiserId: string;
    queryParams: any = {};
    ngOnInit(): void {
        this._activatedRoute.params.subscribe(params => {
            let orderId: number;
            orderId = params.orderId;

            if (orderId) {
                this.fundraiserUserId = 0;
                this.slugOrFundraiserId = '';
            }
            else {
                this.slugOrFundraiserId = params.slug ?? params.fundraiserId; // params.fundraiserId is for old OrderInfo/:fundraiserId/:fundraiserUserId route.

                if (params.id) {
                    // this is an old anonymous non-launchathon link of the form:
                    // /app/main/fundraiser/<fundraiserId>/placeOrder
                    this.fundraiserUserId = 0;
                    this.slugOrFundraiserId = params.id

                }
                else if (params.fundraiserUserId == 'student') {
                    // this is an old student link.
                    this.fundraiserUserId = params.slug;
                }
                else if (params.fundraiserUserId == 'fundraiser') {
                    // this is an old anonymous link.
                    this.fundraiserUserId = 0;
                }
                else {
                    // this is a new link (produced by post product-fundraiser feature deploy code)
                    this.fundraiserUserId = params.fundraiserUserId;
                }
                         
                if(this.slugOrFundraiserId.charAt(0) === AppConsts.FUNDRAISER_STUDENT_CONTACT_SLUG_PREFIX) {                   

                    this.queryParams = {
                        fscSlug: this.slugOrFundraiserId
                    };    

                    if (params.automatedMessageType) {
                        this.queryParams['automatedMessageType'] = params.automatedMessageType;
                    }
                    
                    this._studentContactsServiceProxy.getFundraiserUserIdFromSlug(this.slugOrFundraiserId).subscribe(result => {
                        this.fundraiserUserId = result;
                    });                  
                }
            }

            this._fundraisersServiceProxy.getFundraiserType(this.fundraiserUserId, this.slugOrFundraiserId, orderId).subscribe(fundraiserTypeDto => {
                let fundraiserTypeComponent = FundraiserTypeComponent.product;
                
                if (fundraiserTypeDto.fundraiserType in FundraiserTypeComponent) {
                    fundraiserTypeComponent = FundraiserTypeComponent[fundraiserTypeDto.fundraiserType];
                }

                if (orderId)
                {
                    this._router.navigate(['app', 'main', fundraiserTypeComponent, 'edit', orderId]);
                }
                else
                {
                    let studentOrFundraiser = this.fundraiserUserId > 0 ? 'student' : 'fundraiser'
                    let id = this.fundraiserUserId > 0 ? this.fundraiserUserId : fundraiserTypeDto.slug;
                    let viewSample = window.location.pathname.includes('viewSample');
    
                    if (viewSample) {
                        this._router.navigate([fundraiserTypeComponent, studentOrFundraiser, 'viewSample', id]);
                    }
                    else if (this.isQueryParamsNotEmpty(this.queryParams)) {
                        this._router.navigate([fundraiserTypeComponent, studentOrFundraiser, id], { queryParams: this.queryParams });
                    }
                    else {
                        this._router.navigate([fundraiserTypeComponent, studentOrFundraiser, id]);
                    }
                }
            },
            error => {
                console.error('getFundraiserType an error occurred:', error);
                this._router.navigate(['notfound']);
            });
        });
    }

    private isQueryParamsNotEmpty(obj: any): boolean {
        return obj && Object.keys(obj).length > 0;
    }
}

export interface GoalNumbers {
    rightTitle: string;
    rightAmount: number;
    leftTitle: string;
    leftAmount: number;
    progressBarValue: string;
    name: string;
}

export class ProductOrPackageViewModel {
    public orderProducts: OrderProductOrPackageDto[];
    public productDetails: ProductOrPackageDto;
    public productOptions: CreateOrEditProductOptionDto[];
}