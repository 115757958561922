<div *ngIf="visible" class="product-option-root mt-5">
    <div class="row">
        <div class="col-md-12 col-lg-6 col-xl-6">
            <div class="m--img-centered product-border">
                <img *ngIf="productDetails.productPictureUrl" alt="{{productDetails.name}}" class="product-image"
                     [src]="productDetails.productPictureUrl" />
                <span class="justify-content-center d-flex">{{productDetails.details}}</span>
            </div>
        </div>
        <div class="col-md-12 col-lg-6 col-xl-6">
            <div class="align-details">
                <div class="product-details">
                    <p><strong>{{productDetails.name}}</strong></p>
                    <p>${{productDetails.price}}</p>
                </div>
                <div class="option-selection mb-5">
                    <p>{{l("SelectAnOption")}}</p>
                    <p-dropdown [options]="productOptions" [(ngModel)]="selectedOrderProduct" optionLabel="name"
                                scrollHeight="300px" appendTo="body">
                        <ng-template let-item pTemplate="selectedItem">
                            <img *ngIf="item.pictureId" [alt]="item.name" [src]="item.pictureId"
                                 style="height:50px;vertical-align:middle" />
                            <span style="font-size:12px;margin:auto 2px auto 2px;color:black;">{{item.name}}</span>
                        </ng-template>
                        <ng-template let-opt pTemplate="item">
                            <div class="ui-helper-clearfix" style="position: relative;">
                                <img *ngIf="opt.pictureId" [alt]="opt.name" [src]="opt.pictureId" style="height:50px;" />
                                <div style="font-size:12px;color:black;">{{opt.name}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div class="option-quantity mb-5">
                    {{l("HowManyProducts")}}
                    <div class="product-increment-decrement product-name mt-2">
                        <i class="product-quantity-decrement fa fa-minus-circle" (click)="decrementQuantity()"></i>
                        <span class="quantity-number">{{selectedQuantity}}</span>
                        <i class="product-quantity-increment fa fa-plus-circle" (click)="incrementQuantity()"></i>
                    </div>
                </div>
                <div *ngIf="selectedOrderProduct.quantity > 0" class="option-quantity">
                    <span *ngIf="!addText">
                        {{l("CurrentlyInOrder")}}  {{selectedOrderProduct.quantity}}
                    </span>
                    <span *ngIf="addText" class="order-option-added">
                        {{addText}}
                    </span>
                </div>
                <div class="mb-3">
                    <button type="button" (click)="doSave()" class="btn btn-primary m-btn--pill proceed-checkout"
                            [disabled]="clickWait" style="background-color: #56B989 !important;">
                        {{l("AddToOrder")}}
                    </button>
                    <button class="btn btn-primary m-btn--pill proceed-checkout" type="button"
                            (click)="proceedToCheckout()" [disabled]="clickWait">
                        {{l("ProceedToCheckout")}}
                    </button>
                </div>
                <div>
                    <button type="button" (click)="doCancel()"
                            class="btn btn-primary m-btn--pill proceed-checkout">
                        {{l("BackToProducts")}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>