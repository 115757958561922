<div class="row inputs-column mb-2 mt-3">
    <!-- DIRECT SHIP OPTION: Direct ship is the only option -->
    <div *ngIf="isGuest && forceDirectShip && orderTotalsUpdateResult?.totalItems > 0" class="top-margin-md">
        <h4>{{l("DirectShipHeader")}}</h4>
        <div class="m--padding-10">
            {{l("ForceDirectShipMessage")}}
        </div>
    </div>
    
    <!-- DIRECT SHIP OPTION: User needs to choose an option -->
    <div *ngIf="isGuest && directShipAvailable && orderTotalsUpdateResult?.totalItems > 0" class="top-margin-md mt-5">
        <h4>{{l("DirectShipHeader")}}</h4>
        <div class="m--padding-10">
            {{l("DirectShipMessage")}}
        </div>
        <div class="row h-unset mt-5">
            <div class="d-flex justify-content-center justify-content-lg-start">
                <button class="btn payment-button payment-button border-1 border-dark"
                        [ngClass]="orderDetail.directShip?'btn-selected':'btn-outline'" type="button"
                        (click)="updateShipOption(true)">
                    {{l("DirectShipToMe")}}
                </button>
                <button class="btn payment-button payment-button border-1 border-dark"
                        [ngClass]="!orderDetail.directShip?'btn-selected':'btn-outline'" type="button"
                        (click)="updateShipOption(false)">
                    {{l("HandDeliverToMe")}}
                </button>
            </div>
        </div>
    </div>
    <!-- Order Details -->
    <ng-container *ngIf="!teamMemberNameInput || (isCalendar && teamMemberId == -1)">
        <div class="col-12 col-md-6">
            <!-- TODO: Currently you're able to select the disabled on behalf of for some reason -->
            <div class="label-height">{{teamMemberId ? l('OnBehalfOf') : ''}}</div>
            <select #teamMemberSelect="ngModel" name="{{l('OnBehalfOf')}}" title="{{l('OnBehalfOf')}}"
                    class="form-control" [(ngModel)]="teamMemberId" required
                    [disabled]="isCalendar && teamMemberId == -1 && writeInSellerFirstName && writeInSellerLastName">
                <option [ngValue]="null" disabled>{{l('OnBehalfOf')}}</option>
                <option value="-1">Other</option>
                <option *ngFor="let teamMember of availableSellers" [value]="teamMember.id">{{teamMember.name}}</option>
            </select>
            <validation-messages [formCtrl]="teamMemberSelect"></validation-messages>
        </div>
        <ng-container *ngIf="teamMemberId == -1">
            <div class="col-6 d-none d-md-block"></div>
            <div class="col-6">
                <div class="label-height">{{teamMemberFirstName ? l('TeamMemberFirstName') : ''}}</div>
                <input #teamMemberFirstNameInput="ngModel" name="{{l('TeamMemberFirstName')}}"
                        type="text" [(ngModel)]="teamMemberFirstName" placeholder="{{l('TeamMemberFirstName')}}"
                        required min="1" max="100" data-lpignore="true" />
                <validation-messages [formCtrl]="teamMemberFirstNameInput"></validation-messages>
            </div>
            <div class="col-6">
                <div class="label-height">{{teamMemberLastName ? l('TeamMemberLastName'): ''}}</div>
                <input #teamMemberLastNameInput="ngModel" name="{{l('TeamMemberLastName')}}"
                        type="text" [(ngModel)]="teamMemberLastName" placeholder="{{l('TeamMemberLastName')}}"
                        required min="1" max="100" data-lpignore="true" />
                <validation-messages [formCtrl]="teamMemberLastNameInput"></validation-messages>
            </div>
        </ng-container>
    </ng-container>
    <!-- force Donations to be on a new line. Best practive as per Bootstrap's documentation -->
    <div class="w-100"></div>
    <div *ngIf="acceptDonations || isLaunchathon" class="col-6">
        <div class="label-height">{{l("Donation")}}</div>
        <p-inputNumber #donationAmountInput="ngModel" name="{{l('Donation')}}" inputId="donationAmountInput" id="donationAmountInput"
                        [(ngModel)]="donation" mode="currency" currency="USD" locale="en-US"
                        placeholder="{{l('Donation')}}" (focusout)="updateTotals()"
                        ngDefaultControl class="form-control">
        </p-inputNumber>
        <span *ngIf="!isDonationValid" class="validation-error">{{l('DonationMustBeAtLeastTenDollars')}}</span>
    </div>

    <ng-container *ngIf="isANewOrder() && orderAllowsTip">
        <div class="col-6 col-md-3">
            <div class="label-height">
                <div class="d-inline-block">{{l("Tip")}}&nbsp;</div>
                <div (click)="showTipInfo()" class="d-inline-block order-cart-help-circle" matTooltip="Info about the action">
                    <i class="fa fa-question-circle"></i>
                </div>
            </div>
            <select [(ngModel)]="tipSelection" name="{{l('Tip')}}" title="Tip" class="form-control form-control-format" required>
                <option *ngFor="let tipOption of tipOptions" [value]="tipOption">{{tipOption}}</option>
            </select>
        </div>
        <ng-container *ngIf="tipSelection == 'Other'">
            <div class="offset-6 col-6 offset-md-0 col-md-3">
                <div class="d-inline-block label-height">{{l("CustomTip")}}</div>
                <input title="Custom Tip" type="number" min="0" max="100" name="{{l('CustomTip')}}" [(ngModel)]="customTipPercent" />
                <div *ngIf="!isCustomTipValid" class="validation-error">{{l("TipValueIsInvalid")}}</div>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="!isANewOrder()">
        <div class="col-6">
            <div class="d-inline-block label-height">{{l("Tip")}}</div>
            <p-inputNumber #tipAmountInput="ngModel" name="{{l('Tip')}}" inputId="tipDollarsInput" id="tipDollarsInput"
                            [(ngModel)]="editOrderTipDollars" mode="currency" currency="USD" locale="en-US"
                            placeholder="{{l('Tip')}}" (focusout)="updateTotals()"
                            ngDefaultControl class="form-control">
            </p-inputNumber>
        </div>
    </ng-container>
    <!-- End Order Details-->
    <!-- Shipping / Billing Details -->

    <div class="subheading w-100 d-flex align-items-center mb-0 mt-5">{{l('BillingInformation')}}</div>
    <div class="col-12 col-md-6">
        <div class="label-height">{{ firstName ? l('FirstName') : '' }}</div>
        <input #addressBillingFirstNameInput="ngModel" name="{{l('FirstName')}}"
                type="text" [(ngModel)]="firstName" placeholder="{{l('FirstName')}}"
                required minlength="1" maxlength="128" data-lpignore="true" />
        <validation-messages [formCtrl]="addressBillingFirstNameInput"></validation-messages>
    </div>

    <div class="col-12 col-md-6">
        <div class="label-height">{{ lastName ? l('LastName') : '' }}</div>
        <input #addressBillingLastNameInput="ngModel" name="{{l('LastName')}}"
                type="text" [(ngModel)]="lastName" placeholder="{{l('LastName')}}"
                required="{{isGuest && isPaymentMethodCreditCard}}" minlength="1" maxlength="128" data-lpignore="true" />
        <validation-messages [formCtrl]="addressBillingLastNameInput"></validation-messages>
    </div>

    <label class="col-12 d-flex align-items-center checkbox-label">
        <input name="isAnonymousCheckbox" type="checkbox" [(ngModel)]="orderDetail.isCustomerAnonymous" />
        {{l('MakeMeAnonymous')}}
    </label>

    <ng-container *ngIf="orderDetail.customerAddress">
        <div class="col-9">
            <div class="label-height">{{ orderDetail.customerAddress.streetLine1 ? l('BillingAddress') : '' }}</div>
            <input #streetInput="ngModel" name="{{l('BillingAddress')}}"
                    type="text" [(ngModel)]="orderDetail.customerAddress.streetLine1" placeholder="{{l('BillingAddress')}}"
                    required="{{isGuest && isPaymentMethodCreditCard}}" minlength="1" maxlength="128" data-lpignore="true" />
            <validation-messages [formCtrl]="streetInput"></validation-messages>
        </div>
        <div class="col-3">
            <div class="label-height">{{ orderDetail.customerAddress.streetLine2 ? l("AddressTwo") : '' }}</div>
            <input #streetInput2="ngModel" name="{{l('AddressTwo')}}"
                    type="text" [(ngModel)]="orderDetail.customerAddress.streetLine2" placeholder="{{l('AddressTwo')}}"
                    maxlength="32" data-lpignore="true" />
            <validation-messages [formCtrl]="streetInput2"></validation-messages>
        </div>

        <div class="col-12 col-md-6">
            <div class="label-height">{{ orderDetail.customerAddress.city ? l("City") : '' }}</div>
            <input #cityInput="ngModel" name="{{l('City')}}"
                    type="text" [(ngModel)]="orderDetail.customerAddress.city" placeholder="{{l('City')}}"
                    required="{{isGuest && isPaymentMethodCreditCard}}" minlength="1" maxlength="128" />
            <validation-messages [formCtrl]="cityInput"></validation-messages>
        </div>

        <div class="col-7 col-md-4">
            <div class="label-height">{{ orderDetail.customerAddress.stateId ? l("State") : '' }}</div>
            <select #stateSelect="ngModel" name="{{l('State')}}" title="{{l('State')}}" class="form-control"
                    [(ngModel)]="orderDetail.customerAddress.stateId" required="{{isGuest && isPaymentMethodCreditCard}}">
                <option [ngValue]="undefined" disabled>State</option>
                <option *ngFor="let state of states" [value]="state.id">{{state.displayName}}</option>
            </select>
            <validation-messages [formCtrl]="stateSelect"></validation-messages>
        </div>

        <div class="col-5 col-md-2">
            <div class="label-height">{{ orderDetail.customerAddress.zipCode ? l("ZipCode") : '' }}</div>
            <input #zipInput="ngModel" name="{{l('ZipCode')}}"
                    type="text" [(ngModel)]="orderDetail.customerAddress.zipCode" placeholder="{{l('ZipCode')}}"
                    minlength="5" maxlength="10" required="{{isGuest && isPaymentMethodCreditCard}}" data-lpignore="true" />
            <validation-messages [formCtrl]="zipInput"></validation-messages>
        </div>

        <div class="col-12 col-md-5">
            <div class="label-height">{{l("Phone")}}</div>
            <p-inputMask #phoneInput="ngModel" name="{{l('Phone')}}" [autoClear]="false"
                            mask="(999) 999-9999" [(ngModel)]="orderDetail.customerAddress.phoneNumber"
                            placeholder="(___) ___-____" ngDefaultControl (onInput)="validatePhoneNumber()" (onBlur)="validatePhoneNumber()"
                            class="form-control" data-lpignore="true">
            </p-inputMask>
            <span *ngIf="phoneErrorMessage" class="validation-error">{{phoneErrorMessage}}</span>
        </div>

        <div class="col-12 col-md-7">
            <div class="label-height">{{ orderDetail.customerAddress.emailAddress ? l("Email") : '' }}</div>
            <input #emailInput="ngModel" name="{{l('Email')}}"
                    type="text" [(ngModel)]="orderDetail.customerAddress.emailAddress" placeholder="{{l('Email')}}"
                    [required]="(isGuest && isPaymentMethodCreditCard) || orderContainsDGCSelection" maxlength="256" [pattern]="emailAddressPattern" />
            <validation-messages [errorDefs]="[{ pattern: l('InvalidEmailAddress') }]"
                                    [formCtrl]="emailInput"></validation-messages>
        </div>
    </ng-container>

    <!-- shipping address -->
    <ng-container *ngIf="orderDetail.directShip">
        <div class="my-3 pt-5">
            <h4>{{l("AddressSendTo")}}</h4>
            <div class="mb-5">
                <div class="row mb-3">
                    <div class="d-flex justify-content-center">
                        <button class="btn payment-button payment-button border-1 border-dark"
                                [ngClass]="!setShippingAddress?'btn-selected':'btn-outline'" type="button"
                                (click)="setShippingAddress = false;">
                            {{l("AddressSameAsBilling")}}
                        </button>
                        <button class="btn payment-button payment-button border-1 border-dark"
                                [ngClass]="setShippingAddress?'btn-selected':'btn-outline'" type="button"
                                (click)="setShippingAddress = true;">
                            {{l("AddressSetShipping")}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="setShippingAddress">
            <div class="subheading w-100 d-flex align-items-center mb-0 mt-5">{{l('ShippingInformation').toUpperCase()}}</div>
            <div class="col-12 col-md-6">
                <div class="label-height">{{ orderDetail.shippingAddress.name ? l('Name') : '' }}</div>
                <input #shippingName="ngModel" name="{{l('Name')}}"
                        type="text" [(ngModel)]="orderDetail.shippingAddress.name" placeholder="{{l('Name')}}"
                        required minlength="1" maxlength="128" data-lpignore="true" />
                <validation-messages [formCtrl]="shippingName"></validation-messages>
            </div>

            <div class="col-9">
                <div class="label-height">{{ orderDetail.shippingAddress.streetLine1 ? l("ShippingAddress") : ''}}</div>
                <input #shippingStreet="ngModel" name="{{l('ShippingAddress')}}"
                        type="text" [(ngModel)]="orderDetail.shippingAddress.streetLine1" placeholder="{{l('ShippingAddress')}}"
                        required minlength="1" maxlength="128" data-lpignore="true" />
                <validation-messages [formCtrl]="shippingStreet"></validation-messages>
            </div>
            <div class="col-3">
                <div class="label-height">{{ orderDetail.shippingAddress.streetLine2 ? l("AddressTwo") : ''}}</div>
                <input #shippingStreet2="ngModel" name="{{l('AddressTwo')}}"
                        type="text" [(ngModel)]="orderDetail.shippingAddress.streetLine2" placeholder="{{l('AddressTwo')}}"
                        maxlength="32" data-lpignore="true" />
                <validation-messages [formCtrl]="shippingStreet2"></validation-messages>
            </div>

            <div class="col-12 col-md-6">
                <div class="label-height">{{ orderDetail.shippingAddress.city ? l("City") : ''}}</div>
                <input #shippingCity="ngModel" name="Ship-to {{l('City')}}"
                        type="text" [(ngModel)]="orderDetail.shippingAddress.city" placeholder="{{l('City')}}"
                        required minlength="1" maxlength="128" />
                <validation-messages [formCtrl]="shippingCity"></validation-messages>
            </div>

            <div class="col-7 col-md-4">
                <div class="label-height">{{ orderDetail.shippingAddress.stateId ? l("State") : ''}}</div>
                <select #shippingState="ngModel" name="Ship-to {{l('State')}}" title="Ship-to {{l('State')}}"
                        class="form-control contact-field-short"
                        [(ngModel)]="orderDetail.shippingAddress.stateId" required>
                    <option [ngValue]="undefined" disabled>State</option>
                    <option *ngFor="let state of states" [value]="state.id">{{state.displayName}}</option>
                </select>
                <validation-messages [formCtrl]="shippingState"></validation-messages>
            </div>

            <div class="col-5 col-md-2">
                <div class="label-height">{{ orderDetail.shippingAddress.zipCode ? l("ZipCode") : ''}}</div>
                <input #shippingZip="ngModel" name="Ship-to {{l('ZipCode')}}"
                        type="text" [(ngModel)]="orderDetail.shippingAddress.zipCode" placeholder="{{l('ZipCode')}}"
                        minlength="5" maxlength="10" required data-lpignore="true" />
                <validation-messages [formCtrl]="shippingZip"></validation-messages>
            </div>
        </ng-container>
    </ng-container>
    <!-- End Shipping / Billing Details -->
    <div class="col-12 d-flex align-items-center my-3">
        <button type="button" class="customer-submit-button customer-button primary-bg h6 text-white" (click)="goToPaymentMethods()">{{l('ProceedToPayment')}}</button>
    </div>
</div>
