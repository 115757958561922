import { Component, OnInit, Output, EventEmitter, Input, Injector } from '@angular/core';
import { OrderProductOrPackageDto, ProductOrPackageDto, ViewOrderProductDto, CreateOrEditProductOptionDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConsts } from '@shared/AppConsts';

@Component({
    selector: 'order-options',
    templateUrl: './order-options.component.html',
    styleUrls: ['./order-options.component.less']
})
export class OrderOptionsComponent extends AppComponentBase {

    constructor(injector: Injector) {
        super(injector);
    }

    productDetails: ProductOrPackageDto;
    productOptions: ProductOptionViewModel[];
    selectedOrderProduct: ProductOptionViewModel;
    selectedQuantity = 1;
    visible = false;
    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;
    clickWait = false;
    addText: string;

    @Output() save: EventEmitter<OrderProductOrPackageDto> = new EventEmitter<OrderProductOrPackageDto>();
    @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
    @Output() orderOptionsCheckout: EventEmitter<any> = new EventEmitter<any>();

    show(productDetails: ProductOrPackageDto, productOptions: CreateOrEditProductOptionDto[], orderProducts: OrderProductOrPackageDto[]) {
        this.addText = undefined;
        this.productDetails = productDetails;
        this.productOptions = productOptions.map(x => { // copying so we can do Cancel
            let k = new ProductOptionViewModel();
            k.name = x.name;
            k.details = x.details;
            k.productId = this.productDetails.id;
            k.productOptionId = x.id;
            k.pictureId = x.pictureUrl;
            let op = orderProducts.find(y => y.productOrPackageId == k.productId && y.productOptionId == k.productOptionId);
            if (op)
                k.quantity = op.quantity;
            else
                k.quantity = 0;
            return k;
        });
        this.selectedOrderProduct = this.productOptions[0];
        this.selectedQuantity = 1;
        this.visible = true;
    }

    doCancel() {
        this.cancel.emit(null);
        this.hide();
    }

    doSave(isFromProceedToCheckout: boolean = false) {
        this.clickWait = true;
        if (!isFromProceedToCheckout || this.selectedOrderProduct.quantity == 0) {
            this.selectedOrderProduct.quantity += this.selectedQuantity;
        }
        let orderProduct = new OrderProductOrPackageDto();
        orderProduct.productOrPackageId = this.selectedOrderProduct.productId;
        orderProduct.productOptionId = this.selectedOrderProduct.productOptionId;
        orderProduct.quantity = this.selectedOrderProduct.quantity;
        this.save.emit(orderProduct);
        this.addText = this.l('AddedToOrder', this.selectedQuantity, this.selectedOrderProduct.name);
        this.selectedQuantity = 1;
        setTimeout(() => {
            this.clickWait = false;
            this.addText = undefined;
        }, 1500);
    }

    proceedToCheckout() {
        this.doSave(true);
        this.orderOptionsCheckout.emit();
    }

    hide() {
        this.visible = false;
        this.productDetails = undefined;
        this.productOptions = undefined;
        this.selectedOrderProduct = undefined;
    }

    decrementQuantity() {
        if (this.selectedQuantity > 1)
            this.selectedQuantity--;
    }

    incrementQuantity() {
        this.selectedQuantity++;
    }
}

export class ProductOptionViewModel {
    productId: number;
    productOptionId: number;
    name: string;
    details: string;
    pictureId: string;
    quantity: number;
}