import { CurrencyPipe, Location } from "@angular/common";
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Inject, Injector, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { finalize } from "rxjs/operators";
import { appModuleAnimation } from "../../../../shared/animations/routerTransition";
import { AppComponentBase } from "../../../../shared/common/app-component-base";
import {
    AddressDto, CreateISponsorAccountDto, CreateOrEditOrderDetailDto, CreateOrEditProductOptionDto, CreditCardDto,
    FundraisersServiceProxy,
    FundraiserUserSelector, ListItemLookupTableDto, ListItemsServiceProxy, OrderDetailsServiceProxy, OrderProductOrPackageDto, OrderTotalsDto,
    OrderTotalsInput, RepInfoDto, SharedOrdersServiceProxy
} from "../../../../shared/service-proxies/service-proxies";
import { ProductOrPackageViewModel } from "../fundraiser-home/fundraiser-home.component";
import { CheckoutOrderDetailsComponent } from "./checkout-order-details/checkout-order-details.component";
import { CheckoutPaymentMethodsComponent } from "./checkout-payment-methods/checkout-payment-methods.component";
import { CheckoutReviewOrderComponent } from "./checkout-review-order/checkout-review-order.component";
import { AppConsts } from "@shared/AppConsts";
import { ApplePayEventInfo } from "./checkout-payment-methods-applepay/checkout-payment-methods-applepay.component";
import { AlertComponent } from "ngx-bootstrap/alert";

@Component({
    selector: 'checkout-v2',
    templateUrl: './checkout-v2.component.html',
    styleUrls: ['./checkout-v2.component.less'],
    animations: [appModuleAnimation()]
})
export class CheckoutV2Component extends AppComponentBase implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('orderForm', { read: NgForm, static: true }) orderForm: any;
    @ViewChild('applePayAlert', { static: false }) applePayAlert: AlertComponent;

    // ----- Header -----
    @Input() logoUrl: string;
    @Input() organizationName: string;
    @Input() fundraiserName: string;
    @Input() tenantLogoUrl: string;
    @Input() teamMemberNameInput: string;
    
    
    // ----- Order Product Details -----
    selectedProductOrders: ProductOption[];
    orderTotalsUpdateResult: OrderTotalsDto;
    @Input() orderDetail: CreateOrEditOrderDetailDto;
    @Input() isDonationOnly: boolean;
    @Input() acceptDonations: boolean;
    @Input() convenienceFee: number;
    get isAProductSelected(): boolean {
        return this.productAndPackageViewModels?.find(p => p.orderProducts?.find(op => op.quantity > 0) != undefined) != undefined;
    }


    // ----- Order Details -----
    // orderDetail: CreateOrEditOrderDetailDto
    // teamMemberNameInput: string
    // acceptDonations: boolean
    // isDonationOnly: boolean
    @ViewChild('checkoutOrderDetails') checkoutOrderDetails: CheckoutOrderDetailsComponent;
    @Input() teamMemberIdInput: number;
    @Input() availableSellers: FundraiserUserSelector[];
    @Input() isLaunchathon = false;
    @Input() isCalendar = false;
    @Input() isGuest: boolean;
    @Input() donationAmountInput: number;
    @Input() donateByRoundingOrderTo: number;
    @Input() tipInfoTitle: string;
    @Input() tipInfo: string;
    @Input() writeInSellerFirstName: string;
    @Input() writeInSellerLastName: string;


    // ----- Shipping / Billing Details -----
    // orderDetail: CreateOrEditOrderDetailDto
    // isGuest: boolean
    // orderTotalsUpdateResult: OrderTotalsDto
    isShippingDetailsOpen: boolean = true;
    @Input() directShipAvailable: boolean;
    @Input() forceDirectShip: boolean;
    @Input() paymentMethods: ListItemLookupTableDto[];
    states: ListItemLookupTableDto[] = [];
    orderContainsDGCSelection: boolean;


    // -----Payment Details -----
    // isGuest: boolean
    // orderDetail: CreateOrEditOrderDetailDto
    // paymentMethods: ListItemLookupTableDto[]
    // isAProductSelected: boolean
    // orderTotalsUpdateResult: OrderTotalsDto
    @ViewChild('checkoutPaymentMethods') checkoutPaymentMethods: CheckoutPaymentMethodsComponent;
    isPaymentMethodsOpen: boolean = false;
    isPaymentMethodsDisabled: boolean = true;  
    @Input() checksPayableName: string;
    @Input() creditCardText: string;
    @Input() studentCreditCardOnly: boolean;
    @Input() shareLink: string;
    @Input() fundraiserUserIdInput: number;
    @Input() fundraiserIdInput: number;


    // ----- Review Order -----
    // orderDetail: CreateOrEditOrderDetailDto
    @ViewChild('checkoutReviewOrder') checkoutReviewOrder: CheckoutReviewOrderComponent;
    isReviewOrderOpen: boolean = false;
    isReviewOrderDisabled: boolean = true;
    get stateName(): string {
        return this.states.find(s => s.id == this.orderDetail.customerAddress?.stateId)?.displayName;
    }
    get paymentMethodName(): string {
        return this.paymentMethods ? this.paymentMethods.find(pm => pm.id == this.orderDetail.paymentMethodId)?.displayName : this.l('CreditDebit');
    }


    // ----- Launchathon related Inputs -----
    @Input() isCompleteGoal: boolean;


    // ----- Product related Inputs -----
    @Input() productAndPackageViewModels: ProductOrPackageViewModel[];
    @Input() productOptions: { [id: number]: CreateOrEditProductOptionDto };
    @Input() defaultOrderDetailToDirectShip: boolean;


    // ----- Misc Inputs -----
    @Input() guestPayCCOption: number;
    @Input() repInfo: RepInfoDto;
    @Input() guestPayMode: number;
    @Input() fundraiserStudentContactSlug: string;
    @Input() automatedMessageType: string;
    @Input() orderDetailId: number;
    @Input() isInitialLoadForEdit: boolean;
    @Input() tenantId: number;
    readonly PAY_INPERSON = 0;
    readonly PAY_TEXTCC = 1;
    readonly PAY_CC = 2;
    showCheckoutDetails = true;


    // ----- Outputs -----
    @Output() orderComplete: EventEmitter<any> = new EventEmitter<any>();
    @Output() cancelOrder: EventEmitter<any> = new EventEmitter<any>();
    @Output() goBack: EventEmitter<OrderDetailPlusProductAndPackageViewModels> = new EventEmitter<OrderDetailPlusProductAndPackageViewModels>();

    // ----- Misc variables -----
    isProcessingOrder: boolean = false;
    orderTransactionId: string;
    shippingCostRequestId: number | null = null;
    isUpdatingTotals: boolean = false;

    // ----- Apple Pay -----
    @Input() isApplePayEnabledForFundraiser: boolean = false; // this determines if the fundraiser has apple pay enabled
    canUseApplePay: boolean = false; // this determines if the user CAN use apple pay
    useApplePay: boolean = false; // this determines if the user IS using apple pay
    applePaySessionInfo: ApplePayEventInfo;

    // ----- iSponsor -----
    @Input() isISponsorEnabled: boolean = false;
    

    constructor(
        injector: Injector,
        private _sharedOrdersServiceProxy: SharedOrdersServiceProxy,
        private _orderDetailsServiceProxy: OrderDetailsServiceProxy,
        private _currencyPipe: CurrencyPipe,
        private _router: Router,
        private renderer: Renderer2,
        private _listItemsService: ListItemsServiceProxy,
        private _fundraisersServiceProxy: FundraisersServiceProxy,
        private cdr: ChangeDetectorRef,
        private Location:Location,
    ) {
        super(injector);
        this.addSweetAlertFix();
    }

    ngOnDestroy(): void {
        this.renderer.removeClass(document.body, 'swal2-iosfix');
    }

    ngAfterViewInit(): void {
        this.cdr.detectChanges();

        if (this.applePayAlert) {
            this.applePayAlert.dismissible = true;
        }
        
        if (this.isANewOrder()) {
            this.setupView();
        }
        else {
            this.setupView();
            this.setupViewForExistingOrder();
        }
    }

    ngOnInit(): void {
        // scroll to top of the page
        window.scroll({ top: 0, left: 0 });

        this.canUseApplePay = this.isApplePayEnabledForFundraiser && ApplePaySession !== undefined;
        if(this.canUseApplePay) {
            try {
                // when testing in localhost this throws an InvalidOperationException bc localhost cannot be set up for ApplePay
                // however if any exception occurs let's go ahead and set ApplePay to false
                this.canUseApplePay = ApplePaySession.canMakePayments();
            } catch (err) {
                this.canUseApplePay = false;
            }
        }

        if (this.isInitialLoadForEdit && this.orderDetailId) {
            // user is backoffice, editing an existing order.
            this._orderDetailsServiceProxy.getOrderDetailForEdit(this.orderDetailId).subscribe(result => {
                this.orderDetail = result.orderDetail;
                if (!this.orderDetail.shippingAddress) {
                    this.orderDetail.shippingAddress = new AddressDto();
                }
                if (this.orderDetail.shippingAddress.stateId == null) {
                    this.orderDetail.shippingAddress.stateId = undefined;
                }

                this.orderDetail.customDonation = this.orderDetail.donationAmount;

                this.availableSellers = result.availableSellers;
                this.fundraiserName = result.fundraiserName;
                this.fundraiserIdInput = this.orderDetail.fundraiserId;

                let isDoneGettingFundraiserPaymentMethods = false;
                let isDoneGettingAllProductsForFundraiser = false;

                this._sharedOrdersServiceProxy.getFundraiserPaymentMethods(this.orderDetail.fundraiserId).subscribe(result => {
                    this.paymentMethods = result.paymentMethods;
                    this.checksPayableName = result.checksPayableName;
                    this.creditCardText = result.creditCardText;
                    this.convenienceFee = result.convenienceFee;
                    this.directShipAvailable = result.directShipAvailable;
                    this.forceDirectShip = result.forceDirectShip;
                    this.acceptDonations = result.acceptDonations;

                    this._listItemsService.getListItemsForLookupTable('paymentmethods').subscribe(result => {
                        this.paymentMethods = result.items;
                        let checkPaymentMethodId = this.paymentMethods.find(pm => pm.programValue == AppConsts.check)?.id;
                        let cashPaymentMethodId = this.paymentMethods.find(pm => pm.programValue == AppConsts.cash)?.id;

                        if (this.orderDetail.paymentMethodId == checkPaymentMethodId || this.orderDetail.paymentMethodId == cashPaymentMethodId) {
                            this.guestPayMode = this.PAY_INPERSON;
                        }
                        else {
                            this.guestPayCCOption = this.PAY_CC;
                        }
                    });

                    if (!this.isGuest && result.isCreditCardOnly && this.isANewOrder())
                        this.studentCreditCardOnly = true;

                    if (this.forceDirectShip) {
                        this.orderDetail.directShip = true;
                        this.guestPayMode = this.guestPayCCOption;
                    }

                    isDoneGettingFundraiserPaymentMethods = true;
                    if (!this.isInitialLoadForEdit || isDoneGettingAllProductsForFundraiser) {
                        // now that we have guestPayMode, updateTotals to get convenience fee:
                        this.updateTotals();
                    }

                });

                this._fundraisersServiceProxy.getAllProductsForFundraiser(this.orderDetail.fundraiserId).subscribe(result => {
                    this.productAndPackageViewModels = [];
                    this.productOptions = {};
                    result.productOptions.forEach(x => this.productOptions[x.id] = x);

                    result.productsAndPackages.forEach((productOrPackage) => {
                        // skip inactive products if user is a customer creating a new order.
                        // else, user is an admin, editing an order, and needs to see inactive products.
                        if (this.orderDetailId || productOrPackage.isActive) {
                            var ppvm = new ProductOrPackageViewModel();
                            ppvm.productDetails = productOrPackage;
                            if (ppvm.productDetails && ppvm.productDetails.id) {
                                ppvm.orderProducts = [];
                                ppvm.productOptions = result.productOptions.filter(x => ppvm.productDetails.productOptionIds?.indexOf(x.id) >= 0);

                                if (ppvm.productOptions && ppvm.productOptions.length > 0) {
                                    for (let opt of ppvm.productOptions) {
                                        let orderProduct = this.setupOrderProduct(ppvm.productDetails.id, ppvm.productDetails.isPackage, opt.id);
                                        ppvm.orderProducts.push(orderProduct);
                                    }
                                } else {
                                    let orderProduct = this.setupOrderProduct(ppvm.productDetails.id, ppvm.productDetails.isPackage);
                                    ppvm.orderProducts.push(orderProduct);
                                }

                                this.productAndPackageViewModels.push(ppvm);
                            }
                        }
                    });

                    isDoneGettingAllProductsForFundraiser = true;
                    if (isDoneGettingFundraiserPaymentMethods) {
                        this.updateTotals();
                    }
                });

                this.setupViewForExistingOrder();
            });
        }
        if (this.isANewOrder()) {
            this.setupViewForNewOrder();
        }
        this._sharedOrdersServiceProxy.getListItemsForLookupTable('states', undefined, 0, 50).subscribe(result => {
            this.states = result.items;
        });

    }

    isANewOrder(): boolean {
        return !this.orderDetail?.id;
    }

    setupOrderProduct(productId: number, isPackage: boolean, productOptionId: number = undefined): OrderProductOrPackageDto {
        let orderProduct = new OrderProductOrPackageDto();
        orderProduct.productOrPackageId = productId;
        orderProduct.productOptionId = productOptionId;

        var quantity = 0;
        var isPackage: boolean;
        if (this.orderDetail.orderProductsAndPackages) {
            var op = this.orderDetail.orderProductsAndPackages.find(x => x.productOrPackageId == productId && x.productOptionId == productOptionId);
            if (op) {
                quantity = op.quantity;
            }
            isPackage = isPackage;
        }
        orderProduct.quantity = quantity;
        orderProduct.isPackage = isPackage

        return orderProduct;
    }

    setupView() {
        if (this.donationAmountInput && !this.isAProductSelected) {
            this.isDonationOnly = true;
            this.cdr.detectChanges();
        } else {
            // clear out the default tip (that we'd use for a launchathon fundraiser):
            this.checkoutOrderDetails.tipSelection = 'None';
            this.cdr.detectChanges();
        }

        if (this.teamMemberIdInput) {
            this.checkoutOrderDetails.teamMemberId = this.teamMemberIdInput;
        }
        this.orderDetail.customDonation = this.donationAmountInput;

        if (!this.paymentMethods || this.paymentMethods.length == 0) {
            this._sharedOrdersServiceProxy.getFundraiserPaymentMethods(this.fundraiserIdInput).subscribe(result => {
                this.paymentMethods = result.paymentMethods;
                
                if (this.checkoutPaymentMethods && this.checkoutPaymentMethods.paymentMethods == null) {
                    this.checkoutPaymentMethods.paymentMethods = this.paymentMethods;
                    // check paymentMethods again since they are now set.
                    this.checkoutPaymentMethods.setUpPaymentMethods();
                }

                if (this.orderDetail.paymentMethodId == null) {
                    // this is what happens in product-fundraiser-home, which then passes the orderDetail into the checkout component
                    // (which could be this component, if the user selects no products, of course...)
                    if (this.isGuest) {
                        this.orderDetail.paymentMethodId = this.paymentMethods.find(pm => pm.programValue == 'creditcard')?.id
                    }
                    else {
                        this.orderDetail.paymentMethodId = this.paymentMethods[0].id;
                    }
                }
                this.checksPayableName = result.checksPayableName;
                this.creditCardText = result.creditCardText;

                if (this.isGuest) {
                    if (result.enableCreditCardPayment) {
                        this.guestPayMode = this.PAY_CC;
                        this.guestPayCCOption = this.PAY_CC;
                        this.orderDetail.creditCardInfo = new CreditCardDto();
                    } else if (this.creditCardText && this.creditCardText.trim() !== '') {
                        this.guestPayMode = this.PAY_TEXTCC;
                        this.guestPayCCOption = this.PAY_TEXTCC;
                    } else
                        this.guestPayMode = this.PAY_INPERSON;
                }

                if (!this.isGuest && result.isCreditCardOnly && !(this.orderDetail.id > 0))
                    this.studentCreditCardOnly = true;
            });
            this.updateTotals();
        }
        else {
            this.updateTotals();
        }
    }

    setupViewForNewOrder() {
        // I don't know why we're overwriting the orderDetail we get from the fundraiser home page component,
        // but we need to hold-on to the paymentMethodId:
        let paymentMethodId = this.orderDetail?.paymentMethodId;

        if(!this.orderDetail)
            this.orderDetail = new CreateOrEditOrderDetailDto();

        if (this.defaultOrderDetailToDirectShip) {
            this.orderDetail.directShip = true;
            this.cdr.detectChanges();
        }
        
        this.orderDetail.customerAddress = new AddressDto();
        this.orderDetail.shippingAddress = new AddressDto();
        this.orderDetail.creditCardInfo = new CreditCardDto();

        if(this.orderDetail.paymentMethodId == null)
            this.orderDetail.paymentMethodId = paymentMethodId;

        this.orderDetail.fundraiserStudentContactSlug = this.fundraiserStudentContactSlug;
    }

    setupViewForExistingOrder() {
        if (!this.orderDetailId) {
            this.message.error(this.l('FailedLoadOrderDetail', this.orderDetailId));
            return;
        }
        this.checkoutOrderDetails.teamMemberId = this.orderDetail.studentId ?? -1;
        this.checkoutOrderDetails.teamMemberFirstName = this.orderDetail.sellerFirstName?.trim();
        this.checkoutOrderDetails.teamMemberLastName = this.orderDetail.sellerLastName?.trim();
        this.checkoutOrderDetails.setShippingAddress = !!this.orderDetail.shippingAddress?.streetLine1;
        this.checkoutOrderDetails.editOrderTipDollars = this.orderDetail.tipDollars;
        this.checkoutOrderDetails.donation = this.orderDetail.donationAmount;
        
        this.checkoutPaymentMethods.paidIsSet = true;

        let addressNames = this.orderDetail.customerAddress?.name?.replace(/\s+/g, ' ')?.trim()?.split(' ');
        if (addressNames.length == 1) {
            this.checkoutOrderDetails.firstName = addressNames[0];
        }
        else if (addressNames.length > 1) {
            this.checkoutOrderDetails.firstName = addressNames.slice(0, addressNames.length - 1).join(' ');
            this.checkoutOrderDetails.lastName = addressNames[addressNames.length - 1];
        }

        // instead of trying to match tipPercentage to a tip selection option,
        // let's just select the 'custom' option, and set the custom value to whatever the tipPercentage is.
        this.checkoutOrderDetails.tipSelection = 'Other';
        this.checkoutOrderDetails.customTipPercent = this.orderDetail.tipPercentage;
    }

    ngDoCheck() {
        // sweet alert clears out the swal2-iosfix when it closes,
        // but we need to put it back in case a sweet alert appears again(as for e.g.a confirmation modal).
        // everytime the dom changes, we'll check to make sure the class is on the body element.
        if (!document.body.classList.contains('swal2-iosfix')) {
            this.addSweetAlertFix();
        }
    }
    addSweetAlertFix() {
        // sweet alert component needs body to have swal2-iosfix when it loads.
        // it runs code to put the class there, but it doesn't put it there in time.
        this.renderer.addClass(document.body, 'swal2-iosfix');
    }

    goToShippingDetails(isOpen: boolean): void {
        this.isShippingDetailsOpen = isOpen;
        if(isOpen) {
            // reset the other steps in case they came from a previous step
            this.isPaymentMethodsDisabled = true;
            this.isPaymentMethodsOpen = false;
            this.isReviewOrderDisabled = true;
            this.isReviewOrderOpen = false;
        }
    }

    // Order Details Methods
    incrementQuantity(productOption: ProductOption): void {
        let product = this.productAndPackageViewModels.find(pvm => pvm.productDetails.id == productOption.productId);
        if (!product) return;
        
        let opm = product.orderProducts.find(op => op.productOptionId == productOption.productOptionId);
        if (!opm) return;

        opm.quantity++;
        this.updateTotals(!this.isPaymentMethodsDisabled); // if we're on the payment methods step or later, we need to calculate shipping totals
    }

    decrementQuantity(productOption: ProductOption): void {
        let product = this.productAndPackageViewModels.find(pvm => pvm.productDetails.id == productOption.productId);
        if (!product) return;

        if (productOption.quantity === 1) {
            this.removeProduct(productOption);
        } else {
            let opm = product.orderProducts.find(op => op.productOptionId == productOption.productOptionId);
            if(!opm) return;

            opm.quantity--;
            this.updateTotals(!this.isPaymentMethodsDisabled); // if we're on the payment methods step or later, we need to calculate shipping totals
        }
    }

    removeProduct(productOption: ProductOption): void {
        this.message.confirm('',
            this.l('RemoveProductFromCart'),
            (isConfirmed) => {
                if (isConfirmed) {
                    let product = this.productAndPackageViewModels.find(pvm => pvm.productDetails.id == productOption.productId);
                    let opm = product.orderProducts.find(op => op.productOptionId == productOption.productOptionId);
                    opm.quantity = 0;
                    this.updateTotals(!this.isPaymentMethodsDisabled); // if we're on the payment methods step or later, we need to calculate shipping totals
                }
            });
    }
    // End Order Details Methods


    // Start Shipping Details Methods

    updateShipOption(directShip: boolean) {
        // direct ship is true clears out billing address
        this.orderDetail.directShip = directShip;
        this.guestPayMode = directShip ? this.guestPayCCOption : this.guestPayMode;
        this.updateTotals(directShip);
    }

    isPaymentMethod(paymentMethod: string): boolean {
        if(!this.paymentMethods || !this.orderDetail?.paymentMethodId) {
            return false;
        }
        return this.orderDetail.paymentMethodId == this.paymentMethods.find(pm => pm.programValue == paymentMethod)?.id
    };

    async updateTotals(calculateVendorTotals: boolean = false) {
        this.setSelectedProductOrders();
        this.orderDetail.orderProductsAndPackages = [];
        for (var k in this.productAndPackageViewModels) {
            this.orderDetail.orderProductsAndPackages.push(...this.productAndPackageViewModels[k].orderProducts);
        }

        let orderTotalsInput = new OrderTotalsInput();
        orderTotalsInput.fundraiserId = this.fundraiserIdInput;

        // creditCardPayment is used to figure whether to add a convenience fee.
        let paymentMethodOrderWasPlacedWith = this.orderDetail.paymentMethodId;
        if (paymentMethodOrderWasPlacedWith) {
            // if user is editing an order, then we'll check what was saved as the payment method
            orderTotalsInput.creditCardPayment = this.isPaymentMethod(AppConsts.creditcard) || this.isPaymentMethod(AppConsts.applepay);
        }
        else {
            // else it depends on whether a student is placing the order for a customer (i.e. cash or check),
            // or the customer is placing it directly (i.e. credit card)
            orderTotalsInput.creditCardPayment = this.guestPayMode == this.PAY_CC;
        }

        orderTotalsInput.directShip = this.orderDetail.directShip;
        orderTotalsInput.fundraiserId = this.fundraiserIdInput;
        orderTotalsInput.orderProductsAndPackages = this.orderDetail.orderProductsAndPackages;
        orderTotalsInput.customDonation = this.orderDetail.customDonation;
        orderTotalsInput.selectedDonation = this.orderDetail.selectedDonation;
        orderTotalsInput.donateByRoundingOrderTo = this.donateByRoundingOrderTo;

        if (calculateVendorTotals) {
            orderTotalsInput.shippingAddress = this.useShippingAddress() ? this.orderDetail.shippingAddress : this.orderDetail.customerAddress;
        }

        this.orderContainsDGCSelection = false;
        this.isUpdatingTotals = true;
        this.cdr.detectChanges();

        this._sharedOrdersServiceProxy.updateOrderTotals(calculateVendorTotals, orderTotalsInput)
            .subscribe(result => {
                this.orderTotalsUpdateResult = result;
                this.shippingCostRequestId = result.shippingCostRequestId;
                if (this.orderTotalsUpdateResult.totalItems == 0) {
                    this.orderDetail.directShip = false;
                    if (this.checkoutOrderDetails && !this.isDonationOnly && !this.orderDetailId) {
                        this.checkoutOrderDetails.tipSelection = this.checkoutOrderDetails.defaultTipSelection;
                    }
                    if (!this.isLaunchathon) {
                        if(this.acceptDonations) {
                            this.isDonationOnly = true;
                        }
                        else {
                            this.showCheckoutDetails = false;
                        }
                    }
                }
                else {
                    if (!this.isANewOrder() && this.orderDetail.shippingFee) {
                        this.orderDetail.directShip = true;
                        this.orderTotalsUpdateResult.shippingFee = this.orderDetail.shippingFee;
                        this.orderTotalsUpdateResult.orderTotal += this.orderDetail.shippingFee;
                    }
                    this.orderContainsDGCSelection = result.orderHasDigitalGoldCard;;
                    this.cdr.detectChanges();
                }
                if (this.checkoutOrderDetails) {
                    this.checkoutOrderDetails._donation = this.orderTotalsUpdateResult.donationTotal;
                }
            }, 
            (error: any) => {},
            () =>  {
                this.isUpdatingTotals = false;
                this.cdr.detectChanges();
            });
    }

    setSelectedProductOrders(): ProductOption[] {
        if (!this.productAndPackageViewModels || this.productAndPackageViewModels.length <= 0) return [];
        let result: ProductOption[] = [];
        this.productAndPackageViewModels?.forEach(pvm => {
            pvm.orderProducts.forEach(op => {
                if (op.quantity >= 1) {
                    let productOption: ProductOption = {
                        productName: pvm.productDetails.name,
                        productOptionName: this.productOptions[op.productOptionId]?.name,
                        price: pvm.productDetails.price,
                        quantity: op.quantity,
                        productId: pvm.productDetails.id,
                        productOptionId: op.productOptionId,
                    };
                    result.push(productOption);
                }
            });
        });
        this.selectedProductOrders = result;
        this.cdr.detectChanges();
    }

    
    goToPaymentInfo(isOpen: boolean, calculateVendorTotals: boolean = false): void {
        if (isOpen && this.IsEmptyOrder()) return;

        this.isPaymentMethodsOpen = isOpen;
        if (isOpen) {
            if (calculateVendorTotals) {
                this.updateTotals(true);
            }
            this.isPaymentMethodsDisabled = false;
            
            // reset the other steps in case they came from a previous step
            this.isReviewOrderDisabled = true;
            this.isReviewOrderOpen = false;
            
        }
    }


    // End Shipping Details Methods

    // Start Payment Method Details Methods

    sendSharedOrder(): void {
        if (!this.checkoutPaymentMethods.customerCCOrderEmailAddress) {
            this.message.error(this.l('{0}Required', this.l('EmailAddress')));
            return;
        }
        if (!this.isValidEmailAddress(this.checkoutPaymentMethods.customerCCOrderEmailAddress)) {
            this.message.error(this.l('Invalid', this.l('EmailAddress')));
            return;
        }

        // make call to service
        this._sharedOrdersServiceProxy.sendSharedOrder(this.fundraiserUserIdInput, this.checkoutPaymentMethods.customerCCOrderEmailAddress, this.shareLink)
            .subscribe(() => {
                this.message.success("", this.l('OrderSent'));
                this._router.navigate(["/app/main/fundraiser", this.fundraiserIdInput]);
            });
    }

    goToReviewOrder(isOpen: boolean): void {
        if (isOpen && this.IsEmptyOrder()) return;

        this.isReviewOrderOpen = isOpen;
        this.checkoutReviewOrder.shippingAddress =  this.useShippingAddress() ? this.orderDetail.shippingAddress : this.orderDetail.customerAddress;
        if(isOpen) {
            this.isReviewOrderDisabled = false;
        }
    }

    useShippingAddress(): boolean {
        return this.checkoutOrderDetails.setShippingAddress && !!this.orderDetail.shippingAddress?.streetLine1;
    }

    IsEmptyOrder(): boolean {
        // If there is no order total, show an error
        if (!((this.checkoutOrderDetails.orderTotal ?? 0) + this.checkoutOrderDetails.tipDollars)) {
            this.message.error(`${this.l('EmptyOrder1')}${!this.isLaunchathon ? ' ' + this.l('EmptyOrder2') : '.'}`);
            return true;
        }
        return false;
    }

    // End Payment Method Details Methods

    save(): void {
        if (this.IsEmptyOrder()) return;

        var confirmMessage = !this.isANewOrder() ? this.l('UpdateOrder') + '?' : this.l('PlaceOrder') + '?';

        this.orderDetail.totalPrice = this.orderTotalsUpdateResult.orderTotal;
        this.orderDetail.shippingCostRequestId = this.shippingCostRequestId;
        let totalPlusTip = this.orderDetail.totalPrice + this.checkoutOrderDetails.tipDollars;
        var totalPriceText = this.l('OrderTotal') + this._currencyPipe.transform(totalPlusTip, '$');
        this.message.confirm(confirmMessage, totalPriceText, (isConfirmed) => {
            if (isConfirmed) {
                this.createOrEditOrder();
            }
        });
    }

    createOrEditOrder(): void {
        this.isProcessingOrder = true;

        this.updatePaymentMethod();

        if (this.orderDetail.creditCardInfo) {
            this.orderDetail.creditCardInfo.expireMonth = this.checkoutPaymentMethods.expires?.split('/')[0];
            this.orderDetail.creditCardInfo.expireYear = this.checkoutPaymentMethods.expires?.split('/')[1];
        }

        if (this.useApplePay) {
            this.orderDetail.isApplePay = true;
            this.orderDetail.applePayPaymentData = JSON.stringify(this.applePaySessionInfo.event.payment.token.paymentData);
        }
        
        this.orderDetail.orderTransactionId = this.newGuid();
        this.orderDetail.fundraiserId = this.fundraiserIdInput;
        this.orderDetail.fundraiserUserId = this.fundraiserUserIdInput;

        this.orderDetail.customDonation = this.checkoutOrderDetails.donationValue;

        this.orderDetail.tipDollars = this.checkoutOrderDetails.tipDollars;
        if (this.checkoutOrderDetails.editOrderTipDollars != null) {
            this.orderDetail.tipPercentage = (this.checkoutOrderDetails.tipDollars / this.checkoutOrderDetails.donationValue) * 100;
        }
        else {
            this.orderDetail.tipPercentage = this.checkoutOrderDetails.tipPercentage;
        }

        this.orderDetail.customerAddress.phoneNumber = this.orderDetail.customerAddress.phoneNumber?.replace('x_', '').replace(/_/g, '').trim();

        this.orderDetail.studentId = this.checkoutOrderDetails.teamMemberId ? this.checkoutOrderDetails.teamMemberId : null;
        this.orderDetail.sellerFirstName = this.checkoutOrderDetails.teamMemberFirstName?.trim();
        this.orderDetail.sellerLastName = this.checkoutOrderDetails.teamMemberLastName?.trim();
        this.orderDetail.path = this.Location.path();

        if (this.orderDetail.customerAddress.emailAddress == '') {
            this.orderDetail.customerAddress.emailAddress = undefined;
        }
        if (!this.checkoutOrderDetails.setShippingAddress && this.orderDetail.shippingAddress) {
            // saving with nothing set for shipping address;
            // in case the shippingAddress fields hold values, clear them out.
            this.orderDetail.shippingAddress = null;
        }
        else if (this.checkoutOrderDetails.setShippingAddress && this.orderDetail.shippingAddress) {
            this.orderDetail.shippingAddress.emailAddress = this.orderDetail.customerAddress.emailAddress;
        }

        this.orderDetail.automatedMessageType = this.automatedMessageType;
        this.orderDetail.isISponsorEnabled = this.isISponsorEnabled;

        this._sharedOrdersServiceProxy.createOrEdit(this.orderDetail)
            .pipe(finalize(() => {
                this.isProcessingOrder = false;
            }))
            .subscribe((result) => {
                if (!this.isANewOrder()) {
                    // user was editing an order
                    this._router.navigate(['/app/main/orders/orderDetails', this.orderDetail.fundraiserId]);
                }
                else {
                    if (this.useApplePay) {
                        this.applePaySessionInfo.session.completePayment(ApplePaySession.STATUS_SUCCESS);
                    }

                    // user was placing an order
                    var orderCompleteInfo: CompleteOrderInfo = {
                        customerEmailAddress: this.orderDetail.customerAddress.emailAddress,
                        orderAmount: this.orderDetail.customDonation,
                        orderTransactionId: result.item1, //orderDetailId
                        isponsorInformation: result.item2 //iSponsorDto
                    }
                    this.resetUi();
                    this.orderComplete.emit(orderCompleteInfo);
                    orderCompleteInfo.isponsorInformation = null;
                }
            }, (error: any) => {
                if (this.useApplePay) {
                    this.applePaySessionInfo.session.completePayment(ApplePaySession.STATUS_FAILURE);
                }
            });
    }

    saveWithApplePay(event: ApplePayEventInfo): void {
        if (this.IsEmptyOrder()) return;
        
        this.useApplePay = true;
        this.applePaySessionInfo = event;
        this.orderDetail.totalPrice = this.orderTotalsUpdateResult.orderTotal;
        this.orderDetail.shippingCostRequestId = this.shippingCostRequestId;
        this.createOrEditOrder();
    }

    updatePaymentMethodEmitter(paymentMethodId: number): void {
        this.orderDetail.paymentMethodId = paymentMethodId;
    }

    updatePaymentMethod(): void {
        // if student is placing the order keep the form values
        // if guest is checking out update the payment settings based on payment mode
        if (this.isGuest && this.isANewOrder()) {
            // always set paid to false
            // the integrated credit card processing will mark it paid when it completes
            // for other payment methods the student will edit the order and mark paid later
            this.orderDetail.paid = false;

            // set the payment method
            if (this.guestPayMode == this.PAY_INPERSON) {
                var cashPm = this.paymentMethods.find(x => x.abbreviation === 'ca');
                if (cashPm) this.orderDetail.paymentMethodId = cashPm.id;
            }
        }
    }

    resetUi() {
        this.orderDetail.customDonation = 0;
        this.orderDetail.tipPercentage = 0;
        this.orderDetail.tipDollars = 0;
    }

    cancel() {
        this.message.confirm('', this.l('AreYouSure'), (isConfirmed) => {
            if (isConfirmed) {
                this.cancelOrder.emit(null);
            }
        });
    }
    
    continueShopping(): void {
        if (this.isLaunchathon && this.isANewOrder()) {
            this.cancel();
        }
        else {
            let orderDetailPlusProductViewModels: OrderDetailPlusProductAndPackageViewModels = new OrderDetailPlusProductAndPackageViewModels();
            orderDetailPlusProductViewModels.productAndPackageViewModels = this.productAndPackageViewModels;
            orderDetailPlusProductViewModels.orderDetail = this.orderDetail;
            this.goBack.emit(orderDetailPlusProductViewModels);
        }
    }
}

export class ProductOption {
    productName: string;
    productOptionName: string;
    price: number;
    quantity: number;
    productId: number;
    productOptionId: number;
}

export interface CompleteOrderInfo {
    customerEmailAddress: string;
    orderAmount: number;
    orderTransactionId: string;
    isponsorInformation?: CreateISponsorAccountDto;
}

export class OrderDetailPlusProductAndPackageViewModels {
    productAndPackageViewModels: ProductOrPackageViewModel[];
    orderDetail: CreateOrEditOrderDetailDto;
}