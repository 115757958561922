import { NgModule } from '@angular/core';
import * as ngCommon from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppCommonModule } from '@app/shared/common/app-common.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { RouterModule } from '@angular/router';
import { RecaptchaModule } from 'ng-recaptcha';
import { OrderOptionsComponent } from './order-components/order-options.component';
import { DropdownModule } from 'primeng/dropdown';
import { FundraiserDirectoryComponent } from '../fundraisers/fundraiser-directory/fundraiser-directory.component';
import { DonationsPageComponent } from './donations-page/donations-page.component';
import { InputMaskModule } from 'primeng/inputmask';

@NgModule({
    declarations: [
        OrderOptionsComponent,
        FundraiserDirectoryComponent,
        DonationsPageComponent
    ],
    imports: [
        ngCommon.CommonModule,
        FormsModule,
        UtilsModule,
        RouterModule,
        //RecaptchaModule.forRoot(),
        AppCommonModule,
        DropdownModule,
        InputMaskModule
    ],
    exports: [
        DonationsPageComponent
    ]
})

export class OrderModule { }