

<img src="/assets/common/images/help_tape.png" width="300" />
<div class="brush-stroke help-me-text">
    {{l('AboutTheTeam')}}
</div>
<div class="w-100 d-flex justify-content-center">
    <pre class="about-team-text px-10 px-sm-20">{{aboutTheTeam}}</pre>
</div>
<div class="video-wrap h-100" *ngIf="aboutTheTeamVideo && aboutTheTeamVideo.length > 0">
    <div class="h-100" [innerHTML]="aboutTheTeamVideo | safe: 'html'"></div>
</div>