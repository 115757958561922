

<div *ngFor="let order of orders" class="row mx-0 w-100">
    <div class="col-2 col-md-1 d-flex align-items-center justify-content-center pb-3 px-0">
        <div class="ranking-box" [ngStyle]="{'background-color': order.colorCode}">
            {{order.customerInitials}}
        </div>
    </div>
    <div class="col-6 col-md-8 d-flex flex-column align-items-start justify-content-start pb-3 px-3 donation-names my-auto">
        <div>
            <div class="order-customer-name">{{order.customerName}}</div>
            <div>({{order.studentName}})</div>
        </div>
        <div class="order-message">{{order.messageText ? '"' + order.messageText + '"' : ''}}</div>
    </div>
    <div class="col-4 col-md-3 d-flex flex-column align-items-center justify-content-center pb-3 px-3">
        <div class="order-amount">{{order.orderAmount  | currency : 'USD'}}</div>
    </div>
</div>