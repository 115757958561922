import { PermissionCheckerService, FeatureCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';
import { SettingService } from 'abp-ng2-module';
import { AppConsts } from '@shared/AppConsts';

@Injectable()
export class AppNavigationService {
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _featureCheckerService: FeatureCheckerService,
        private _appSessionService: AppSessionService,
        private _settingsService: SettingService
    ) { }

    getMenu(): AppMenu {
        if(this._permissionCheckerService.isGranted('Pages.Tenant.Parent') 
            && !this._permissionCheckerService.isGranted('Pages.Administration.Tenant.Settings'))
        {
            return new AppMenu('MainMenu', 'MainMenu', [
                new AppMenuItem('Dashboard', 'Pages.Tenant.Parent', 'svg-icon-dashboard-alt', '/app/main/parentFundraisers'),
                new AppMenuItem('Orders', 'Pages.Tenant.Parent', 'svg-icon-credit-card', '/app/main/orders/orderDetails'),
                new AppMenuItem('Participants', 'Pages.Tenant.Parent', 'svg-icon-users', '/app/admin/users'),
            ]);
        }

        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem(
                'Dashboard',
                'Pages.Administration.Host.Dashboard',
                'svg-icon-dashboard-alt',
                '/app/admin/hostDashboard'
            ),
            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'svg-icon-dashboard-alt', '/app/main/dashboard'),            
            new AppMenuItem('Documents', 'Pages.Fundraisers.List', 'svg-icon-documents', this.getRepDocumentUrl(), undefined, undefined, true),
            new AppMenuItem('Help', 'Pages.Fundraisers.List', 'svg-icon-help', this.getRepYouTubeUrl(), undefined, undefined, true),
            new AppMenuItem('LAUNCHBACK Office', AppConsts.launchbackLinkPermissionName, 'svg-icon-webhooks', 'https://repoffice.launchfundraising.com/', undefined, undefined, true),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'svg-icon-tenants', '/app/admin/tenants'),
            new AppMenuItem('Invoicing', 'Pages.Tenants.Invoicing', 'svg-icon-invoice', '/app/admin/invoicing'),
            new AppMenuItem('Organizations', 'Pages.Organizations', 'svg-icon-organizations', '/app/main/organizations/organizations'),
            new AppMenuItem('Editions', 'Pages.Editions', 'svg-icon-editions', '/app/admin/editions'),
            new AppMenuItem('Fundraisers', 'Pages.Fundraisers.List', 'svg-icon-fundraisers', '/app/main/fundraisers/fundraisers'),
            new AppMenuItem('Fundraiser', 'Pages.Fundraiser.Dashboard', 'svg-icon-fundraiser', '/app/main/fundraiser'),
            new AppMenuItem('Contacts', AppConsts.studentContactPermissionName, 'svg-icon-contacts', '/app/main/studentContacts'),
            new AppMenuItem('FundraiserTemplates', 'Pages.Fundraisers.Templates.Manage', 'svg-icon-fundraiser-template', '/app/main/fundraisers/fundraisertemplates'),
            new AppMenuItem('Orders', 'Pages.Tenant.CreditCardReport', 'svg-icon-credit-card', '/app/main/orders/orders'),

            new AppMenuItem('CRMFeature', 'Pages.Fundraisers.Edit', 'svg-icon-crm', '', [], [
                new AppMenuItem('Contacts', 'Pages.Fundraisers.Edit', 'svg-icon-contacts', '/app/main/crm/contacts'),
                new AppMenuItem('Metrics', 'Pages.Fundraisers.Edit', 'svg-icon-metrics', '/app/main/crm/rep-metrics'),
                new AppMenuItem('FundraiserAgreements', 'Pages.Fundraisers.Edit', 'svg-icon-fa', '/app/main/crm/agreements'),
                new AppMenuItem('SalesWorkFlow', 'Pages.Fundraisers.Edit', 'svg-icon-kanban', '/app/main/crm/salesWorkflow'),
                new AppMenuItem('FundraiserAgreementContent', 'Pages.Fundraiser.Agreement.Content.Edit', 'svg-icon-fa-content', '/app/main/crm/agreementContent'),
                new AppMenuItem('SplitSummaries', 'Pages.Fundraiser.Agreement.Content.Edit', 'svg-icon-split-summary', '/app/main/crm/splitSummaries'),
            ], false, undefined, () => { return this._featureCheckerService.isEnabled('App.CRMFeature'); }),

            new AppMenuItem('CRMFeature', 'Pages.Administration.Host.Settings', 'svg-icon-crm', '', [], [
                new AppMenuItem('FundraiserAgreementContent', 'Pages.Fundraiser.Agreement.Content.Edit', 'svg-icon-fa-content', '/app/main/crm/agreementContent')
            ]),
            new AppMenuItem(
                'Administration',
                '',
                'svg-icon-administration',
                '',
                [],
                [
                    new AppMenuItem(
                        'OrganizationUnits',
                        'Pages.Administration.OrganizationUnits',
                        'svg-icon-organization-units',
                        '/app/admin/organization-units'
                    ),
                    new AppMenuItem(
                        'Roles', 
                        'Pages.Administration.Roles', 
                        'svg-icon-roles', 
                        '/app/admin/roles'
                    ),
                    new AppMenuItem(
                        'Users', 
                        'Pages.Administration.Users', 
                        'svg-icon-users', 
                        '/app/admin/users'
                    ),
                    new AppMenuItem(
                        'Vendors', 
                        'Pages.Administration.Vendors.Edit', 
                        'svg-icon-vendors', 
                        '/app/admin/launch/vendors'
                    ),            
                    new AppMenuItem(
                        'Lists', 
                        'Pages.Administration.Lists', 
                        'svg-icon-lists', 
                        '/app/admin/lists'
                    ),
                    new AppMenuItem(
                        'CustomReports',
                        'Pages.Administration.CustomReports',
                        'svg-icon-data-files',
                        '/app/admin/custom-reports'
                    ),
                    new AppMenuItem(
                        'ProductGroups', 
                        'Pages.Administration.ProductGroups', 
                        'svg-icon-product-groups', 
                        '/app/admin/products/productGroups'
                    ),
                    new AppMenuItem(
                        'Languages',
                        'Pages.Administration.Languages',
                        'svg-icon-languages',
                        '/app/admin/languages',
                        ['/app/admin/languages/{name}/texts']
                    ),
                    new AppMenuItem(
                        'AuditLogs',
                        'Pages.Administration.AuditLogs',
                        'svg-icon-audit-logs',
                        '/app/admin/auditLogs'
                    ),
                    new AppMenuItem(
                        'Maintenance',
                        'Pages.Administration.Host.Maintenance',
                        'svg-icon-maintenance',
                        '/app/admin/maintenance'
                    ),
                    new AppMenuItem(
                        'Subscription',
                        'Pages.Administration.Tenant.SubscriptionManagement',
                        'svg-icon-subscription',
                        '/app/admin/subscription-management'
                    ),
                    new AppMenuItem(
                        'VisualSettings',
                        'Pages.Administration.UiCustomization',
                        'svg-icon-visual-settings',
                        '/app/admin/ui-customization'
                    ),
                    new AppMenuItem(
                        'WebhookSubscriptions',
                        'Pages.Administration.WebhookSubscription',
                        'svg-icon-webhooks',
                        '/app/admin/webhook-subscriptions'
                    ),
                    new AppMenuItem(
                        'DynamicProperties',
                        'Pages.Administration.DynamicProperties',
                        'svg-icon-dynamic-properties',
                        '/app/admin/dynamic-property'
                    ),
                    new AppMenuItem(
                        'Settings',
                        'Pages.Administration.Host.Settings',
                        'svg-icon-settings',
                        '/app/admin/hostSettings'
                    ),
                    new AppMenuItem(
                        'Settings',
                        'Pages.Administration.Tenant.Settings',
                        'svg-icon-settings',
                        '/app/admin/tenantSettings'
                    ),
                ]
            ),
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }
        return false;
    }

    showMenuItem(menuItem: AppMenuItem, isStudentContactEnabled?: boolean): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        if (menuItem.permissionName == AppConsts.studentContactPermissionName 
                && this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            return isStudentContactEnabled;
        }
        
        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach((menuItem) => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach((subMenu) => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }

    getRepYouTubeUrl(): string {
        var url = this._settingsService.get('App.HostManagement.Training.RepYouTubeUrl');
        return url;
    }

    getRepDocumentUrl(): string {
        var url = this._settingsService.get('App.HostManagement.Training.RepDocumentUrl');
        return url;
    }
}
