<!-- NOTE: Check if changes made here affect src/app/main/launch/fundraisers/student-contacts/student-contacts.component.html
If so, make sure to make the changes in both places
Code duplication is sometimes a necessary evil for security :( -->

<div [@routerTransition]>
    <div class="content d-flex flex-column flex-column-fluid" [hidden]="loading">
        <sub-header [title]="headerTitle">
            <div role="actions" class="d-none d-lg-block">
                <button type="button" (click)="createNewContact()" class="btn btn-primary">
                    <i class="fa fa-plus"></i>
                    {{l("CreateNewContact")}}
                </button>
            </div>
        </sub-header>
        <div class="container-fluid mt-5">
            <div class="card container-fluid px-2">
                <div class="card-body px-2 py-5">
                    <div class="row d-flex justify-content-center mb-3 d-lg-none gap-2"> 
                        <div class="col-auto">
                            <button type="button" (click)="createNewContact()" class="btn btn-lg btn-primary px-4 py-2">
                                <i class="fa fa-plus"></i>
                                {{l("CreateNewContact")}}
                            </button>
                        </div>
                    </div>
                    <ng-container *ngIf="studentContacts.length === 0; else studentContactsSection">
                        <div class="d-flex justify-content-center justify-content-lg-start ms-lg-10">
                            <p>{{l('NoContactsFound')}}</p>
                        </div>
                    </ng-container>
                    <ng-template #studentContactsSection>
                        <!-- Mobile Card section -->
                        <div class="row px-1 d-flex justify-content-center d-lg-none mb-3" *ngFor="let contact of studentContacts">
                            <div class="card border border-secondary py-3 px-2">
                                <div class="card-body d-flex justify-content-center p-0 w-100">
                                    <div class="row w-100">
                                        <div class="col-6 ps-3 pe-0">
                                            <div>
                                                <h2>{{contact.firstName}} {{contact.lastName}} </h2>
                                            </div>
                                            <div class="ps-3 mb-2">
                                                <span class="d-flex">
                                                    <i class="fa fa-lg fs-2 fa-user-circle text-dark d-flex align-items-center"></i>
                                                    <h5 class="ps-3 fw-normal m-0">{{contact.relationship}}</h5>
                                                </span>
                                            </div>
                                            <div class="ps-3">
                                                <span class="d-flex">
                                                    <i class="fa fa-lg fs-2 fa-phone-alt text-dark d-flex align-items-center"></i>
                                                    <h5 class="ps-3 fw-normal m-0">{{contact.phone | phone}}</h5>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col d-flex flex-column p-0">
                                            <div class="d-flex justify-content-end align-items-start pe-2">
                                                <button type="button" class="btn btn-icon d-flex align-items-start justify-content-end pt-1" (click)="editContact(contact.studentContactId)" title="Edit">
                                                    <span class="fa fa-edit color-primary fa-lg"></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Mobile Section -->
                        <!-- Desktop Table section -->
                        <div class="row d-none d-lg-flex align-items-center">
                            <!--<Primeng-Datatable-Start>-->
                        <div class="primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
                            <p-table #dataTable [value]="studentContacts" [paginator]="false" [scrollable]="true" ScrollWidth="100%">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="flex: 0 0 8%">
                                            
                                        </th>
                                        <th style="flex: 0 0 17%" pSortableColumn="firstName">
                                            {{l('FirstName')}}
                                            <p-sortIcon field="firstName"></p-sortIcon>
                                        </th>
                                        <th style="flex: 0 0 17%" pSortableColumn="lastName">
                                            {{l('LastName')}}
                                            <p-sortIcon field="lastName"></p-sortIcon>
                                        </th>
                                        <th style="flex: 0 0 17%">
                                            {{l('Relationship')}}
                                        </th>
                                        <th style="flex: 0 0 17%">
                                            {{l('Phone')}}
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-record="$implicit">
                                    <tr>
                                        <td style="flex: 0 0 8%">
                                            <button type="button" class="btn btn-primary btn-sm d-flex align-items-center" (click)="editContact(record.studentContactId)" title="Edit">
                                                <i class="fa fa-edit"></i>
                                                Edit
                                            </button>
                                        </td>
                                        <td style="flex: 0 0 17%">
                                            {{record.firstName}}
                                        </td>
                                        <td style="flex: 0 0 17%">
                                            {{record.lastName}}
                                        </td>
                                        <td style="flex: 0 0 17%">
                                            {{record.relationship}}
                                        </td>
                                        <td style="flex: 0 0 17%">
                                            {{record.phone | phone}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                        <!--<Primeng-Datatable-End>-->
                        </div>
                        <!-- End Desktop Table Section-->
                    </ng-template>
                    <!-- Previous Supporters table-->
                    <div class="row align-items-center mt-4">
                        <div class="d-flex justify-content-center justify-content-lg-start">
                            <h2 class="mb-5 ms-lg-4">{{l('PreviousSupporters')}}</h2>
                        </div>
                        <div class="d-flex justify-content-center justify-content-lg-start ms-lg-10" *ngIf="previousSupporters.length <= 0">
                            <p>{{l('NoPreviousSupporters')}}</p>
                        </div>
                        <div class="primeng-datatable-container" *ngIf="previousSupporters.length > 0" [busyIf]="previousSupportersTableLoading">
                            <p-table #dataTable [value]="previousSupporters" [paginator]="false" [scrollable]="true" ScrollWidth="100%">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="flex: 0 0 125px">
                                            <button *ngIf="showImportAll && hasSupporterWithPhone" type="button" class="btn btn-primary btn-sm px-4 py-2 d-flex align-items-center"
                                                    [buttonBusy]="isImporting" [busyText]="l('ImportingWithThreeDots')" [disabled]="isImporting"
                                                    (click)="importAllContacts()">
                                                <i class="fa fa-plus"></i>
                                                {{l('ImportAll')}}
                                            </button>
                                        </th>
                                        <th style="flex: 0 0 150px" pSortableColumn="firstName">
                                            {{l('FirstName')}}
                                            <p-sortIcon field="firstName"></p-sortIcon>
                                        </th>
                                        <th style="flex: 0 0 200px" pSortableColumn="lastName">
                                            {{l('LastName')}}
                                            <p-sortIcon field="lastName"></p-sortIcon>
                                        </th>
                                        <th style="flex: 0 0 200px">
                                            {{l('Phone')}}
                                        </th>
                                        <th style="flex: 0 0 350px">
                                            {{l('Email')}}
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData>
                                    <tr>
                                        <td style="flex: 0 0 1px">
                                            <button *ngIf="!rowData.hideImportButton" type="button" class="btn btn-primary btn-sm px-4 py-2 d-flex align-items-center"
                                                    (click)="importStudentContact(rowData)">
                                                <i class="fa fa-plus"></i>
                                                {{l('Import')}}
                                            </button>
                                        </td>
                                        <td style="flex: 0 0 150px">
                                            {{rowData.firstName}}
                                        </td>
                                        <td style="flex: 0 0 200px">
                                            {{rowData.lastName}}
                                        </td>
                                        <td style="flex: 0 0 200px">
                                            {{rowData.phone | phone}}
                                        </td>
                                        <td style="flex: 0 0 350px">
                                            {{rowData.email}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <!-- Previous Supporters Table End -->
                </div>
            </div>
        </div>
    </div>
</div>

<parentCreateEditStudentContactModal #parentCreateEditStudentContactModal [studentId]="studentId" [guid]="guid" (modalSave)="afterContactSaved()"></parentCreateEditStudentContactModal>