import { Pipe } from "@angular/core";

@Pipe({
    name: "phone"
})
export class PhoneFormatPipe {
    transform(phoneNumber: string) {
        if (!phoneNumber) {
            return '';
        }
        let areaCode = phoneNumber.substr(0, 3);
        let prefix = phoneNumber.substr(3, 3);
        let suffix = phoneNumber.substr(6, 4);

        let extValue = phoneNumber.substr(10, 99);
        let ext = extValue ? ' ' + extValue : '';

        return '(' + areaCode + ') ' + prefix + '-' + suffix + ext;
    }
}