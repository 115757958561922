import { Component, OnInit, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ActivatedRoute } from '@angular/router';
import { FundraiserDirectoryServiceProxy, FundraiserDirectoryOutput, FundraiserInfoDto } from '@shared/service-proxies/service-proxies';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-fundraiser-directory',
  templateUrl: './fundraiser-directory.component.html',
  styleUrls: ['./fundraiser-directory.component.less'],
  animations: [appModuleAnimation()]
})
export class FundraiserDirectoryComponent extends AppComponentBase implements OnInit {
  loading = true;
  found = false;
  content: FundraiserDirectoryOutput;
  searchedFundraisers: FundraiserInfoDto[];
  filter: string;

  constructor(injector: Injector,
    private _activatedRoute: ActivatedRoute,
    private _fundraiserDirectoryService: FundraiserDirectoryServiceProxy) {
    super(injector);
  }

  ngOnInit() {
    this._activatedRoute.params.subscribe(params => {
      this.loadFundraisers(params.groupName);
    });
  }

  loadFundraisers(groupName: string) {
    this.loading = true;
    let searchName: string;
    if (groupName && groupName.trim().length > 0)
      searchName = groupName.trim();
    this._fundraiserDirectoryService.getFundraisers(searchName)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(result => {
        this.content = result;
        this.found = this.content && this.content.isFound;
        this.resetSearch();
      });
  }

  resetSearch() {
    this.searchedFundraisers = undefined;
  }

  searchFundraisers() {
    if (!this.filter || this.filter.length < 2) {
      this.resetSearch();
      return;
    }
    let lfilter = this.filter.toLowerCase();

    this.searchedFundraisers = this.content.fundraisers.filter(x => x.name.toLowerCase().indexOf(lfilter) >= 0 || x.organization.toLowerCase().indexOf(lfilter) >= 0);
  }

  showAll() {
    this.filter = undefined;
    this.searchedFundraisers = this.content.fundraisers;
  }
}
