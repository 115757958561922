import { Component, Input } from "@angular/core";
import { AppComponentBase } from "../../../../shared/common/app-component-base";

@Component({
    selector: 'about-the-team',
    templateUrl: './about-the-team.component.html',
    styleUrls: ['./about-the-team.component.less']
})

export class AboutTheTeamComponent extends AppComponentBase {
    @Input() aboutTheTeam: string;
    @Input() aboutTheTeamVideo: string;
}