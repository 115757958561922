<div [@routerTransition] class="container h-100" class="countdown-text">
    <div class="row h-100">
        <div class="my-auto">
            <div [hidden]="!showTimer || isFundraiserOver">
                <div *ngIf="countdownStatus != 'ended'" class="countdown-text">
                    COUNTDOWN TIMER
                </div>
                <div *ngIf="countdownStatus == 'ended'" class="countdown-status">{{daysLeft}} {{dayVsDays}} and</div>
                <countdown #cd [config]="config" (event)="handleEvent($event)"></countdown>
                <div *ngIf="countdownStatus == 'ended'" class="countdown-status">left in fundraiser</div>
            </div>

            <div [hidden]="!isFundraiserOver">
                <div class='fundraiser-ended'>
                    Fundraiser has ended
                </div>
            </div>

            <div [hidden]="showTimer || isFundraiserOver">
                <div class='coming-soon'>Coming Soon!</div>
            </div>

            <button *ngIf="showCountdownButton" class="timer-toggle-button timer-toggle-button-{{buttonText}} mt-3"
                    (click)="toggleTimer()">
                {{buttonText}}
            </button>
            <button *ngIf="showResetButton" class="timer-toggle-button bg-danger mt-3" (click)="resetHourathon()">
                Reset
            </button>
        </div>
    </div>
</div>