import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { GetStudentContactForViewDto, ParentStudentContactsServiceProxy, PreviousSupportersDto } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConsts } from '@shared/AppConsts';
import { ParentCreateEditStudentContactModalComponent } from './parent-create-or-edit-student-contact-modal/parent-create-or-edit-student-contact-modal.component';

@Component({
    selector: 'app-parent-student-contacts',
    templateUrl: './parent-student-contacts.component.html',
    styleUrls: ['./parent-student-contacts.component.less'],
    animations: [appModuleAnimation()]
})
export class ParentStudentContactsComponent extends AppComponentBase implements OnInit {

// NOTE: Check if changes made here affect src/app/main/launch/fundraisers/student-contacts/student-contacts.component.ts
// If so, make sure to make the changes in both places
// Code duplication is sometimes a necessary evil for security :( 

    @ViewChild('parentCreateEditStudentContactModal', { static: true }) parentCreateEditStudentContactModal: ParentCreateEditStudentContactModalComponent;
    
    guid: string;
    studentId: number;
    studentName: string;

    loading = true;
    studentContacts: GetStudentContactForViewDto[] = [];

    previousSupportersTableLoading = true;
    previousSupporters: PreviousSupportersDto[] = [];

    constructor(
        injector: Injector,
        private _parentStudentContactsService: ParentStudentContactsServiceProxy,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this._activatedRoute.queryParams.subscribe(queryParams => {
            if ((!queryParams.guid || queryParams.guid == '')) {
                this.navigateAway();
                return;
            }
            this.guid = queryParams.guid;
            this.getContacts();
            this.getPreviousSupporters();
        });
    }

    get headerTitle(): string {
        return `${this.l('StudentContacts')} (${this.studentContacts.length}${this.studentContacts.length < AppConsts.studentContactMinimumAmount ? ` of ${AppConsts.studentContactMinimumAmount})` : ')'} for ${this.studentName}`; 
    }

    createNewContact(): void {
        this.parentCreateEditStudentContactModal.show();
    }

    editContact(studentContactId: number): void {
        this.parentCreateEditStudentContactModal.show(studentContactId);
    }

    afterContactSaved(): void {
        this.getContacts();
        this.getPreviousSupporters();
    }

    getContacts() {
        this.primengTableHelper.showLoadingIndicator();

        this._parentStudentContactsService.getAll(this.guid)
            .pipe(finalize(() => {
                this.loading = false;
                this.primengTableHelper.hideLoadingIndicator();
            }))
            .subscribe(result => {
                if (result) {
                    this.studentId = result.studentId;
                    this.studentName = result.studentName;
                    this.studentContacts = result.studentContacts;
                } else {
                    this.navigateAway();
                }
        }, () => this.navigateAway());
    }

    getPreviousSupporters() {
       this.previousSupportersTableLoading = true;

       this._parentStudentContactsService.getPreviousSupporters(this.guid)
            .pipe(finalize(() => {
                this.previousSupportersTableLoading = false;
            }))
            .subscribe(result => {
                if(result) {
                    this.previousSupporters = result;
                } else {
                    this.navigateAway();
                }
        }, () => this.navigateAway());
    }

    get showImportAll(): boolean {
        return this.previousSupporters.findIndex(ps => !ps.hideImportButton) > -1;
    }

    importStudentContact(rowData: PreviousSupportersDto) {
        this.parentCreateEditStudentContactModal.studentContact.firstName = rowData.firstName;
        this.parentCreateEditStudentContactModal.studentContact.lastName = rowData.lastName;
        this.parentCreateEditStudentContactModal.studentContact.emailAddress = rowData.email;
        this.parentCreateEditStudentContactModal.studentContact.phoneNumber = rowData.phone;
        // marking as touched so it shows the user what still needs to be filled out
        this.parentCreateEditStudentContactModal.studentContactForm.controls['relationship'].markAsTouched();
        this.parentCreateEditStudentContactModal.show();
    }

    isImporting = false;
    get hasSupporterWithNoPhone() {
        return this.previousSupporters.findIndex(s => !s.phone) > -1;
    }
    get hasSupporterWithPhone() {
        return this.previousSupporters.findIndex(s => s.phone) > -1;
    }
    importAllContacts() {
        let text = this.l('YouAreImportingContacts');
        if (this.hasSupporterWithNoPhone) {
            text += " " + this.l('ContactsWithoutPhoneWontBeImported');
        }
        this.message.confirm(
            text,
            this.l('Import All Supporters'),
            (isConfirmed) => {
                if (isConfirmed) {
                    this.isImporting = true;
                    this._parentStudentContactsService.importAll(this.guid).subscribe(result => {
                        this.notify.info(this.l('ImportedContacts'));
                        this.isImporting = false;

                        this.getContacts();
                        this.getPreviousSupporters();
                    },
                        () => this.isImporting = false);
                }
            }
            ,
            {
                confirmButtonText: this.l('Import'),
                cancelButtonText: this.l('Cancel')
            }
        );
    }

    navigateAway(): void {
        this._router.navigate(['app', 'main', 'fundraisers', 'fundraisers']);
    }
}