import { Component, Injector, Input } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { GetFundraiserForLiveLink, TopSeller, TopTeam } from '../../../../../../shared/service-proxies/service-proxies';
import {
    trigger,
    state,
    style,
    animate,
    transition
} from '@angular/animations';

@Component({
    selector: 'live-link-totals',
    templateUrl: './live-link-totals.component.html',
    styleUrls: ['./live-link-totals.component.less'],
    animations: [
        trigger('shiftDown', [
            state('false', style({ transform: 'translateY(0px)' })),
            state('true', style({ transform: 'translateY(65px)' })),
            transition('false => true', [
                animate(2000)
            ])
        ]),
        trigger('shiftUpOne', [
            state('false', style({ transform: 'translateY(0px)' })),
            state('true', style({ transform: 'translateY(-65px)' })),
            transition('false => true', [
                animate(2000)
            ])
        ]),
        trigger('shiftUpTwo', [
            state('false', style({ transform: 'translateY(0px)' })),
            state('true', style({ transform: 'translateY(-110px)' })),
            transition('false => true', [
                animate(2000)
            ])
        ]),
        trigger('shiftUpThree', [
            state('false', style({ transform: 'translateY(0px)' })),
            state('true', style({ transform: 'translateY(-175px)' })),
            transition('false => true', [
                animate(2000)
            ])
        ]),
        trigger('shiftUpFour', [
            state('false', style({ transform: 'translateY(0px)' })),
            state('true', style({ transform: 'translateY(-240px)' })),
            transition('false => true', [
                animate(2000)
            ])
        ]),

        trigger('shiftFiveToSixTrigger', [
            state('false', style({ transform: 'translateY(0px) translateX(0px)' })),
            state('true', style({ transform: 'translateY(-335px) translateX(240px)' })),
            transition('false => true', [
                animate(2000)
            ])
        ]),

        trigger('shiftLeftUpFive', [
            state('false', style({ transform: 'translateX(0px) translateY(0px)' })),
            state('true', style({ transform: 'translateX(-240px) translateY(-335px)' })),
            transition('false => true', [
                animate(2000)
            ])
        ]),
        trigger('shiftLeftUpFour', [
            state('false', style({ transform: 'translateX(0px) translateY(0px)' })),
            state('true', style({ transform: 'translateX(-240px) translateY(-270px)' })),
            transition('false => true', [
                animate(2000)
            ])
        ]),
        trigger('shiftLeftUpThree', [
            state('false', style({ transform: 'translateX(0px) translateY(0px)' })),
            state('true', style({ transform: 'translateX(-240px) translateY(-205px)' })),
            transition('false => true', [
                animate(2000)
            ])
        ]),
        trigger('shiftLeftUpTwo', [
            state('false', style({ transform: 'translateX(0px) translateY(0px)' })),
            state('true', style({ transform: 'translateX(-240px) translateY(-140px)' })),
            transition('false => true', [
                animate(2000)
            ])
        ]),
        trigger('shiftLeftUpOne', [
            state('false', style({ transform: 'translateX(0px) translateY(0px)' })),
            state('true', style({ transform: 'translateX(-240px) translateY(-75px)' })),
            transition('false => true', [
                animate(2000)
            ])
        ]),
        trigger('shiftLeftStraight', [
            state('false', style({ transform: 'translateX(0px)' })),
            state('true', style({ transform: 'translateX(-240px)' })),
            transition('false => true', [
                animate(2000)
            ])
        ]),
        trigger('shiftLeftDownOne', [
            state('false', style({ transform: 'translateX(0px) translateY(0px)' })),
            state('true', style({ transform: 'translateX(-240px) translateY(65px)' })),
            transition('false => true', [
                animate(2000)
            ])
        ]),
        trigger('shiftLeftDownTwo', [
            state('false', style({ transform: 'translateX(0px) translateY(0px)' })),
            state('true', style({ transform: 'translateX(-240px) translateY(130px)' })),
            transition('false => true', [
                animate(2000)
            ])
        ]),
        trigger('shiftLeftDownThree', [
            state('false', style({ transform: 'translateX(0px) translateY(0px)' })),
            state('true', style({ transform: 'translateX(-240px) translateY(195px)' })),
            transition('false => true', [
                animate(2000)
            ])
        ]),
        trigger('shiftLeftDownFour', [
            state('false', style({ transform: 'translateX(0px) translateY(0px)' })),
            state('true', style({ transform: 'translateX(-240px) translateY(260px)' })),
            transition('false => true', [
                animate(2000)
            ])
        ])

    ]
})

export class LiveLinkTotalsComponent extends AppComponentBase {
    isFirstLoad: boolean = true;

    totalRaisedUnformatted: number;
    isMetricDollars: boolean;

    // teams
    topTeams: TopTeam[];
    incomingTopTeams: TopTeam[];
    teamsAbove: TopTeam[];
    teamsToMoveDown = undefined;
    teamToMoveUp = undefined;
    teamsBelow: TopTeam[];

    isSellersSortedByDollars: boolean = true; // else sorted by transactions count

    topSellersCol1: TopSeller[];
    topSellersCol2: TopSeller[];
    incomingTopSellersCol1: TopSeller[];
    incomingTopSellersCol2: TopSeller[];
    sellersCol1Above: TopSeller[];
    sellersCol2Above: TopSeller[];
    sellersCol1ToMoveDown = undefined;
    sellersCol2ToMoveDown = undefined;
    sellersCol1ToMoveUp = undefined;
    sellersCol2ToMoveUp = undefined;
    sellersCol1Below: TopSeller[];
    sellersCol2Below: TopSeller[];
    sellerCol2ToMoveDiagonally: TopSeller;

    isShiftLeftUpFive: boolean = false;
    isShiftLeftUpFour: boolean = false;
    isShiftLeftUpThree: boolean = false;
    isShiftLeftUpTwo: boolean = false;
    isShiftLeftUpOne: boolean = false;
    isShiftLeftStraight: boolean = false;
    isShiftLeftDownOne: boolean = false;
    isShiftLeftDownTwo: boolean = false;
    isShiftLeftDownThree: boolean = false;
    isShiftLeftDownFour: boolean = false;
    hideSellerCol2ToMoveDiagonally: boolean = false;

    // separate trigger shiftFiveToSixTrigger, so we can change it after a timeout,
    // independent of shiftFiveToSix calculation time.
    shiftFiveToSixTriggerBoolean: boolean = false;
    shiftFiveToSix: boolean = false;
    sellerShiftFiveToSix: TopSeller;

    moverStart = 7;
    moverEnd = 3;
    isTopSellersReset: boolean = true;

    isShiftDown: boolean = false;
    isShiftOne: boolean = false;
    isShiftTwo: boolean = false;
    isShiftThree: boolean = false;
    isShiftFour: boolean = false;
    rankingChange: number = 0;

    
    isShiftDownSellersCol1: boolean = false;
    isShiftDownSellersCol2: boolean = false;

    incomingTopSellers: TopSeller[];
    topSellers: TopSeller[];

    moverOldRank: number;
    moverNewRank: number;
    mover: TopSeller;

    isTestingMoveSeller = false;

    reset() {
        this.topSellersCol1 = [];
        this.topSellersCol2 = [];

        this.sellersCol1Above = [];
        this.sellersCol2Above = [];

        this.sellersCol1ToMoveDown = undefined;
        this.sellersCol2ToMoveDown = undefined;

        this.sellersCol1ToMoveUp = undefined;
        this.sellersCol2ToMoveUp = undefined;

        this.sellersCol1Below = [];
        this.sellersCol2Below = [];

        this.sellerCol2ToMoveDiagonally = undefined;
        this.mover = undefined;

        this.isShiftLeftUpFive = false;
        this.isShiftLeftUpFour = false;
        this.isShiftLeftUpThree = false;
        this.isShiftLeftUpTwo = false;
        this.isShiftLeftUpOne = false;
        this.isShiftLeftStraight = false;
        this.isShiftLeftDownOne = false;
        this.isShiftLeftDownTwo = false;
        this.isShiftLeftDownThree = false;
        this.isShiftLeftDownFour = false;
        this.hideSellerCol2ToMoveDiagonally = false;

        // separate trigger shiftFiveToSixTrigger, so we can change it after a timeout,
        // independent of shiftFiveToSix calculation time.
        this.shiftFiveToSixTriggerBoolean = false;
        this.shiftFiveToSix = false;
        this.sellerShiftFiveToSix = undefined;


        this.isShiftDown = false;
        this.isShiftOne = false;
        this.isShiftTwo = false;
        this.isShiftThree = false;
        this.isShiftFour = false;
        this.rankingChange = 0;

        this.isShiftDownSellersCol1 = false;
        this.isShiftDownSellersCol2 = false;
    }

    _fundraiserForLiveLink: GetFundraiserForLiveLink;
    @Input() set fundraiserForLiveLink(value: GetFundraiserForLiveLink) {
        if (!value || (
            // this comes from a signal-r message meant for updating something other than sales tallies.
            !value.topSellersByDollars && !value.topSellersByTransactionCount && !value.topTeams && !value.totalRaised
        )) {
            return;
        }
        this._fundraiserForLiveLink = value;
        this.totalRaisedUnformatted = value.totalRaised;
        this.isMetricDollars = value.isMetricDollars;

        // put all the teams in teamsAbove to start.
        this.teamsAbove = value.topTeams.slice(0, 5);
        this.sellersCol1Above = this.isSellersSortedByDollars ? value.topSellersByDollars.slice(0, 5) : value.topSellersByTransactionCount.slice(0, 5);
        this.sellersCol2Above = this.isSellersSortedByDollars ? value.topSellersByDollars.slice(5, 10) : value.topSellersByTransactionCount.slice(5, 10);

        //this.topTeams = this._fundraiserForLiveLink.topTeams;
        if (!this.isFirstLoad) {
            this.incomingTopTeams = value.topTeams;
            this.transitionTopTeamChanges();

            this.incomingTopSellers = this.isSellersSortedByDollars ? value.topSellersByDollars : value.topSellersByTransactionCount;

            if (this.incomingTopSellers.length > 9) {
                this.calculateTopSellersAnimationDetails();
                this.moveSeller();
            }
            else {
                this.finishAnimation();
            }
        }
        else {
            this.isFirstLoad = false;
            this.topTeams = value.topTeams.slice(0, 5);

            this.topSellers = this.isSellersSortedByDollars ? value.topSellersByDollars : value.topSellersByTransactionCount;

            this.topSellersCol1 = this.isSellersSortedByDollars ? value.topSellersByDollars.slice(0, 5) : value.topSellersByTransactionCount.slice(0, 5);
            this.topSellersCol2 = this.isSellersSortedByDollars ? value.topSellersByDollars.slice(5, 10) : value.topSellersByTransactionCount.slice(5, 10);
        }

    }

    get totalRaised(): number {
        if (this.totalRaisedUnformatted == undefined) {
            return null;
        }
        let result = this.totalRaisedUnformatted;
        if (result % 1.0 > 0) {
            result++;
        }
        return +(result.toString().split('.')[0] + '.00');
    }

    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    ngOnInit(): void {
    }

    transitionTopTeamChanges() {
        // now figure out which items in topTeams need to go into which lists:
        // which team has changed?
        let changedTeamId: number;
        let changedTeamOldRank: number;
        let changedTeamNewRank: number;

        // team in incomingTeams that's not in topTeams.
        // in case a new team is moving into the top 5.
        //let newTeam: TopTeam;
        this.incomingTopTeams.forEach(t => {
            let oldRank = this.topTeams.findIndex((tt) => tt.id == t.id);
            if (oldRank < 0) {
                // add the new top team to topTeams,
                // so that it will be accounted for in the following operations.
                this.topTeams.push(t);
            }
        });

        this.topTeams.forEach(t => {
            // this leaves us with the values for the LAST team with a changed ranking,
            // which is the team that's getting moved up...

            let oldRank = this.topTeams.findIndex((tt) => tt.id == t.id);
            let newRank = this.incomingTopTeams.findIndex((tt) => tt.id == t.id);
            if (oldRank != newRank) {
                changedTeamId = t.id;
                changedTeamOldRank = oldRank;
                changedTeamNewRank = newRank;
            }
        });

        if (!changedTeamId) {
            return;
        }

        // initialize arrays so we can push into them.
        this.teamsAbove = [];
        this.teamsToMoveDown = [];
        this.teamsBelow = [];
        for (let i = 0; i < this.topTeams.length; i++) {
            let oldTopTeam = this.topTeams[i];

            // teams above the teams getting shifted:
            // if oldTopTeam is ranked higher than changedTeamNewRank and changedTeamOldRank
            if (i < changedTeamNewRank && i < changedTeamOldRank) {
                this.teamsAbove.push(oldTopTeam);
            }

            // team to shift up:
            else if (oldTopTeam.id == changedTeamId) {
                this.teamToMoveUp = oldTopTeam;
            }

            // teams to shift down:
            else if (i >= changedTeamNewRank && i < changedTeamOldRank) {
                this.teamsToMoveDown.push(oldTopTeam)
            }

            // teams below the teams getting shifted:
            else {
                this.teamsBelow.push(oldTopTeam)
            }
        }

        this.rankingChange = changedTeamOldRank - changedTeamNewRank;
        setTimeout(() => {
            switch (this.rankingChange) {
                case 1:
                    this.isShiftOne = true;
                    this.isShiftDown = true;
                    break;
                case 2:
                    this.isShiftTwo = true;
                    this.isShiftDown = true;
                    break;
                case 3:
                    this.isShiftThree = true;
                    this.isShiftDown = true;
                    break;
                case 4:
                    this.isShiftFour = true;
                    this.isShiftDown = true;
                    break;
                default:
                    return;
            }
            this.rankingChange = 0;
        }, 1)

        setTimeout(() => {
            // finally update topTeams to incomingTopTeams:
            this.topTeams = this.incomingTopTeams;

            // put all the teams back into teamsAbove:
            this.teamsAbove = this.topTeams;

            // clear all the teams out of the other lists:
            this.teamsBelow = undefined;
            this.teamToMoveUp = undefined;
            this.teamsToMoveDown = undefined;

            // reset the animation trigger variables:
            this.isShiftDown = false;
            this.isShiftOne = false;
            this.isShiftTwo = false;
            this.isShiftThree = false;
            this.isShiftFour = false;
        }, 2000);
    }

    toggleTopSellersMetric() {
        this.isSellersSortedByDollars = !this.isSellersSortedByDollars;

        this.sellersCol1Above = this.isSellersSortedByDollars ? this._fundraiserForLiveLink.topSellersByDollars.slice(0, 5) : this._fundraiserForLiveLink.topSellersByTransactionCount.slice(0, 5);
        this.sellersCol2Above = this.isSellersSortedByDollars ? this._fundraiserForLiveLink.topSellersByDollars.slice(5, 10) : this._fundraiserForLiveLink.topSellersByTransactionCount.slice(5, 10);
        this.topSellers = this.isSellersSortedByDollars ? this._fundraiserForLiveLink.topSellersByDollars : this._fundraiserForLiveLink.topSellersByTransactionCount;
        this.topSellersCol1 = this.isSellersSortedByDollars ? this._fundraiserForLiveLink.topSellersByDollars.slice(0, 5) : this._fundraiserForLiveLink.topSellersByTransactionCount.slice(0, 5);
        this.topSellersCol2 = this.isSellersSortedByDollars ? this._fundraiserForLiveLink.topSellersByDollars.slice(5, 10) : this._fundraiserForLiveLink.topSellersByTransactionCount.slice(5, 10);
    }


    // new seller moving into column 2:
    // 1. put the seller at the bottom of column 2
    // 2. open a gap for the new seller,
    // 3. move the new seller into the gap.

    // new seller moving into column 1:
    // 1. put the seller at the bottom of column 2
    // 2. open a gap in column 1.
    // 3. move new seller diagonally into column 1 gap.
    // 4. move old #5 diagonally to #6.

    // existing seller moving within column 1 or column 2:
    // 1. open a gap in the column.
    // 2. move seller into the gap.

    // existing seller moving from column 2 to column 1:
    // 1. open a gap in column 1.
    // 2. move new seller diagonally into column 1 gap.
    // 3. move old #5 diagonally to #6.

    calculateTopSellersAnimationDetails() {
        this.moverOldRank = undefined;
        this.moverNewRank = undefined;
        this.mover = undefined;

        for (let i = 0; i < this.incomingTopSellers.length; i++) {
            let incomingSeller = this.incomingTopSellers[i];
            let incomingSellerOldRank = this.topSellers.findIndex((s) => s.id == incomingSeller.id);
            if (incomingSellerOldRank < 0) {
                // incoming seller was not in the top 10 sellers.
                if (this.mover) {
                    // we already found an incoming seller that's moved up,
                    // we don't know how to animate for muliple sellers moving up,
                    // so we'll skip animation.
                    this.resetSellersAnimationCalculations();
                    break;
                }
                this.moverNewRank = i;
                this.mover = this.incomingTopSellers[i];
            }
            else if (incomingSellerOldRank > i) {
                if (this.mover) {
                    // we already found an incoming seller that's moved up,
                    // we don't know how to animate for muliple sellers moving up,
                    // so we'll skip animation.
                    this.resetSellersAnimationCalculations();
                    break;
                }
                else {
                    // incoming seller has moved up in the rankings, must be the mover:
                    this.mover = this.incomingTopSellers[i];
                    this.moverNewRank = i;
                    this.moverOldRank = incomingSellerOldRank;
                }
            }
        }
    }

    resetSellersAnimationCalculations() {
        // too many new sellers to animate, so reset move rank variables:
        this.moverOldRank = undefined;
        this.moverNewRank = undefined;
        this.mover = undefined;
    }


    moveSeller() {
        if (this.moverOldRank == undefined || this.moverOldRank <= this.moverNewRank) {
            // something must have gone wrong,
            // since we can only animate a seller moving UP; don't animate.
            this.finishAnimation();
            return;
        }

        //// LATER? this is a little challenging,
        //// so don't do any animation for this for now.
        //if (this.moverOldRank == undefined) {

        //    //// seller to move is new to the top 10.
        //    //// would need to animate the new seller at the bottom of col 2...

        //    //if (this.moverNewRank > 4) {
        //    //    // open a gap in col2 for mover.
        //    //    // move mover up into gap.

        //    //    this.sellersCol2ToMoveUp = this.mover;

        //    //    // sort existing col2 sellers into sellers-above and seller-to-move-down:

        //    //}
        //    //else {
        //    //    // open a gap in col1 for mover.
        //    //    // move mover diagonally into gap.
        //    //    this.sellerCol2ToMoveDiagonally = this.mover;
        //    //}
        //}
        if (this.moverOldRank < 5 && this.moverNewRank < 5) {
            // seller move is within column 1
            this.sellersCol1Above = [];
            this.sellersCol1ToMoveDown = [];

            this.sellersCol1ToMoveUp = this.mover;

            // move down everything below the mover's destination:
            // TODO: account for a column shorter than 5 elements.
            for (let i = 0; i < 5; i++) {
                let seller = this.topSellers[i];
                if (i < this.moverNewRank) {
                    this.sellersCol1Above.push(seller);
                }
                else if (i >= this.moverNewRank && i != this.moverOldRank) {
                    this.sellersCol1ToMoveDown.push(seller);
                }
            }
            // animate:
            setTimeout(() => {
                this.isShiftDownSellersCol1 = true;
            }, 1)

            setTimeout(() => {
                // now that the gap has been created, animate a move of the mover up into it.
                switch (this.moverOldRank - this.moverNewRank) {
                    case 1:
                        this.isShiftOne = true;
                        break;
                    case 2:
                        this.isShiftTwo = true;
                        break;
                    case 3:
                        this.isShiftThree = true;
                        break;
                    case 4:
                        this.isShiftFour = true;
                        break;
                }
                setTimeout(() => {
                    // finish the animation.
                    this.topSellersCol1 = this.incomingTopSellers.slice(0, 5);
                    this.sellersCol1Above = this.topSellersCol1;
                    this.sellersCol1ToMoveDown = undefined;
                    this.sellersCol1ToMoveUp = undefined;
                }, 2000);
            }, 2000);
        }
        else if (this.moverOldRank > 4 && this.moverNewRank > 4) {
            // seller move is within column 2
            this.sellersCol2Above = [];
            this.sellersCol2ToMoveDown = [];

            this.sellersCol2ToMoveUp = this.mover;

            // move down everything below the mover's destination:
            // TODO: account for a column shorter than 5 elements.
            for (let i = 5; i < 10; i++) {
                let seller = this.topSellers[i];
                if (i < this.moverNewRank) {
                    this.sellersCol2Above.push(seller);
                }
                else if (i >= this.moverNewRank && i != this.moverOldRank) {
                    this.sellersCol2ToMoveDown.push(seller);
                }
            }
            // animate:
            setTimeout(() => {
                this.isShiftDownSellersCol2 = true;
            }, 1)

            setTimeout(() => {
                // now that the gap has been created, animate a move of the mover up into it.
                switch (this.moverOldRank - this.moverNewRank) {
                    case 1:
                        this.isShiftOne = true;
                        break;
                    case 2:
                        this.isShiftTwo = true;
                        break;
                    case 3:
                        this.isShiftThree = true;
                        break;
                    case 4:
                        this.isShiftFour = true;
                        break;
                }
                setTimeout(() => {
                    // finish the animation.
                    this.topSellersCol2 = this.incomingTopSellers.slice(5, 10);
                    this.sellersCol2Above = this.topSellersCol2;
                    this.sellersCol2ToMoveDown = undefined;
                    this.sellersCol2ToMoveUp = undefined;
                }, 2000);
            }, 2000);
        }
        else {
            // seller is moving from column 2 to column 1.

            this.sellerCol2ToMoveDiagonally = this.mover;

            // open a gap in column 1: move down everything below the mover's destination:
            this.sellersCol1Above = [];
            this.sellersCol1ToMoveDown = [];
            // TODO: account for a column shorter than 5 elements.
            for (let i = 0; i < 5; i++) {
                let seller = this.topSellers[i];
                if (i < this.moverNewRank) {
                    this.sellersCol1Above.push(seller);
                }
                else if (i >= this.moverNewRank && i != this.moverOldRank) {
                    this.sellersCol1ToMoveDown.push(seller);
                }
            }

            // sort column 2 into sellers to move down and sellers below:
            this.sellersCol2Below = [];
            this.sellersCol2Above = [];
            this.sellersCol2ToMoveDown = [];
            for (let i = 5; i < 10; i++) {
                let seller = this.topSellers[i];
                if (i < this.moverOldRank) {
                    this.sellersCol2ToMoveDown.push(seller);
                }
                else if (i > this.moverOldRank) {
                    this.sellersCol2Below.push(seller);
                }
            }

            // animate:
            setTimeout(() => {
                this.isShiftDownSellersCol1 = true;
            }, 1)

            setTimeout(() => {
                // now that the gap has been created, animate a move of the mover diagonally into it.
                let amountToShiftMoverUp = this.moverOldRank - this.moverNewRank - 5;
                switch (amountToShiftMoverUp) {
                    case -4:
                        this.isShiftLeftDownFour = true;
                        break;
                    case -3:
                        this.isShiftLeftDownThree = true;
                        break;
                    case -2:
                        this.isShiftLeftDownTwo = true;
                        break;
                    case -1:
                        this.isShiftLeftDownOne = true;
                        break;
                    case 0:
                        this.isShiftLeftStraight = true;
                        break;
                    case 1:
                        this.isShiftLeftUpOne = true;
                        break;
                    case 2:
                        this.isShiftLeftUpTwo = true;
                        break;
                    case 3:
                        this.isShiftLeftUpThree = true;
                        break;
                    case 4:
                        this.isShiftLeftUpFour = true;
                        break;
                    case 5:
                        this.isShiftLeftUpFive = true;
                        break;
                    default:
                        break;

                }

                setTimeout(() => {
                    // finish the shift-left animation:
                    this.topSellersCol1 = this.incomingTopSellers.slice(0, 5);
                    this.sellersCol1Above = this.topSellersCol1;

                    // don't clear this yet; else the sellers in col 2 to move down will suddenly drop.
                    //this.sellerCol2ToMoveDiagonally = undefined;
                    this.hideSellerCol2ToMoveDiagonally = true;

                    // TODO: fix indexing to work with fewer than 10 sellers.
                    this.sellerShiftFiveToSix = this.topSellers[4];

                    this.sellersCol1ToMoveDown = undefined;
                    this.sellersCol1ToMoveUp = undefined;
                    this.sellersCol1Below = undefined;

                    // close the gap in column 2:
                    this.isShiftDownSellersCol2 = true;

                    setTimeout(() => {
                        // shift col1 #5 to col 2 top:
                        this.shiftFiveToSixTriggerBoolean = true;

                        setTimeout(() => {
                            // animations are done. Reset all.
                            this.finishAnimation();
                        }, 2000);
                    }, 2000);
                }, 2000);
            }, 2000);
        }
    }

    finishAnimation() {
        this.reset();
        this.topSellers = this.incomingTopSellers;

        // put the top sellers back into the sellers-col-above elements:
        this.sellersCol1Above = this.topSellers.slice(0, 5);
        this.sellersCol2Above = this.topSellers.slice(5, 10);
    }






}
