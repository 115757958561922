<div bsModal #itemImageViewerModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="itemImageViewerModal" 
     aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog">
        <div class="modal-content d-flex justify-content-center h-100" (click)="close()">
            <div class="show-item-image-viewer-btn text-center">
                <i class="fa fa-window-close item-expander-icon"></i>
            </div>
            <img [src]="imageUrl" class="item-image" (click)="imageClick($event)" />
        </div>
    </div>
</div>