import { Injectable } from '@angular/core';
import * as localForage from 'localforage';

@Injectable()
export class LocalStorageService {
    getItem(key: string, callback: any): void {
        if (!localForage) {
            return;
        }

        localForage.getItem(key, callback);
    }

    setItem(key, value, callback?: any): void {
        if (!localForage) {
            return;
        }

        if (value === null) {
            value = undefined;
        }

        localForage.setItem(key, value, callback);
    }

    removeItem(key, callback?: any): void {
        if (!localForage) {
            return;
        }

        localForage.removeItem(key, callback);
    }

    clear(callback: any): void {
        if (!localForage) {
            return;
        }

        localForage.clear(callback);
    }

    clearAllWithPrefix(prefix: string): void {
        if (!localForage) {
            return;
        }

        localForage.iterate(function(value, key, iterationNumber) {
            // Resulting key/value pair -- this callback
            // will be executed for every item in the
            // database.
            if (key.startsWith(prefix)) {
                localForage.removeItem(key, null);
            }
        }).then(function() {
            // console.log('Iteration has completed');
        }).catch(function(err) {
            // This code runs if there were any errors
            // console.log(err);
        });
    }
}
