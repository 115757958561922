import { Component, ElementRef, HostListener, Injector, OnInit, ViewChild } from "@angular/core";
import { AppComponentBase } from "../../../../shared/common/app-component-base";
import {
    AgreementsServiceProxy, GetAgreementForSignatureDto, ListItemLookupTableDto
} from "../../../../shared/service-proxies/service-proxies";
import { SignaturePad } from 'angular2-signaturepad';
import { DateTime } from 'luxon';
import { ActivatedRoute } from "@angular/router";
import { AppConsts } from "../../../../shared/AppConsts";
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';
import { finalize } from "rxjs/operators";

@Component({
    selector: 'sign',
    styleUrls: ['./sign.component.less'],
    templateUrl: './sign.component.html'
})
export class SignComponent extends AppComponentBase implements OnInit {

    @ViewChild('organizationSignaturePad', { static: false }) organizationSignaturePad: SignaturePad;
    @ViewChild('organizationSignatureImage', { static: false }) organizationSignatureImage: ElementRef;
    @ViewChild('providerSignatureImage', { static: false }) providerSignatureImage: ElementRef;
    @HostListener('window:resize', ['$event']) onResize(event) {
        this.sizeSignaturePadsAndImages();
    }

    gotAgreement: boolean = false;
    agreement: GetAgreementForSignatureDto = null;
    statuses: ListItemLookupTableDto[];

    // TODO: make this work on the Sign button (getting error: Can't bind to 'buttonBusy' since it isn't a known property of 'button')
    saving = false;

    projectedSalesEdit: number;
    actualSalesEdit: number;

    _statusId: number;
    notesEdit: string;

    defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/app-logo-on-' + this.currentTheme.baseSettings.menu.asideSkin + '.svg';

    deliveryDate: Date;
    endDate: string;
    startDate: string;

    viewTerms: boolean = false;

    screenWidth: number;
    signaturePadOptions: Object = {
        // seems we need to set something here, if we want to (re)set this later.
        'minWidth': 1,
    };
    canvasWidth: number;
    canvasHeight: number;
    minStrokeWidth: number;
    maxStrokeWidth: number;

    remoteServiceBaseUrl = AppConsts.remoteServiceBaseUrl;
    ui: AppUiCustomizationService;

    constructor(injector: Injector,
        private _agreementsServiceProxy: AgreementsServiceProxy,
        private route: ActivatedRoute,
    ) {
        super(injector);
    }

    // setting this true will keep us from briefly showing the default logo before the customer logo.
    hasLogo: boolean = true;

    get deliveryDateString(): string {
        if (!this.agreement.deliveryDate) {
            return '';
        }
        return this.agreement.deliveryDate.toFormat("D");
    }

    get now(): string {
        return DateTime.now().toFormat("D");
    }
    get repSignatureDateString(): string {
        if (!this.agreement.signatures[1].signatureDate) {
            return '';
        }
        return this.getLocalDateTimeString(this.agreement.signatures[1].signatureDate);
    }
    get customerSignatureDateString(): string {
        if (!this.agreement.signatures[0].signatureDate) {
            return '';
        }
        return this.getLocalDateTimeString(this.agreement.signatures[0].signatureDate);
    }

    _imageSource: string;
    get imageSource() {
        return this._imageSource;
    }

    agreementGuid: string;
    ngOnInit() {
        this.route.params.subscribe(params => {
            this.agreementGuid = params.agreementGuid;
            this._agreementsServiceProxy.getAgreementByGuid(this.agreementGuid).subscribe(result => {
                this.agreement = result;
                this.gotAgreement = true;
                if (!this.agreement || !this.agreement.name) {
                    this.notify.error(this.l('NoAgreementForLink'));
                    return;
                }
                if (result.isRequestForSignatureExpired) {
                    return;
                }
                let re = /[0\-]/gi;
                if (result.logoId.replace(re, '').length == 0) {
                    this.hasLogo = false;
                }
                else {
                    this.hasLogo = true;
                    this._imageSource = this.remoteServiceBaseUrl + '/TenantCustomization/GetTenantLogo?skin='
                        + this.currentTheme.baseSettings.menu.asideSkin + '&tenantId=' + result.tenantId + '&id=' + result.logoId;
                }
                this.startDate = this.getLocalDateTimeString(result.startDate);
                this.endDate = this.getLocalDateTimeString(result.endDate);
                this.deliveryDate = this.getLocalDateTimeDate(result.deliveryDate);
            });
        });
    }

    ngAfterViewInit() {
        this.sizeSignaturePadsAndImages();
    }

    sizeSignaturePadsAndImages(): void {
        this.screenWidth = document.body.clientWidth;

        if (this.screenWidth < 710) {
            // scale down the signature pads and signature images:
            this.canvasWidth = this.screenWidth - 210;
            let scaleFactor = this.canvasWidth / 500;
            this.canvasHeight = 100 * scaleFactor;
            this.minStrokeWidth = 5 * scaleFactor;
            this.maxStrokeWidth = 10 * scaleFactor;
        }
        else {
            this.canvasWidth = 500;
            this.canvasHeight = 100;
            this.minStrokeWidth = 5;
            this.maxStrokeWidth = 10;
        }
        this.scaleSignaturePad();
        this.scaleProviderSignatureImage();
        this.scaleOrganizationSignatureImage();
    }

    // timeout because the server is super-slow getting us the image
    maxTimeout: number = 5000;

    scaleProviderSignatureImageRetryWait: number = 1;
    scaleProviderSignatureImage() {
        if (this.providerSignatureImage) {
            const htmlElement = this.providerSignatureImage.nativeElement as HTMLElement;
            htmlElement.setAttribute("style", "width:" + this.canvasWidth.toString() + "px;")
            htmlElement.setAttribute("style", "height:" + this.canvasHeight.toString() + "px;")
        }
        else if (this.scaleProviderSignatureImageRetryWait < this.maxTimeout) {
            setTimeout(() => {
                this.scaleProviderSignatureImageRetryWait *= 5;
                this.scaleProviderSignatureImage();
            }, this.scaleProviderSignatureImageRetryWait);
        }
    }

    scaleOrganizationSignatureImageRetryWait: number = 1;
    scaleOrganizationSignatureImage() {
        if (this.organizationSignatureImage) {
            const htmlElement = this.organizationSignatureImage.nativeElement as HTMLElement;
            htmlElement.setAttribute("style", "width:" + this.canvasWidth.toString() + "px;")
            htmlElement.setAttribute("style", "height:" + this.canvasHeight.toString() + "px;")
        }
        else if (this.scaleOrganizationSignatureImageRetryWait < this.maxTimeout) {
            setTimeout(() => {
                this.scaleOrganizationSignatureImageRetryWait *= 5;
                this.scaleOrganizationSignatureImage();
            }, this.scaleOrganizationSignatureImageRetryWait);
        }
    }

    scaleSignaturePadRetryWait: number = 1;
    scaleSignaturePad() {
        if (this.organizationSignaturePad) {
            this.organizationSignaturePad.queryPad()._canvas.width = this.canvasWidth;
            this.organizationSignaturePad.queryPad()._canvas.height = this.canvasHeight;
            this.organizationSignaturePad.queryPad().minWidth = this.minStrokeWidth;
            this.organizationSignaturePad.queryPad().maxWidth = this.maxStrokeWidth;
        }
        else if (this.scaleSignaturePadRetryWait < this.maxTimeout) {
            setTimeout(() => {
                this.scaleSignaturePadRetryWait *= 5;
                this.scaleSignaturePad();
            }, this.scaleSignaturePadRetryWait);
        }
    }

    clearSignature(): void {
        (<any>this.organizationSignaturePad).signaturePad.clear();
    }

    sign(): void {
        if (this.validateSignature('organization')) {
            this.agreement.signatures[0].signatureBase64 = this.organizationSignaturePad.toDataURL();
            this.agreement.signatures[0].signatureType = 10;

            // TODO? it would be nice to scale-up the signature image in case of a shrunken signpad, somehow.
            // just scaling up the canvas before getting the image doesn't work, because the component
            // clears the image on resize.
            // here are some things to try on the server side:
            // https://stackoverflow.com/questions/8862389/change-image-size-with-c-sharp-in-mvc3

            this._agreementsServiceProxy.sign(this.agreementGuid, this.agreement.signatures[0])
                .pipe(finalize(() => (this.saving = false)))
                .subscribe(result => {
                    if (!result) {
                        this.notify.info(this.l('SavedSuccessfully'));
                        this.agreement.signatures[0].signatureDate = DateTime.now();
                    }
                    else {
                        // reset the signature pad:
                        this.agreement.signatures[0].signatureBase64 = null;
                        this.agreement.signatures[0].signatureType = 0;
                        this.sizeSignaturePadsAndImages();
                        this.notify.error(this.l('ErrorSavingSignature') + ' ' + result);
                    }
                });
        }
    }

    validateSignature(signaturePad: string): boolean {
        var message = this.getSignatureErrorMessage(signaturePad);
        if (message && message.length > 0) {
            this.message.error(message, this.l('SignatureProblems'));
            return false;
        }
        return true;
    }

    DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSS';
    getSignatureErrorMessage(signaturePad: string): string {
        let problems: string[] = [];
        if (signaturePad == 'organization') {
            if ((<any>this.organizationSignaturePad).signaturePad.isEmpty()) {
                problems.push('An organization signature must be provided');
            }
        }

        if (problems.length == 0)
            return '';
        return problems.join('\n');
    }

    getFormattedProfit(isPercent: boolean, amount: number) {
        if (isPercent) {
            return amount + '%';
        }
        else {
            return amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
            });
        }
    }
}