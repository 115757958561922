import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'donations-page',
    templateUrl: './donations-page.component.html',
    styleUrls: ['./donations-page.component.less']
})
export class DonationsPageComponent extends AppComponentBase implements OnInit {
    visible = false;
    donationAmounts: number[];

    @Input() logoUrl: string;
    @Input() organizationName: string;
    @Input() fundraiserName: string;
    @Input() teamMemberNameInput: string;
    @Input() tenantLogoUrl: string;
    
    @Output() applyDonation: EventEmitter<{
        selectedDonation: number,
        customDonation: number,
        updatedTipPercentage: number,
        updatedTipDollars: number,
        goToCheckout: boolean
    }>
        = new EventEmitter<{
            selectedDonation: number,
            customDonation: number,
            updatedTipPercentage: number,
            updatedTipDollars: number,
            goToCheckout: boolean
        }>();
    customDonation: number = 0;
    selectedDonation: number = 0;

    currentTipPercentage: number;
    @Output() applyTip: EventEmitter<number> = new EventEmitter<number>();
    updatedTipPercentage: number = 0;
    updatedTipDollars: number = 0;

    currentTotal: number;
    allowRoundUp = true;
    showTipInput: boolean;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
    }

    show(donationAmounts: number[], currentTotal: number, customDonation: number, currentTipPercentage: number, hideRoundUp: boolean, showTipInput: boolean) {
        // Only show options that are at least $1 higher than the current total
        this.donationAmounts = donationAmounts.filter(x => x > (currentTotal + 1));

        this.showTipInput = showTipInput;

        this.currentTotal = currentTotal;
        if (this.selectedDonation < currentTotal)
            this.selectedDonation = 0;

        this.customDonation = customDonation;
        this.allowRoundUp = !hideRoundUp;

        this.currentTipPercentage = currentTipPercentage;
        this.updatedTipPercentage = this.currentTipPercentage;

        this.visible = true;
    }

    noDonation() {
        this.selectedDonation = 0;
        this.updatedTipPercentage = 0;
        this.customDonation = 0;
        this.setDonation(true);
    }

    setDonation(goToCheckout:boolean = true) {
        // clear selected donation: if a custom donation was selected or if the selected rounding donation is less than the total
        if (this.customDonation > 0 || this.selectedDonation < this.currentTotal)
            this.selectedDonation = 0;

        this.applyDonation.emit({
            selectedDonation: this.selectedDonation,
            customDonation: this.customDonation,
            updatedTipPercentage: this.updatedTipPercentage,
            updatedTipDollars: this.updatedTipPercentage * this.selectedDonation / 100,
            goToCheckout: goToCheckout
        });
        this.visible = false;
    }

    setSelectedAmount(amount: number) {
        // selectedDonation is apparently taken to mean something like 'total',
        // where the actual donation amount is selectedDonation - itemTotal.
        this.selectedDonation = amount;
        this.customDonation = 0;
    }

    setCustomDonation() {
        if (this.customDonation < 1)
            this.customDonation = 0;
        if (this.customDonation > 0)
            this.selectedDonation = 0;
    }

    get isDonationInvalid(): boolean {
        return this.customDonation && this.customDonation < 10;
    }
}
